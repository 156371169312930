import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import * as locale from 'date-fns/locale';

import { Contact, ToolTipContact } from '@/components/contact';
import ModalComponentLookComment from '@/components/modallookComment';
import Table from '@/components/table';
import Shimmer from '@/components/ui/shimmer';
import TextArea from '@/components/ui/textArea';
import { FeedbackService } from '@/services/feedback';
import { Comment } from '@/services/feedback/types';
import { Pragmatico } from '@/services/users';

import styles from './styles.module.scss';

type CommentListProps = {
  user: Pragmatico;
};

export const formatDate = (date: Date): string => {
  const str = format(date, 'MMM-dd-yyyy hh:mm', { locale: locale.es });
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const CommentList: React.FC<CommentListProps> = (props) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [selectedComment, setSelectedComment] = useState<Comment | null>(null);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  useEffect(() => {
    setIsCommentLoading(true);
    FeedbackService.getComments({ email: props.user.email, limit: 3 }).then((data) => {
      setComments(data.comments);
      setIsCommentLoading(false);
    });
  }, []);

  const getPragmaticComments = () => {
    if (isCommentLoading) return <Shimmer repetitions={3} height={30} />;
    return (
      <div className={styles['comment-users-table']}>
        <Table
          data={comments.map((comment, index) => ({
            _key: index,
            ...comment,
            comment: (
              <div className={styles['comment-column']}>
                <button
                  onClick={() => {
                    setSelectedComment(comment);
                  }}
                >
                  {comment.comment}
                </button>
                <div>{formatDate(new Date(comment.commentDate))}</div>
              </div>
            ),
            leaderNameRow: (
              <ToolTipContact
                className={''}
                user={{ images: comment.leaderImages, fullname: comment.leaderName }}
              />
            ),
          }))}
          header={[
            { label: 'Comentarios Anteriores', name: 'comment' },
            { label: 'Creado por', name: 'leaderNameRow' },
          ]}
          name="comments"
          emptyLabel={'No existen comentarios recientes'}
        ></Table>
      </div>
    );
  };

  return (
    <div className={styles['comment-table']}>
      {getPragmaticComments()}
      <ModalComponentLookComment
        title=""
        onClose={() => {
          setSelectedComment(null);
        }}
        onSuccess={() => {
          setSelectedComment(null);
        }}
        show={!!selectedComment}
        successLabel="Aceptar"
      >
        <>
          <Contact user={props.user} show={['capacity']} />
          <CommentInfo
            bodyCreate={{
              comment: selectedComment?.comment ?? '',
              commentDate: selectedComment?.commentDate ?? '',
              leaderName: selectedComment?.leaderName ?? '',
            }}
          ></CommentInfo>
        </>
      </ModalComponentLookComment>
    </div>
  );
};

type CommentModal = {
  comment: string;
  commentDate: string;
  leaderName: string;
};

type CommentCreate = {
  bodyCreate: CommentModal;
};
export const CommentInfo: React.FC<CommentCreate> = ({ bodyCreate }) => {
  return (
    <div className={styles['selected-comment']}>
      <h5>Comentario</h5>
      <TextArea
        value={bodyCreate.comment || ''}
        disabled={true}
        className={styles['comment-field']}
      ></TextArea>
      <div className="comment-modal-created">
        <h4>Creado:</h4>
        <p>
          {`${new Date(bodyCreate.commentDate || 0).toLocaleDateString('es-CO', {
            month: 'long',
            day: 'numeric',
          })}
          por
          ${bodyCreate.leaderName}`}
        </p>
      </div>
    </div>
  );
};
