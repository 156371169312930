import React, { useState, useEffect } from 'react';

import style from './styles.module.scss';

enum unitsTime {
  WEEKS = 'weeks',
  DAYS = 'days',
}

enum classTimelineFigure {
  CIRCLE = 'timeline__circle',
  CIRCLE_FOOTER = 'timeline__circle__footer',
  LINE = 'timeline__line--vertical',
  TEXT = 'timeline__circle__text',
  CARD = 'card',
  CARD_TITLE = 'card__title',
  CARD_TASK = 'card__task',
}

export const UserTimelineFigure = (props: any) => {
  const unitTime = props.unitTime;
  const [classCard, setclassCard] = useState('');
  const [classLine, setclassLine] = useState('');
  const [classText, setclassText] = useState('');
  const [wordWeek, setWordWeek] = useState('');
  const [classCardTitle, setclassCardTitle] = useState('');
  const [classCardTask, setclassCardTask] = useState('');
  const [classCircle, setclassCircle] = useState('');
  const [classCircleFooter, setclassCircleFooter] = useState('');
  const week = props.week;

  const cssClass = (className: string): string => {
    if (unitTime === unitsTime.DAYS) {
      className = className + '--resumed';
    }
    return className;
  };

  const showWord = () => {
    if (unitTime === unitsTime.WEEKS) {
      setWordWeek('Semana');
    }
  };

  useEffect(() => {
    showWord();
    setclassLine(cssClass(classTimelineFigure.LINE));
    setclassText(cssClass(classTimelineFigure.TEXT));
    setclassCard(cssClass(classTimelineFigure.CARD));
    setclassCardTitle(cssClass(classTimelineFigure.CARD_TITLE));
    setclassCardTask(cssClass(classTimelineFigure.CARD_TASK));
    setclassCircle(cssClass(classTimelineFigure.CIRCLE));
    setclassCircleFooter(cssClass(classTimelineFigure.CIRCLE_FOOTER));
  });

  return (
    <div className={style['timeline__figure']}>
      <div className={style['timeline__circle__container']}>
        <div className={style[classCircle]}>
          <div className={style[classText]}>
            {wordWeek} {unitTime === unitsTime.DAYS && week.slice(0, 5)}
            {unitTime === unitsTime.WEEKS && week}
          </div>
        </div>

        <div className={style[classLine]}></div>

        <div className={style[classCircleFooter]}></div>
      </div>
      <div className={style['timeline__card']}>
        <div className={style['timeline__line--horizontal']}>
          <hr className={style['timeline__line--dashed']} />
        </div>

        <div className={style[classCard]}>
          <div className={`${style[classCardTitle]} ${style['card__title--pink']}`}>
            HU&apos;s Terminadas: {props.finishedStories.length}
          </div>
          {props.finishedStories.map((finishedStorie: any) => {
            return (
              <div
                key={finishedStorie.name + finishedStorie.endDate}
                className={style[classCardTask]}
              >
                {finishedStorie.name}
                {unitTime === unitsTime.WEEKS && ' : '}
                {finishedStorie.endDate}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
