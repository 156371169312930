import React, { useState, useEffect, useContext } from 'react';

import Calendar from '@/components/ui/calendar';
import Checkbox from '@/components/ui/checkbox';

import style from './styles.module.scss';
import RouteTracing from '@/services/route';

import { sessionContext } from '@/contexts/session';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import SpinnerLoader from '@/components/ui/spinnerLoader';
import { utilsContext } from '@/contexts/utils';

type InformationPragmatic = {
  name: string;
  startDate: string;
  userStory:[];
}

export const FormProgressRoutes = () => {
  const {
    actions: { addToast },
  } = useContext(utilsContext);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [isLoadding, setIsLoading] = useState<boolean>(false);
  const [forms, setForms] = useState<any[]>([]);
  const { state: { data } } = useContext(sessionContext);
  const [userHaveRoute, setUserHaveRoute] = useState<boolean>(true);

  useEffect(() => {
    getForms()
  }, [])

  const getForms = async () => {
    setIsLoading(true)
    try {
      const response = await RouteTracing.getForms();
      setForms(response.routes)
      response.routes.length > 0 ? setUserHaveRoute(true): setUserHaveRoute(false);
      setIsLoading(false)
    } catch (e) {
      addToast(` Error: ${e}`, 'danger');
    }
  }

  const updateInformation = async(sendForm: InformationPragmatic) => {
    try {
      await RouteTracing.sendForm(sendForm);
      getForms();
      setForms(forms.map(form => {
        if (form.name === sendForm.name) {
          return sendForm;
        }
        return form
      }))
      setIsLoading(false);
    } catch (e) {
      addToast(` Error: ${e}`, 'danger');
      setIsLoading(false);
    } 
  }

  const handleChangeCheckbox = async (event: any, form: any, data: any) => {
    setIsLoading(true)
    const knowledge = {
      name: event.target.name,
      completed: event.target.checked
    }
    const knowledges = data.knowledge.map((k: any) => {
      if (k.name === knowledge.name) {
        return knowledge
      }
      return k
    })
    const sendForm = {
      ...form,
      userStories: form.userStories.map((u: any) => {
        if (u.name === data.name) {
          return {
            ...u,
            knowledge: knowledges
          }
        }
        return u
      })
    }
    updateInformation(sendForm);
  }

  const toTitleCase = (txt: string): string => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  }

  return (
    <>
      {isLoadding && <SpinnerLoader />}
      {forms.map((form: any, index) => {
        return (
          <Accordion key={form.name + index} style={{
            backgroundColor: "rgb(32 10 71)",
            color: "#FFF"
          }}>
            <AccordionSummary

              expandIcon={
                <ExpandMore
                  style={{
                    color: "#FFF"
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header">
              <div className={`${style['m-form-progress-routes__title']}`}>
                {toTitleCase(form.name)}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={`${style['m-form-progress-routes']}`} >
                <section className={`${style['m-form-progress-routes__date']}`}>
                  <div>
                    <Calendar.Input
                      selected={selectedDate}
                      disabled={true}
                      label="Seleccione fecha que Inicio la ruta"
                      name="test"
                      value={form.startDate}
                      dateFormat={'dd/MM/yyyy'}
                      onChange={(date) => setSelectedDate(date)}
                    />
                  </div>
                </section>
                {
                  form.userStories.map((data: any, indice: any) => {
                    return (
                      <section className={`${style['m-form-progress-routes__route']}`} key={indice}>
                        <div className={`${style['m-form-progress-routes__title']}`}>
                          {data.name}
                        </div>

                        <div className={`${style['m-form-progress-routes__advance']}`}>
                          {
                            data.knowledge.map((item: any, indice: any) => {
                              return (
                                <Checkbox
                                  key={indice}
                                  name={item.name}
                                  label={toTitleCase(item.name)}
                                  value={item.completed}
                                  checked={item.completed}
                                  onChange={(e: any) => handleChangeCheckbox(e, form, data)}
                                />
                              )
                            })
                          }
                        </div>

                        <section className={`${style['m-form-progress-routes__date-story']}`}>
                          <div>
                            <Calendar.Input
                              disabled={true}
                              label="Seleccione fecha que finalizó la historia"
                              name="test"
                              dateFormat={'dd/MM/yyyy'}
                              value={data.endDate}
                              onChange={console.log}
                            />
                          </div>
                        </section>
                      </section>
                    );
                  })
                }
              </div>
            </AccordionDetails>
          </Accordion>
        )
      })}
      {(!userHaveRoute)&& 
      (<div className={`${style['m-form-message-container']}`}>No hay formularios de avance debido a que aún no se le han asignado rutas.</div>) 
      }

    </>
  );
};
