import { useState } from 'react';

import { getDataInitial, saveDataLocalStorage } from 'helpers';
type FormResponse = {
  [key: string]: any | FormResponse;
};
type options = {
  onChange?: any;
};

export const useControlledForm = () => {
  const [form, setForm] = useState<{ [key: string]: string }>({});
  let temporalForm: { [key: string]: string } | undefined = undefined;
  const register = (
    name: string,
    options: options = {},
    initialData?: string,
    isSaveData?: boolean,
  ) => {
    const { onChange } = options;
    const saveData = (name: string, value: string) => {
      if (!isSaveData) return;
      saveDataLocalStorage(name, value);
    };

    const setValueFromStorageData = () => {
      const storageValue = getDataInitial(name, initialData);

      if (storageValue) {
        setForm((values) => {
          temporalForm = { ...values, [name]: storageValue };
          return temporalForm;
        });
      }
      return storageValue;
    };

    return {
      name,
      value: form[name] ? form[name] : setValueFromStorageData(),
      onChange: (e: any) => {
        saveData(name, e.target.value);
        setForm((values) => {
          temporalForm = { ...values, [name]: e.target.value };
          return temporalForm;
        });
      },
    };
  };
  const getValues = () => {
    const f = temporalForm ? temporalForm : form;
    const response: FormResponse = {};
    for (const key in f) {
      const value = f[key];
      if (value) {
        const props = key.split('.');
        props.reduce((acc, prop, index) => {
          if (index == props.length - 1) acc[prop] = value;
          if (typeof acc[prop] === 'undefined') acc[prop] = {};
          return acc[prop];
        }, response);
      }
    }
    return response;
  };
  const reset = () => {
    temporalForm = undefined;
    setForm({});
  };
  return {
    form,
    reset,
    register,
    getValues,
  };
};
export default useControlledForm;
