/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useRef, useState } from 'react';

import ReactDOM from 'react-dom';

import { motion, AnimatePresence } from 'framer-motion';
import { MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { useGoToUserDetails } from '@/app/feedbackComments';
import ContactImageC from '@/components/contact/contact-image';
import { GroupBadge } from '@/components/groups';
import Button from '@/components/ui/button';
import Radio from '@/components/ui/radio';
import Calendar from '@/components/ui/calendar';
import TextArea from '@/components/ui/textArea';
import ASelect from '@/components/atoms/ASelect/ASelect';
import { utilsContext } from '@/contexts/utils';
import { capitalize } from '@/helpers';
import { useGroups } from '@/hooks/useGroups';
import UserService, { ContactImage, Pragmatico } from '@/services/users';

import styles from './styles.module.scss';
import { FeedbackService } from '@/services/feedback';
import ModalComponent from '@/components/ui/modal';

type User = {
  images: ContactImage[] | null;
  fullname: string;
  email: string;
  status?: string;
  capacity?: string;
  group?: string;
  leaderGroup?: string;
  availabilityStartDate?: string;
  technologies?: string;
  probableBrand?: string;
  followUp?: string;
};

export interface InfoUpdateProps {
  show: boolean;
  successLabel: string;
  states: any[];
  onClose: (group?:string, leader?:string, oldGroup?:string) => void;
  user: User;
  brands: { id: number, name: string }[]
}

type FormModalPragmatic = {
  availabilityStartDate?: string;
  technologies?: string;
  probableBrand?: string;
  followUp?: string;
  email: string;
}

export const formatDateToSave = (date: Date | null): string => {
  const dateValid = date ? date : '';
  return String(
    new Date(dateValid)
      .toLocaleDateString('ko-KR', {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
      }).split('. ').join('-')).replace('.', '');
}

const StateInfoEditComponent = ({ show, states, user, onClose, brands }: InfoUpdateProps) => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [url] = useGoToUserDetails(user.email);

  const groups = useGroups();
  const [disabled, setDisabled] = useState(true);
  const [selectedState, setSelectedState] = useState(user.status);
  const [oldSelectedState, setOldSelectedState] = useState(user.status);
  const [selectedGroup, setSelectedGroup] = useState(user.group ? user.group : 'Sin grupo');

  const labelColors = new Map([
    [0, '#FE621D'],
    [1, '#AB88E7'],
    [2, '#F8AF3C'],
    [3, '#E4A4FF'],
    [4, '#BDBDFF'],
    [5, '#7D82B8'],
    [6, '#638475'],
    [7, '#DE7C5A'],
    [8, '#419D78'],
    [9, '#FF2C55'],
    [10, '#3C91E6'],
    [11, '#849324'],
    [12, ' #FFB800'],
    [13, '#919D78'],
    [14, '#FF2C'],
  ]);

  const { email, availabilityStartDate, technologies, probableBrand, followUp } = user;
  const data = { email, availabilityStartDate, technologies, probableBrand, followUp };
  const [formModal, setFormModal] = useState<FormModalPragmatic>(data);
  const originFormModal = useRef(data);
  const originSelectedState = useRef(user.status);
  const originSelectedGroup = useRef(user.group ? user.group : 'Sin grupo')
  const brandsRef = useRef(brands);
  const tecnologiesRef = useRef<HTMLTextAreaElement>(null);
  const followUpRef = useRef<HTMLTextAreaElement>(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [messageModals, setMessageModals] = useState<string>('mensaje');

  useEffect(() => {
    setDisabled(true);
    disablebutton();
  }, [selectedGroup, selectedState])

  useEffect(() => {
    formatDate(availabilityStartDate);
  }, [])

  const formatDate = (date: string | undefined): void => {
    const dateToShow = date ? date.slice(0, 10) : String(
      new Date()
        .toLocaleDateString('ko-KR', {
          year: "numeric",
          day: "2-digit",
          month: "2-digit",
        }).split('. ').join('-')).replace('.', '');

    setFormModal((value: any) => (
      { ...value, availabilityStartDate: dateToShow }
    ));
    originFormModal.current = { ...originFormModal.current, availabilityStartDate: dateToShow };
  }

  const setSelectedStateCustom = (state: string | undefined) => {
    setOldSelectedState(selectedState)
    setSelectedState(state);
  }

  const disablebutton = () => {
    if (oldSelectedState == "Onboarding" && selectedState == "Estudiando" &&
      selectedGroup == 'Sin grupo' || selectedGroup == '') {
      setDisabled(false);
    }
  }
  const validGroup = () =>
    selectedGroup?.toLocaleLowerCase() !=
    (user.group ? user.group?.toLocaleLowerCase() : 'sin grupo');
  const validState = () => selectedState?.toLocaleLowerCase() != user.status?.toLocaleLowerCase();
  const activateButton = () => disabled && (validState || validGroup());

  const setValueTextFile = () => {
    const followUp = followUpRef.current?.value ? followUpRef.current?.value : '';
    const technologies = tecnologiesRef.current?.value ? tecnologiesRef.current?.value : '';
    setFormModal((prev) => ({ ...prev, followUp, technologies }))
  }

  const updateDataOfSomeFields = async (data: any, email: string) => {
    try {
      await FeedbackService.updateSomeFields(data, email);
      addToast('Formulario actualizado correctamente','success')
      setLoading(false);
      onClose();
    } catch (e: any) {
      let messageToShow;

      switch (e.response.data.message) {
        case 'Brand not found': messageToShow = 'La marca probable es requerida';
          break;
        case 'Usuario no encontrado': messageToShow = 'Usuario no encontrado en la lista de disponibles.';
          break;
        default: messageToShow = e;
      }
     addToast(`${messageToShow}`,'danger')
      setLoading(false);
    }
  }

  const validationDataOfSomeFields = () => {
    const { email, availabilityStartDate, technologies, probableBrand, followUp } = formModal;
    if (!availabilityStartDate) {
      setLoading(false);
      addToast('Fecha de inicio de disponibilidad es requerido', 'danger');
    } else {
      const dataToSave = { availabilityStartDate, technologies, probableBrand, followUp }
      updateDataOfSomeFields(dataToSave, email)
    }
  }

  const form = JSON.stringify(formModal).toLowerCase();
  const formOrigin = JSON.stringify(originFormModal.current).toLowerCase();
  const state = selectedState?.toLowerCase();
  const originState = originSelectedState.current?.toLowerCase();
  const group = selectedGroup?.toLowerCase();
  const originGroup = originSelectedGroup.current?.toLowerCase();

  const saveDataOfStateAndGroup = () => {
    if (user.email && selectedState && selectedGroup) {
      const newGroup = validGroup() ? selectedGroup : undefined;
      const newState = validState() ? selectedState : undefined;
      UserService.saveUserChanges(user.email, newState, newGroup)
        .then((data) => {
          user.status = selectedState;
          user.group = selectedGroup;
          addToast('Estado y casa/proyecto actualizados correctamente', 'success');
     
         if(group!== originGroup){
          const detailGroup = groups.find((group) => group.title.toLowerCase() === (newGroup as string).toLowerCase());
          onClose(newGroup, detailGroup?.leader, originSelectedGroup.current);
         }else{
            onClose()
         }
          
        })
        .catch((e) => addToast(`Estado y casa/proyecto no se han actualizado.${e}`, 'danger'))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const handlerValidationOfDataToSave = () => {
    if ((form === formOrigin) &&
      (originState === state) &&
      (originGroup === group)
    ) {
      addToast('Los datos del pragmático no han sido modificados', 'information')
      setLoading(false);
    } else {

      if ((form !== formOrigin) &&
        ((originState !== state) || (originGroup !== group))) {
        setMessageModals('cambiar de estado o casa-proyecto y actualizar datos del');
      } else if (
        ((originState !== state) || (originGroup !== group)) &&
        (form === formOrigin)
      ) {
        setMessageModals('cambiar de grupo o casa-proyecto al');
      } else if (
        !((originState !== state) || (originGroup !== group)) &&
        (form !== formOrigin)
      ) {
        setMessageModals('actualizar datos del')
      }

      setOpenConfirmationModal(true);
    }
  }

  const handlerPostConfirmation = () => {
    if ((form !== formOrigin) &&
      ((originState !== state) || (originGroup !== group))) {
      saveDataOfStateAndGroup();
      validationDataOfSomeFields();
    } else if (
      ((originState !== state) || (originGroup !== group)) &&
      (form === formOrigin)
    ) {
      saveDataOfStateAndGroup();
    } else if (
      !((originState !== state) || (originGroup !== group)) &&
      (form !== formOrigin)
    ) {
      validationDataOfSomeFields();
    }
    else {
      setLoading(false);
      addToast('Información no almacenada', 'danger')
    }
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <div className={styles.container} onClick={(e) => e.stopPropagation()} aria-hidden="true" data-testid="state-info-edit-component">
            <div className={styles.header}>
              <MdClose
                name="modal-component_button-close"
                className={styles.close}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className={styles.body}>
              <div className={styles['contact-animate-presence']}>
                <ContactImageC user={user as Pragmatico} size="l" />
                <div className={styles['info-contact-animate-presence']}>
                  <h3>{user.fullname}</h3>
                  <p>{user.email}</p>
                  <div
                    className={styles['group-contact-animate-presence']}
                    data-name={capitalize(user.group || '')}
                  ></div>
                </div>
                <Link to={url}>
                  <Button name="test" type="button">
                    Ver detalles
                  </Button>
                </Link>
              </div>
              <section className={styles['body__state-home-form']}>
                <div className={styles['inner-container']}>
                  <h6>Estado</h6>
                  {states.map((state, index) => (
                    <Radio
                      key={index}
                      name="RadioState"
                      className={styles['radio-label']}
                      classLabel={{
                        backgroundColor: labelColors.get(index),
                        borderRadius: '25px',
                        padding: '4px 10px',
                        fontWeight: 'bold',
                      }}
                      label={state?.state}
                      value={state?.state}
                      checked={selectedState?.toLowerCase() == state?.state.toLowerCase()}
                      onChange={(e) => {
                        setSelectedStateCustom((e.target as any)?.value);
                      }}
                    />
                  ))}
                </div>

                <div className={styles['inner-container']}>
                  <h6 className={styles['title']}>Actualizar datos</h6>

                  <form className={styles['form-modal']}>
                    <div className={styles['form-modal__subtitle']}>Fecha de inicio de disponibilidad</div>
                    <Calendar.Input
                      disabled={false}
                      value={formModal.availabilityStartDate}
                      dateFormat={'yyyy-mm-dd'}
                      onChange={(date) => {
                        const newData = formatDateToSave(date);
                        setFormModal((value: any) => (
                          { ...value, availabilityStartDate: newData }
                        ))
                      }}
                    />

                    <div className={styles['form-modal__subtitle']}>Marca probable*</div>
                    <ASelect
                      name="select-route-state-info"
                      options={brandsRef.current.map((brand: { name: string }) => ({ value: brand.name, label: brand.name }))}
                      placeholder='Elegir marca probable'
                      value={{ value: formModal.probableBrand, label: formModal.probableBrand }}
                      onChange={(v, actionMeta) => {
                        const probableBrand = v ? v.value : '';
                        setFormModal((val) => ({ ...val, probableBrand }));
                        if (actionMeta.action === 'clear' && formModal.probableBrand) setFormModal((val) => ({ ...val, probableBrand: '' }));
                      }}
                      isClearable
                    />

                    <div className={styles['form-modal__subtitle']}>Tecnologías</div>
                    <TextArea
                      data-testid="text-area-tech"
                      placeholder="Escribe tus tecnologías"
                      className={styles['comment-field']}
                      value={formModal.technologies}
                      ref={tecnologiesRef}
                      onMouseOut={() => setValueTextFile()}
                      onKeyDown={(e) => {
                        const el = e.target as HTMLTextAreaElement;
                        el.style.cssText = 'height:auto;';
                        el.style.cssText = 'height:' + el.scrollHeight + 'px';
                      }}
                    ></TextArea>

                    <div className={styles['form-modal__subtitle']}>Seguimiento</div>
                    <TextArea
                      data-testid="text-area-followup"
                      ref={followUpRef}
                      onMouseOut={() => setValueTextFile()}
                      placeholder="Escribe tu seguimiento"
                      className={styles['comment-field']}
                      value={formModal.followUp}
                      onKeyDown={(e) => {
                        const el = e.target as HTMLTextAreaElement;
                        el.style.cssText = 'height:auto;';
                        el.style.cssText = 'height:' + el.scrollHeight + 'px';
                      }}
                    ></TextArea>
                  </form>
                </div>

                <div className={styles['inner-container']}>
                  <h6>Casa - Proyecto</h6>
                  {!disabled &&
                    <p>debes elegir una de las casas diferente a sin grupo</p>
                  }
                  {groups
                    ?.filter((group) => group.title.toLowerCase() != 'prestamo')
                    .map((group, index) => (
                      <Radio
                        key={index}
                        name="RadioGroup-Project"
                        className={styles['radio-label']}
                        label={<GroupBadge group={group.title} showIcon showLeader />}
                        value={group.title}
                        checked={selectedGroup?.toLowerCase() == group?.title.toLowerCase()}
                        onChange={(e) => {
                          setSelectedGroup((e.target as any)?.value);
                        }}
                      />
                    ))}
                </div>
              </section>

            </div>

            <div className={styles.footer}>
              <Button
                name="save-change-user-state"
                type="button"
                disabled={!activateButton()}
                onClick={() => {
                  handlerValidationOfDataToSave();
                }}
              >
                Guardar
              </Button>
              {!disabled &&
                <p className={styles['text-information']}>Elige una casa o proyecto diferente a sin grupo</p>
              }
            </div>
          </div>

          <ModalComponent
            title={'Confirmar cambios'}
            successLabel={'Confirmar'}
            onClose={() => { setOpenConfirmationModal(false) }}
            show={openConfirmationModal}
            onSuccess={() => {
              handlerPostConfirmation();
              setLoading(true);
              setOpenConfirmationModal(false);
            }}
          >
            <div className={styles['text-confirmation-modal']}>{`¿Estás seguro/a de ${messageModals} `}<br />
              {` pragmático ${user.email} ?`}
            </div>
          </ModalComponent>
        </motion.div>
      )}
    </AnimatePresence>,
    document.querySelector('#root') || document.body,
  );
};

export default StateInfoEditComponent;
