import React, { useState } from 'react';

import { PencilIcon, BackButtonIcon } from '@/assets/icons/icons';
import ListHeatMap from '@/components/molecules/ListHeatMap';
import FormHeatMap from '@/components/organisms/FormHeatMap';
import Button from '@/components/ui/button';

import styles from './styles.module.scss';

interface UserFeedbackProps {
  email?: string;
}

export const UserHeatMap = ({ email }: UserFeedbackProps) => {
  const [showQuestions, setShowQuestions] = useState<boolean>(false);

  const handleShowForm = () => setShowQuestions(!showQuestions);

  return (
    <div className={styles['container-heat-map']}>
      {!showQuestions ? (
        <>
          <Button
            onClick={handleShowForm}
            name="completar-heatMap"
            className={styles['btn-complete-heat-map']}
          >
            <PencilIcon width={14} height={14} />
            Completar
          </Button>
          <ListHeatMap email={email} />
        </>
      ) : (
        <>
          <button className={styles['btn-back-button-heat-map']} onClick={handleShowForm}>
            <span className={styles['btn-back-button-heat-map__icon']}>
              <BackButtonIcon width={15} height={15} />
            </span>
            Volver
          </button>
          <FormHeatMap email={email} handleShowForm={handleShowForm} />
        </>
      )}
    </div>
  );
};
