import React, { useContext, useEffect, useState } from 'react';

import { format } from 'date-fns';
import * as locale from 'date-fns/locale';
import { MdCancel, MdCheckCircle, MdRemoveCircle } from 'react-icons/md';

import { ChevronDownIcon, ThumbsUp } from '@/assets/icons/icons';
import { ToolTipContact } from '@/components/contact';
import Table from '@/components/table';
import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import { FeedbackService } from '@/services/feedback';
import { Comment } from '@/services/feedback/types';

import style from './styles.module.scss';

interface UserFeedbackProps {
  email?: string;
}
export const UserFeedback = ({ email }: UserFeedbackProps) => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [dropdowned, setDropdowned] = useState<React.Key[]>([]);
  const [list, setList, setListLoading] = useList<Comment>();
  const cancellablePromise = useCancellablePromise();
  const [datePagination, setDatePagination] = useState<string>();
  useEffect(() => {
    requestComment();
  }, [email]);

  const requestComment = (page = 0) => {
    if (!email) return null;
    setListLoading(true, !page);
    cancellablePromise(
      FeedbackService.getComments({ email, commentDatePagination: datePagination, limit: 10 })
        .finally(() => setListLoading(false))
        .then((res) => {
          setDatePagination(res.lastItem?.commentDate);
          setList(res.comments || [], !!page, 5, !res.lastItem, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };
  const toggleDropDowned = (key: React.Key) => {
    const index = dropdowned.indexOf(key);
    if (index < 0) setDropdowned((v) => [...v, key]);
    else {
      const newData = [...dropdowned];
      newData.splice(index, 1);
      setDropdowned(newData);
    }
  };

  const formatDate = (date: Date): string => {
    const str = format(date, 'MMM-dd-yyyy', { locale: locale.es });
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const cropCommet = (comment: Comment): string => {
    const isLessThanHundred = comment.comment.length < 120;
    if (isLessThanHundred) return comment.comment;
    const isDropdowned = dropdowned.indexOf(comment.commentDate) > -1;
    if (isDropdowned) return comment.comment;
    return comment.comment.slice(0, 120) + '...';
  };
  return (
    <div className={style['users-table']}>
      <Table
        header={[
          { name: 'comment', label: 'Comentario' },
          { name: 'commentDate', label: 'Fecha de creación' },
          { name: 'event', label: 'Evento' },
          { name: 'assistance', label: 'Asistencia' },
          { name: 'punctuality', label: 'Puntualidad' },
          { name: 'leaderName', label: 'Creado por' },
        ]}
        data={list.list.map((comment) => ({
          _key: `${comment.commentDate}`,
          ...(comment as any),
          comment: (
            <div className={style['contact-field']}>
              <button
                className={style.chevron}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropDowned(`${comment.commentDate}`);
                }}
              >
                {comment.comment && comment.comment.length > 120 && (
                  <ChevronDownIcon width={17} height={17} fill={'#330072'} />
                )}
              </button>
              {
                <div>
                  <p
                    className={`${style['text']} ${
                      dropdowned.indexOf(comment.commentDate) > -1 ? style['text--deploy'] : ''
                    }`}
                  >
                    {comment.comment ? cropCommet(comment) : 'No se registraron comentarios'}
                  </p>
                </div>
              }
            </div>
          ),
          commentDate: formatDate(new Date(comment.commentDate)),
          event: comment.event ? comment.event : 'sin evento',
          assistance: comment.assistance ? (
            <div className={`${style.assistance} ${style.done}`}>
              <MdCheckCircle />
            </div>
          ) : comment.assistance === false ? (
            <div className={`${style.assistance} ${style.bad}`}>
              <MdCancel />
            </div>
          ) : (
            <div className={`${style.assistance} ${style.weird}`}>
              <ToolTipContact
                className={'-icon'}
                icon={{ icon: MdRemoveCircle, label: 'No se registró' }}
              />
            </div>
          ),
          punctuality: comment.punctuality ? (
            <div className={`${style.option} ${style.up}`}>
              <ThumbsUp height={30} width={30} />
            </div>
          ) : comment.punctuality === false ? (
            <div className={`${style.option} ${style.down}`}>
              <ThumbsUp height={30} width={30} />
            </div>
          ) : (
            <div className={`${style.assistance} ${style.weird}`}>
              <ToolTipContact
                className={'-icon'}
                icon={{ icon: MdRemoveCircle, label: 'No se registró' }}
              />
            </div>
          ),
          leaderName: (
            <div className={style['image-leader']}>
              <ToolTipContact
                className={''}
                user={{ images: comment.leaderImages, fullname: comment.leaderName }}
              />
              <p>{comment.leaderName}</p>
            </div>
          ),
        }))}
        advanced={{
          ...list,
          handleSearch: (page) => requestComment(page),
          dropdowned: dropdowned,
          isAnyVisible: false,
        }}
        classEmpty={true}
        emptyLabel={'Aún no se han registrado comentarios'}
      />
    </div>
  );
};
