/**
 * Solicitudes Pragma SA
 */

import React, { useEffect } from 'react';

import { createPortal } from 'react-dom';

import { motion, AnimatePresence } from 'framer-motion';
import { MdClose } from 'react-icons/md';

import Button from '@/components/ui/button';

import styles from './styles.module.scss';

interface ModalProps {
  title: string;
  children: React.ReactNode;
  show: boolean;
  successLabel: string;
  onClose: () => void;
  onSuccess: () => void;
  successDisabled?: boolean;
  secondClassContainer ?: string;
  secondClassHeader ?:string
}

const ModalComponent = ({
  title,
  children,
  onClose,
  show,
  onSuccess,
  successLabel,
  successDisabled,
  secondClassContainer,
  secondClassHeader
}: ModalProps) => {
  const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
    if ((e.charCode || e.keyCode) === 27) onClose();
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  return createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.modal}
          onClick={onClose}
        >
          <div className={[styles.container, secondClassContainer].join(' ') } onClick={(e) => e.stopPropagation()} aria-hidden="true">
            <div className={[styles.header, secondClassHeader].join(' ')}>
              <h2>{title}</h2>
              <MdClose
                name="modal-component_button-close"
                className={styles.close}
                onClick={onClose}
              />
            </div>
            <div className={styles.body}>{children}</div>
            <div className={styles.footer}>
              <Button
                name="modal-component_button-success"
                disabled={successDisabled}
                className={styles.button}
                onClick={onSuccess}
              >
                {successLabel}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.querySelector('root') || document.body,
  );
};

export default ModalComponent;
