import React, { useState, useEffect } from 'react';

import style from './styles.module.scss';

type TProps = {
  percentage: number;
};

export const AProcessBar = ({ percentage }: TProps) => {
  const [nameClass, setNameClass] = useState('');

  useEffect(() => {
    setNameClass(cssClass());
  }, [percentage]);

  const cssClass = (): string => {
    if (percentage <= 50) return 'progress-bar-child--progress';
    if (percentage >= 55 && percentage < 80) return 'progress-bar-child--progress-60';
    if (percentage >= 80 && percentage < 90) return 'progress-bar-child--progress-80';
    return 'progress-bar-child--progress-90';
  };
  return (
    <div className={style['progress-container']}>
      <div className={style['progress-percentage']}>{percentage}%</div>
      <div className={style['progress-bar-container']}>
        <div
          className={`${style['progress-bar-child']} ${style['progress-bar-child--shrinker']}`}
        ></div>
        <div
          className={`${style['progress-bar-child']} ${style[nameClass]}`}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default AProcessBar;

// {style['likely-brand-table']}
