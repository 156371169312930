/**
 * Solicitudes Pragma SA
 */

import React, { useState, useEffect } from 'react';

import style from './styles.module.scss';

type TProps = {
  estimatedRouteStories?: number | null;
  completedRouteStories?: number | null;
};

const styleRouteProgress = {
  shrinker: 'progress-bar-child--shrinker',
  complete: 'progress-bar-child--progress-complete',
  incomplete: 'progress-bar-child--progress-incomplete',
};

export const AProgressRoute = ({ completedRouteStories, estimatedRouteStories }: TProps) => {
  const [nameClass, setNameClass] = useState('');
  const [completedRoute, setCompletedRoute] = useState(0);
  const [estimatedRoute, setEstimatedRoute] = useState(0);

  useEffect(() => {
    setNameClass(cssClass());
  }, [nameClass, completedRoute, estimatedRoute]);

  const verifyNotNullValue = (parameter: number | null | undefined) =>
    parameter === null || typeof parameter === 'undefined' || (parameter || -1) < 0;

  const verifyNegativeValue = (parameter: number): number => {
    return parameter < 0 ? 0 : parameter;
  };

  const setStyleProgressBar = (completeRoute: number, estimateRoute: number): string => {
    if (completeRoute > estimateRoute || (completeRoute === estimateRoute && completeRoute != 0))
      return styleRouteProgress.complete;

    if (completeRoute > 0 && completeRoute < estimateRoute) return styleRouteProgress.incomplete;

    return styleRouteProgress.shrinker;
  };

  const cssClass = (): string => {
    let classProgress = styleRouteProgress.shrinker;
    let completeRoute = completedRouteStories ?? 0;
    let estimateRoute = estimatedRouteStories ?? 0;

    if (verifyNotNullValue(completedRouteStories) || verifyNotNullValue(estimatedRouteStories)) {
      classProgress = styleRouteProgress.shrinker;
    }

    if (completeRoute > estimateRoute) completeRoute = estimateRoute;

    completeRoute = verifyNegativeValue(completeRoute);
    estimateRoute = verifyNegativeValue(estimateRoute);

    classProgress = setStyleProgressBar(completedRoute, estimateRoute);
    setCompletedRoute(completeRoute);
    setEstimatedRoute(estimateRoute);

    return classProgress;
  };
  return (
    <div className={style['progress-container']}>
      <div className={style['progress-bar-container']}>
        <div
          className={`${style['progress-bar-child']} ${style['progress-bar-child--shrinker']}`}
        ></div>
        <div
          className={`${style['progress-bar-child']} ${style[nameClass]}`}
          style={{
            width: `${(completedRoute * 100) / estimatedRoute}%`,
          }}
        ></div>
      </div>
      <div>
        {completedRoute}/{estimatedRoute}
      </div>
    </div>
  );
};

export default AProgressRoute;
