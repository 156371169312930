import React, { useContext, useState, useEffect } from 'react';

import { format } from 'date-fns';
import * as locale from 'date-fns/locale';

import { AProcessBar } from '@/components/atoms/AProcessBar/AProcessBar';
import Table from '@/components/table';
import { utilsContext } from '@/contexts/utils';
import { LikelyBrandService } from '@/services/likelyBrand';
import { Datum } from '@/services/likelyBrand/type';

import style from './styles.module.scss';

interface LikelyBrandProps {
  email?: string;
}

export const LikelyBrand = ({ email }: LikelyBrandProps) => {
  const [dataLikelyBrand, setDataLikelyBrand] = useState<Datum[]>([]);
  // const [email, setEmail] = useState<string>('');

  const {
    actions: { setLoading },
  } = useContext(utilsContext);

  useEffect(() => {
    getDataLikelyBrand(false);
  }, []);

  const getDataLikelyBrand = async (isRefresh = false) => {
    setLoading(true);
    if (email != '' && email != undefined && email != null) {
      const response = await LikelyBrandService.getLikelyBrands(email, isRefresh);
      setDataLikelyBrand(response);
      setLoading(false);
    }
  };

  const formatDate = (date: string | Date) => {
    if (!date) return '';
    const d = new Date(date);
    let dateFormat = format(d, 'MMM-dd-yyyy', { locale: locale.es });
    dateFormat = dateFormat.charAt(0).toUpperCase() + dateFormat.slice(1);
    return dateFormat;
  };

  const handleRefresh = async () => {
    getDataLikelyBrand(true);
  };

  return (
    <div className={style['likely-brand-table']}>
      <button
        onClick={() => handleRefresh()}
        name="actualizar"
        className={style['btn-complete-likey-brand']}
      >
        Actualizar
      </button>
      <Table
        header={[
          { name: 'percentage', label: 'Porcentaje de afinidad' },
          { name: 'dateRequest', label: 'Fecha de consulta' },
          { name: 'nameBrand', label: 'Nombre de la marca' },
        ]}
        data={dataLikelyBrand.map((item) => ({
          _key: `${item.brandName}`,
          ...(item as any),
          percentage: (
            <div className={style['coverage-container__percentage']}>
              <AProcessBar percentage={item.affinityPercentage} />
            </div>
          ),
          dateRequest: formatDate(item.consultationDate),
          nameBrand: item.brandName,
        }))}
        classEmpty={true}
        emptyLabel={'Aún no se han registrado marcas probable'}
      />
    </div>
  );
};

export default LikelyBrand;
