import React, { useEffect, useState } from 'react';

import { CommentList } from '@/components/commentList';
import { Shimmer } from '@/components/ui/shimmer';
import { UserTimeline } from '@/components/userDetails/UserTimeline';
import { timeLineResumedMock } from '@/mocks/timeline.mock';
import { TimelineResumed, TimelineStoryService } from '@/services/timeline';
import { Pragmatico } from '@/services/users';

import styles from './styles.module.scss';

interface TProps {
  user: Pragmatico;
}

export const UserSummary: React.FC<TProps> = ({ user }) => {
  const unitTime = 'days';
  const [storieRoute, setStorieRoute] = useState<TimelineResumed | undefined>();
  const [isTimelineDaysLoading, setIsTimelineDaysLoading] = useState(false);
  const email = user.email;
  useEffect(() => {
    setIsTimelineDaysLoading(true);
    if (email) {
      TimelineStoryService.getTimelineUser(email)
        .then((d) => {
          setStorieRoute(d);
        })
        .finally(() => setIsTimelineDaysLoading(false));
    }
  }, [email]);

  return (
    <section className={styles['user-details']}>
      <CommentList user={user} />
      {isTimelineDaysLoading && <Shimmer repetitions={3} height={30} />}
      {storieRoute && (
        <UserTimeline
          timeline={storieRoute}
          userEmail={user.email}
          unitTime={unitTime}
        ></UserTimeline>
      )}
    </section>
  );
};
