import React, { useEffect, useState } from 'react';

import { ATimelineMessage } from '@/components/atoms/ATimelineMessage';
import { UserTimelineFigure } from '@/components/molecules/UserTimelineFigure';
import { timeLineMock, timeLineResumedMock } from '@/mocks/timeline.mock';
import { Datum, Timeline } from '@/services/timeline/types';

import style from './styles.module.scss';

enum unitsTime {
  WEEKS = 'weeks',
  DAYS = 'days',
}

enum classesTimeline {
  TIMELINE = 'timeline',
  TITLE = 'timeline__title',
  DATE = 'timeline__date',
  CIRCLE_END = 'timeline__circle__end',
}

export const UserTimeline = (props: any) => {
  const [userTimeline, setUserTimeline] = useState<Timeline[] | null>();
  const [userTimelineResumed, setUserTimelineResumed] = useState<Datum[] | null>();
  const [classTimeline, setclassTimeline] = useState('');
  const [classTitle, setclassTitle] = useState('');
  const [classDate, setclassDate] = useState('');
  const [classCircleEnd, setclassCircleEnd] = useState('');
  const userEmail = props.userEmail;
  const unitTime = props.unitTime;

  useEffect(() => {
    setclassTimeline(cssClass(classesTimeline.TIMELINE));
    setclassTitle(cssClass(classesTimeline.TITLE));
    setclassDate(cssClass(classesTimeline.DATE));
    setclassCircleEnd(cssClass(classesTimeline.CIRCLE_END));

    if (unitTime === unitsTime.WEEKS) {
      const data = props.timeline?.reverse();
      setUserTimeline(data);
    } else {
      setUserTimelineResumed(props.timeline);
    }
  });

  const cssClass = (className: string): string => {
    if (unitTime === unitsTime.DAYS) {
      className = className + '--resumed';
    }
    return className;
  };

  if (userEmail) {
    return (
      <div className={style[classTimeline]}>
        <div className={style[classTitle]}>
          Línea de Tiempo <strong className={style['timeline__title--pink']}>del Pragmático</strong>{' '}
        </div>
        <div className={style['timeline__container']}>
          <div className={style[classDate]}>
            <div className={style['timeline__date__title']}> Fecha Ingreso: </div>
            <div> {props.startDate} </div>
          </div>
          {unitTime === unitsTime.DAYS &&
            userTimelineResumed?.map((timeline) => {
              return (
                <UserTimelineFigure
                  key={timeline.date}
                  week={timeline.date}
                  finishedStories={timeline.finishedStories}
                  unitTime={unitTime}
                />
              );
            })}
          {unitTime === unitsTime.WEEKS &&
            userTimeline?.map((timeline) => {
              return (
                <UserTimelineFigure
                  key={timeline.week}
                  week={timeline.week}
                  finishedStories={timeline.finishedStories}
                  unitTime={unitTime}
                />
              );
            })}
          <div className={style[classCircleEnd]}></div>
        </div>
      </div>
    );
  } else {
    return <ATimelineMessage unitTime={props.unitTime}></ATimelineMessage>;
  }
};
