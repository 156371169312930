/**
 * Solicitudes Pragma SA
 */

// import { Apps, TApps } from '@/pages';
import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';

import type * as Types from './types';

export { Types };

export const getUser = async (): Promise<Types.TUser | null> => {
  const response: { data: Types.TUser } = await axiosClient({
    url: endpoints.profile.GET_USER,
    method: 'GET',
  });

  return response.data;
};
