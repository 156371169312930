import { getRandomRGBColor } from '@/helpers';
import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import { IGroup } from '@/services/group/types';

const COLORGROUPS: { [key: string]: string[] } = {
  gryffindor: ['#DD8FA6'],
  'slytherin 1': ['#6DC5B5'],
  'slytherin 2': ['#6DC5B5'],
  ravenclaw: ['#8FA3B6'],
  hufflepuff: ['#F2CD8D'],
  'sin grupo': ['#FDB0FD'],
};

const ADITIONALCOLORS = [
  ['#E0D4F5'],
  ['#D1BFF0'],
  ['#C1A9EB'],
  ['#A27FE1'],
  ['#8354D7', 'white'],
  ['#6429CD', 'white'],
  ['#5021A4', 'white'],
  ['#3C197B', 'white'],
  ['#281052', 'white'],
  ['#F8DCF8'],
  ['#F5CBF5'],
  ['#F1BAF1'],
  ['#EB97EB'],
  ['#E475E4'],
  ['#DD52DD', 'white'],
  ['#B242B2', 'white'],
  ['#873287', 'white'],
  ['#5C215C', 'white'],
  ['#38E8FF'],
  ['#DCDCFF'],
  ['#D0D0FF'],
  ['#B9B9FF'],
  ['#A1A1FF'],
  ['#8A8AFF'],
  ['#6E6ECC', 'white'],
  ['#535399', 'white'],
  ['#373766', 'white'],
  ['#DDC2FF'],
  ['#CEAFF5'],
  ['#BE9BEB'],
  ['#AF88E0'],
  ['#A074D6', 'white'],
  ['#814EC2', 'white'],
  ['#6327AD', 'white'],
  ['#440099', 'white'],
  ['#36007A', 'white'],
  ['#29005C', 'white'],
].values();

// const ADITIONALCOLORS = [
//   'rgb(185, 187, 111)',
//   'rgb(121, 188, 123)',
//   'rgb(104, 166, 48)',
//   'rgb(248, 101, 111)',
//   'rgb(25, 58, 29)',
//   'rgb(40, 99, 107)',
//   'rgb(71, 33, 163)',
//   'rgb(99, 61, 251)',
//   'rgb(110, 43, 19)',
//   'rgb(249, 107, 108)',
//   'rgb(37, 49, 79)',
//   'rgb(200, 110, 85)',
//   'rgb(200, 208, 42)',
//   'rgb(211, 187, 71)',
//   'rgb(35, 102, 217)',
//   'rgb(61, 129, 48)',
//   'rgb(113, 32, 57)',
// ].values();

export class Group implements IGroup {
  title: string;
  leader: string;
  active?: boolean | undefined;
  id: number;
  isProject: boolean;
  color = ['#282829'];
  constructor(group: IGroup) {
    this.title = group.title;
    this.active = group.active;
    this.id = group.id;
    this.leader = group.leader;
    this.isProject = group.isProject;
    this.getColor();
  }

  getColor(): string[] {
    this.color = COLORGROUPS[this.title.toLowerCase()] ||
      ADITIONALCOLORS.next()?.value || [getRandomRGBColor()];
    return this.color;
  }
}

class GroupsService {
  private $promise: Promise<Group[]> | null = null;
  async fetch(): Promise<Group[]> {
    const response = await axiosClient({ url: endpoints.feedback.groups, method: 'GET' });
    return response.data.map((group: IGroup) => new Group(group));
  }
  async get(): Promise<Group[]> {
    if (this.$promise) return this.$promise;
    this.$promise = this.fetch();
    return this.$promise;
  }
}
export const groupsService = new GroupsService();
export default groupsService;
