import React, { useState, useEffect } from 'react';

import style from './styles.module.scss';

type TProps = {
  days: string;
  timeframe: number;
};

export const ACircleDays = ({ days, timeframe }: TProps) => {
  const [nameClass, setNameClass] = useState('');
  useEffect(() => {
    setNameClass(cssClass());
  }, [days]);

  const cssClass = (): string => {
    const numberDays = parseInt(days);
    let classCircle = 'circle-container';

    if (Number.isNaN(numberDays)) {
      return classCircle;
    }

    if (numberDays > 0 && numberDays <= timeframe) classCircle = 'circle-container--color-ondate';
    if (numberDays > timeframe) classCircle = 'circle-container--color-outdate';

    return classCircle;
  };
  return <div className={`${style['circle-container']} ${style[nameClass]}`}>{days}</div>;
};

export default ACircleDays;
