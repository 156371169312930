import React from 'react';

import { HomeGroup, QuestionMarkIcon } from '@/assets/icons/icons';
import avatar from '@/assets/images/user.png';
import { useGroups } from '@/hooks/useGroups';
import endpoints from '@/services/endpoints';
import { ContactImage, Pragmatico } from '@/services/users';

import styles from './styles.module.scss';

export type sizeType = 'sm' | 'm' | 'l';
type ContactImageProps = {
  user: Partial<Pragmatico>;
  size?: sizeType;
  showGroup?: boolean;
} & { onClick?: () => void };

const getImage = (images?: ContactImage[] | null) => {
  let url = images?.find((url) => url.tipoFotografia == 'REDONDA');
  if (!url) url = images?.[0];
  if (url) return endpoints.GET_MULTIMEDIA + '/' + url?.contenido;
  else return avatar;
};

export const ContactImageC: React.FC<ContactImageProps> = ({
  user,
  size = 'sm',
  showGroup = true,
  ...rest
}) => {
  const groups = useGroups();
  const setColorGroup = (): string => {
    const group = user.group
      ? groups.find((group) => group.title.toLowerCase() == user.group?.toLowerCase())
      : groups.find((group) => group.title.toLowerCase() == 'sin grupo');
    return group?.color ? group.color[0] : 'red';
  };
  return (
    <figure className={`${styles.image} ${styles[size]} `} {...rest}>
      <img src={getImage(user.images)} alt={user.fullname} aria-hidden="true" />
      {showGroup && (
        <div className={styles.grupo} style={{ backgroundColor: setColorGroup() }}>
          {((!user.group || user.group.toLowerCase() == 'sin grupo') && (
            <QuestionMarkIcon width={13} height={15} />
          )) || <HomeGroup width={13} height={15} className={styles['home-image-icon-svg']} />}
        </div>
      )}
    </figure>
  );
};

export default ContactImageC;
