import React, { useContext, useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';
import { CancelIcon, CheckRouteIcon, SearchIcon } from '@/assets/icons/icons';
import avatar from '@/assets/images/user.png';

import { UserService, GetPragmaticsParams, Pragmatico, ContactImage } from '@/services/users';
import endpoints from '@/services/endpoints';
import RouteTracing, { RouteChapterName } from '@/services/route';

import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import { replaceValue } from '@/helpers';

import ASelect from '@/components/atoms/ASelect/ASelect';
import { Contact } from '@/components/contact';
import Table from '@/components/table';
import TextField from '@/components/ui/textField';
import Checkbox from '@/components/ui/checkbox';
import Button from '@/components/ui/button';
import ModalComponent from '@/components/ui/modal';

interface PragmaticAndStateRoute extends Pragmatico {
  stateRoutes: string;
}

type ColumnsToShow = {
  name: keyof PragmaticAndStateRoute;
  label: string;
  filterable?: boolean;
  immobilizable?: boolean;
};

type PragmaticSelected = {
  email: string;
  image: string;
}

const formatEntryDate = (date: string): string => {
  const dateValid = (date && date !== 'Invalid date')? date : ''
  return dateValid 
  ? date.slice(8, 10) + ' / ' + new Intl.DateTimeFormat("en-US", { month: "short" }).format(new Date(date))+ ' / ' + date.slice(0,4)
  : String(
    new Date()
      .toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }).split(' ')
    .join(' / '))
}

const getImage = (images?: ContactImage[] | null) => {
  let url = images?.find((url) => url.tipoFotografia == 'REDONDA');
  if (!url) url = images?.[0];
  if (url) return endpoints.GET_MULTIMEDIA + '/' + url?.contenido;
  else return avatar;
};

export const RoutesGrowth = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);

  const [rutas, setRutas] = useState<RouteChapterName[]>([]);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<PragmaticAndStateRoute>();
  const [searchValue, setSearchValue] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');
  const [componentMounted, setComponentMounted] = useState(false);

  const [numberPragmaticsSelected, setNumberPragmaticsSelected] = useState(0);
  const [pragmaticsSelected, setPragmaticsSelected] = useState<any>([]);
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [routeSelected, setRouteSelected] = useState<string | undefined>(undefined);
  const [isRepeatedRoute, setIsRepeatedRoute] = useState(false);

  const checkboxGeneral = useRef<HTMLInputElement>(null);

  const getAllroutes = async () => {
    try {
      const response = await RouteTracing.getAllRoutes()
      setRutas(response.routes)
    } catch (error) {
      addToast(`Rutas no han sido cargadas. Error: ${error}`, 'danger')
    }
  }

  const getPragmaticRoutes = async (email: string, routeSelected: string) => {
    try {
      const arrayRoutes = await RouteTracing.getPragmaticoRoutes(email)

      arrayRoutes.forEach((route) => {
        if (route.name === routeSelected) {
          addToast(`El usuario ${email}  ya ha sido asignado a la ruta ${routeSelected}`, 'danger');
          setIsRepeatedRoute(true);
        }
      })

    } catch (e) {
      addToast(`Error: ${e}`, 'danger')
    }
  }

  const requestList = (page = 0, limit = 10) => {
    setListLoading(true, !page);
    const pragmaticParams: GetPragmaticsParams = {
      page: page + 1,
      limit,
      date:'desc'
    };

    if (currentSearch) pragmaticParams.user = currentSearch;
    cancellablePromise(
      UserService.getPragmaticos(pragmaticParams)
        .finally(() => {
          setListLoading(false);
          setLoading(false);
        })
        .then((res) => {
          const data = res.users.map((el) => {
            const formatDate = formatEntryDate(el.PragmaEntryDate);
            const stateRoutesNew = (el.routeDetails.length > 0)? 'Con/ruta' : 'Sin/ruta';
            const dataUser = { ...el, PragmaEntryDate: formatDate, stateRoutes:stateRoutesNew };
            return {
              ...dataUser
            };
          });
          setList(data || [], !!page, res.length, res.length - 1 == page, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  useEffect(() => {
    setList([]);
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (componentMounted) {
      setLoading(true);
      requestList();
    }
  }, [ currentSearch, componentMounted]);

  useEffect(() => {
    cleanUseStates();
    getAllroutes();
  }, []);

  useEffect(() => {
    unCheckedCheckboxes();
  });

  const cleanUseStates = () => {
    setRouteSelected(undefined);
    setOpenCloseModal(false);
    setPragmaticsSelected([]);
    setNumberPragmaticsSelected(0)
  }

  const handleSearch = (value: string) => {
    setCurrentSearch(
      replaceValue({
        value,
        searchValue: /\s+/gi,
        replaceValue: ' ',
      }),
    );
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setCurrentSearch('');
  };

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    setSearchValue(text);
    if (text.length === 0) setCurrentSearch('');
  };

  const getTrailingSearchIcon = (): React.ReactNode => {
    return searchValue.length > 0 ? (
      <CancelIcon width={16} height={16} onclick={handleClearSearch} />
    ) : (
      <SearchIcon width={16} height={16} onclick={() => handleSearch(searchValue)} />
    );
  };

  const addPragmaticToSelectedPragmatics = (pragmaticSelected: PragmaticSelected) => {
    const { email, image } = pragmaticSelected;
    setNumberPragmaticsSelected((currentValue) => currentValue + 1)
    setPragmaticsSelected((previousValue: []) => [...previousValue, { email, image }])
  }

  const isUniquePragmaticSelected = (email:string) => {
    const emailsArray = pragmaticsSelected.map((item: PragmaticSelected) => item.email);
    return !emailsArray.includes(email);
  }

  const addOrDeleteNumberAndPragmatic = (checked: boolean, pragmatic: Pragmatico): void => {
    const { email, images } = pragmatic;
    if (checked && !isUniquePragmaticSelected(email)) {
      addToast(`El pragmático ${email} ya ha sido seleccionado `, 'danger')
    } else if (checked && isUniquePragmaticSelected(email)) {
      const image = getImage(images);
      addPragmaticToSelectedPragmatics({ email, image });
    } else {
      deleteUserFromSelectedPragmatics(email);
    }
  }

  const unCheckedCheckboxes = () => {
    const inputs = document.getElementsByTagName('input');
    const checkboxInput = checkboxGeneral.current;
    if(checkboxInput && checkboxInput.checked){checkboxInput.checked = !(pragmaticsSelected.length === 0)};

    [...inputs].forEach((input) => {
      const { name, value } = input;
      if (name === 'checkbox-pragmatic-ruta-crecimiento') {
        const [email] = value.split('-');
        input.checked = !isUniquePragmaticSelected(email);
      }
    })
  }

  const handleCheckboxSelectedAll = (checked: boolean): void => {
    const inputs = document.getElementsByTagName('input');

    [...inputs].forEach((input) => {
      const { name, value } = input;
      if (name === 'checkbox-pragmatic-ruta-crecimiento') {
        input.checked = checked;
        const [email, image] = value.split('-');

        if (checked) {
          if(isUniquePragmaticSelected(email))addPragmaticToSelectedPragmatics({ email, image });
        } else {
          cleanUseStates()
        }
      }
    })
  }

  const handlerCloseModal = (): void => {
    setOpenCloseModal(false);
    setRouteSelected(undefined);
    setIsRepeatedRoute(false);
    setNumberPragmaticsSelected(() => pragmaticsSelected.length);
  }

  const deleteUserFromSelectedPragmatics = (emailPragmatic: string): void => {
    setPragmaticsSelected((previousValue: []) =>
      previousValue.filter(({ email }) => email !== emailPragmatic)
    )
    setNumberPragmaticsSelected((currentValue) => currentValue - 1)
  }

  const saveEmailsWithRoutes = async (data: { route: string, emails: string[] }): Promise<void> => {
    try {
      await RouteTracing.setRoutes(data);
      requestList();
      cleanUseStates();
      setLoading(false);
      addToast('La ruta se ha asignado', 'success');
    } catch (e:any) {
      setLoading(false);
      (e.response.data.statusCode === 404)
      ? addToast(`Ruta asignada. Correo no ha sido enviado(usuario no existe en disponibles)`, 'danger')
      :addToast(`Error: ${e}`, 'danger');
      requestList();
      cleanUseStates();
    }
  }

  const handlerBeforeSaving = (): void => {
    if (isRepeatedRoute) {
      addToast(`Hay usuarios que ya han sido asignados con la ruta ${routeSelected}`, 'danger')
    } else if (routeSelected === undefined) {
      addToast(`Ruta no ha sido seleccionada`, 'danger')
    } else {
      setLoading(true);
      saveEmailsWithRoutes({
        route: routeSelected,
        emails: pragmaticsSelected.map((pragmatic: { email: string }) => pragmatic.email)
      });

      handlerCloseModal();
    }
  }

  const addOptionNameRoute = (option: { value: any; label: any } | null): void => {
    if (option) {
      const { value } = option;
      setRouteSelected(value)
      setIsRepeatedRoute(false);
      pragmaticsSelected.forEach((pragmatic: { email: string }) => {
        getPragmaticRoutes(pragmatic.email, value);
      })
    } else {
      addToast('La ruta no ha sido seleccionada', 'danger')
    }
  }

  const pragmaticosColumns: ColumnsToShow[] = [
    {
      name: 'pragmatico', label: (
        <>
          <Checkbox
          ref= {checkboxGeneral}
          onChange={(event) => {
            const { target } = event;
            const { checked } = target;
            handleCheckboxSelectedAll(checked)
          }} />
          <div className={styles['space-gap']}>Pragmáticos</div>
        </>
      ), filterable: false
    },
    { name: 'capacity', label:'Capacidad'},
    { name: 'PragmaEntryDate', label: 'Fecha de ingreso', filterable: false },
    { name: 'level', label: 'Seniority', filterable: false },
    { name: 'stateRoutes', label: 'Asignación ruta', filterable: false },
  ].map(
    (data) =>
      ({ ...data, filterable: data?.filterable !== false } as ColumnsToShow),
  );

  return (
    <>
      <ModalComponent
        title="Asignar ruta de conocimiento"
        onClose={() => {
          handlerCloseModal()
        }}
        onSuccess={() => {
          handlerBeforeSaving()
          return;
        }}
        show={openCloseModal}
        successLabel="Asignar ruta"
        successDisabled={!routeSelected || isRepeatedRoute}
        secondClassContainer={styles['modal-container']}
        secondClassHeader={styles['modal-header']}
      >
        <>
          <section className={styles['modal-container__body']}>
            <div className={styles['modal-container__subtitle']}>La ruta será asignada a :</div>

            <section className={styles['modal-container__badges']}>
              {
                [...pragmaticsSelected].map((pragmatic, index) => {
                  const { email, image } = pragmatic;
                  return (
                    <div className={styles['modal-container__badges__custom-badge']} key={index}>
                      <img src={image} alt='pracmatic' />
                      {email}
                      <CancelIcon width={16} height={16} className={styles['icon-svg']}
                        onclick={() => { deleteUserFromSelectedPragmatics(email) }} />
                    </div>
                  )
                })
              }
            </section>

            <div className={styles['modal-container__subtitle']}>Elija la ruta</div>
            <ASelect
              name="select-route"
              options={rutas.map((route: { name: string; chapter: string }) => ({ value: route.name, label: route.name }))}
              placeholder='Elegir la ruta'
              value={{ value: routeSelected, label: routeSelected }}
              onChange={(value, actionMeta) => {
                addOptionNameRoute(value)
                if (actionMeta.action === 'clear' && routeSelected) setRouteSelected(undefined);
              }}
              isClearable
            />

            <div className={styles['modal-container__text']}>Al dar click en
              <strong> asignar ruta </strong>
              el pragmático recibirá en su correo la invitación para su ruta del classroom 🚀
            </div>
          </section>
        </>
      </ModalComponent>

      <div className={styles.options}>
        <section className={styles['options__search']}>
          <h2>Buscar pragmático</h2>
          <TextField
            placeholder="Buscar pragmático"
            value={searchValue}
            onEnter={() => handleSearch(searchValue)}
            onChange={handleSearchValue}
            trailing={getTrailingSearchIcon()}
          />
        </section>

        <section className={
          numberPragmaticsSelected>0
          ?styles['options__modal-open']
          : styles['options__modal']
        } >
          <p>{numberPragmaticsSelected} seleccionados</p>
          <Button
            name='button-asign-rutas'
            theme='quaternary'
            type='button'
            onClick={() => { setOpenCloseModal(true) }}
            className={styles['btn-asign']}
          >
            Asignar rutas
          </Button>
        </section>
      </div>

      <div className={styles['users-table-ruta-crecimiento']}>
        <Table
          header={pragmaticosColumns}
          data={list.list.map((pragmatico) => ({
            _key: pragmatico.email,
            ...(pragmatico as PragmaticAndStateRoute),
            pragmatico: (
              <div className={styles['contact-field']}>
                <Checkbox
                  name='checkbox-pragmatic-ruta-crecimiento'
                  onChange={(event) => {
                    const { target } = event;
                    const { checked } = target;
                    addOrDeleteNumberAndPragmatic(checked, pragmatico)
                  }}
                  id={pragmatico.email + '-' + getImage(pragmatico.images)}
                />

                <Contact
                  user={pragmatico}
                  className={styles.contact}
                  show={['status']}
                  size="sm"
                  showDetails={false}
                />
              </div>
            ),
            stateRoutes: (
              <div className={styles['container-check-routes']}>
                <figure
                  className={(pragmatico.stateRoutes === 'Con/ruta')
                    ? styles['icon-check']
                    : styles['icon-uncheck']}
                >
                  <CheckRouteIcon
                    width={24}
                    height={24}
                    fill={(pragmatico.stateRoutes === 'Con/ruta')
                      ? '#00b880' : '#9d9da1'}

                    fillICon={(pragmatico.stateRoutes === 'Con/ruta')
                      ? '#ffff' : 'none'}
                  />
                </figure>
                {pragmatico.stateRoutes.split('/').join(' ')}
              </div>
            )
          }))}
          advanced={{
            ...list,
            handleSearch: (page) => requestList(page),
            isAnyVisible: false,
          }}
          onChangeFilter={()=> {unCheckedCheckboxes()}}
          emptyLabel={'No existen datos para mostrar'}
        /> 
      </div>
    </>
  );
};

export default RoutesGrowth;
