/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useRef, useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { BackButtonIcon, ScrollIcon } from '@/assets/icons/icons';
import { PRAGMA } from '@/pages';

import Header from './header';
import styles from './styles.module.scss';

export enum TemplateType {
  template1 = 'template1',
  template2 = 'template2',
}

export type TStateLocation = {
  id: number;
  previusPath: string;
};

const Template = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [translationPath, setTranslationPath] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [scroll, setscroll] = useState(0);
  const myRef = useRef<HTMLDivElement>(null);

  const [template, setTemplate] = useState<TemplateType | undefined>(TemplateType.template1);
  const [showTitle, setShowTitle] = useState<boolean>(true);
  const [full, setFull] = useState(false);
  const [backButton, setBackButton] = useState(false);

  useEffect(() => {
    let redirect = true;
    PRAGMA.forEach((app) => {
      // Validamos si la ruta es oculta
      app.routes.forEach((route) => {
        if (route.path?.split('/')[1] === location.pathname.split('/')[1] && route.hidden) {
          setTranslationPath(`${route.name}`);
          redirect = false;
        }
      });

      // Validamos si la ruta no es oculta
      app.routes.forEach((route) => {
        if (route.path?.split('/')[1] === location.pathname.split('/')[1]) {
          setTranslationPath(`${route.name}`);
          setTemplate(route.template);
          setShowTitle(route.showTitle === false ? false : true);
          setFull(route.full ? true : false);
          setBackButton(route.backButton ? true : false);
          redirect = false;
        }
      });

      if (app.index === location.pathname) {
        setTranslationPath(`${app.name}`);
        redirect = false;
      }
    });
    // Si ninguna de las validaciones se cumple redirecciona al 404
    if (redirect) navigate('/404', { replace: true });
  }, [location]);

  // if (state.login || state.menu.loading) return <SpinnerLoader />;

  const handleScroll = () => {
    setscroll(myRef.current?.scrollTop || 0);
  };
  // return state.logged && state.menu.loaded && state.data && authPage ? (
  return (
    <div
      id="template"
      className={`${styles.template} ${template ? styles[template] : ''}`}
      onScroll={handleScroll}
      ref={myRef}
    >
      <Header setShowMenu={setShowMenu} />
      <div className={` ${styles.main} ${full && styles['main--expanding-container']}`}>
        <div className={styles.container}>
          {/* <SideBar showMenu={showMenu} setShowMenu={setShowMenu} /> */}
          <div className={` ${styles.page}  ${full && styles['page--expanding-page']}`}>
            <div className={` ${styles['header-page']}`}>
              {backButton && (
                <button
                  className={styles['back-button']}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <span className={styles['icon']}>
                    <BackButtonIcon width={15} height={15} />
                  </span>
                  Volver
                </button>
              )}
              {showTitle && <h1 className={styles.title}>{translationPath}</h1>}
            </div>
            <div className={styles.body}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles['button-pragma']}
        onClick={() => {
          window.open('https://pragma.workplace.com/search/top/?q=%23Pragma7000', '_blank');
        }}
        aria-hidden={true}
      >
        <p>#Pragma7000</p>
      </div>
      {scroll > 200 && (
        <div
          className={styles['button-scroll']}
          onClick={() => {
            if (myRef?.current?.scrollTop) {
              myRef.current.scrollTop = 0;
            }
          }}
          aria-hidden={true}
        >
          <ScrollIcon width={20} height={20} />
        </div>
      )}
    </div>
  );
};

export default Template;
