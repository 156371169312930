import React, {
  ReactNode,
  useState,
  useEffect,
  Children,
  isValidElement,
  cloneElement,
} from 'react';

import styles from './styles.module.scss';

interface ICardContent {
  title: string;
  children?: ReactNode;
  refdrag?: React.RefObject<HTMLDivElement>;
}

const CardComponent = ({ title, children }: ICardContent) => {
  const [huCount, setHuCount] = useState(0);

  useEffect(() => {
    setHuCount(Children.count(children) - 1);
  }, [children]);

  return (
    <>
      <div className={styles['card-container']}>
        <div className={styles['card-header']}>
          <h3 className={styles['card-header-title']}>{title}</h3>
          <h3 className={styles['card-header-counter']}>{huCount}</h3>
        </div>
        <div className={styles['card-body']}>
          {Children.map(children, (child, index) => {
            if (isValidElement(child)) {
              return cloneElement(child, { key: index });
            }
            return child;
          })}
        </div>
        {/* {provided.placeholder} */}
      </div>
    </>
  );
};

export default CardComponent;
