/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import styles from './styles.module.scss';

const NotFound = () => {
  // const t = useLocalTranslation(i18n as Resource);

  return (
    <div className={styles['not-found']}>
      <h1>404</h1>
      <h2>Error</h2>
      <h3>¡Rayos! Lo sentimos</h3>
      <h4>La página que estás buscando no la tenemos.</h4>
    </div>
  );
};

export default NotFound;
