import Rutas from "@/app/rutas"
import usePermission from "@/routes/protect/usePermission"
import React from "react"
import { Navigate } from "react-router-dom"

type ProtectedProps = {
    children: any
    path:string | undefined
}

const Protected = ({path,children}:ProtectedProps) =>{
    const {routesAllowed} = usePermission()   
    if(path && (routesAllowed?.includes(path))){
        return children 
        
    }
    if(routesAllowed && routesAllowed?.length>0){
        return <Navigate to={routesAllowed[0]} replace />
    }
    
}
    
export default Protected
