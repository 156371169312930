/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import SessionProvider from '@/contexts/session';

import UtilsProvider from './utils';

const Contexts = ({ children }: { children: React.ReactNode }) => (
  <SessionProvider>
    <UtilsProvider>{children}</UtilsProvider>
  </SessionProvider>
);

export default Contexts;
