import React from 'react';

import { Link } from 'react-router-dom';

import { useGoToUserDetails } from '@/app/feedbackComments';
import { ContactImageC, sizeType } from '@/components/contact/contact-image';
import { ContactContentPopover } from '@/components/contact/contact-popover';
import { GroupBadge } from '@/components/groups';
import Popover from '@/components/ui/popover';
import { capitalize } from '@/helpers';
import { Pragmatico } from '@/services/users';

import styles from './styles.module.scss';

type ContactInfoProps = {
  user: Partial<Pragmatico>;
  children?: React.ReactNode;
  className?: string;
  size?: sizeType;
  showDetails?: boolean;
  show?: propertyToShow[];
  onChangeUser?: (user: Pragmatico) => void; //esta prop debería ser interna y alterar un estado global
 // openModalForm?:()=>void;
};

type propertyToShow = 'email' | 'capacity' | 'status' | 'group';

const getUserStatus = (user: Partial<Pragmatico>) => {
  if (!user.status) return;
  const capitalizeStatus = capitalize(user.status);
  return (
    <div className={styles.status} data-name={capitalizeStatus}>
      {capitalizeStatus}
    </div>
  );
};

export const ContactInfo: React.FC<ContactInfoProps> = ({
  user,
  children,
  size = 'm',
  className,
  showDetails = true,
  show,
  onChangeUser,
 // openModalForm
}) => {
  const [url] = useGoToUserDetails(user.email);
  const showProperty = (property: propertyToShow) => {
    return !show || (show.includes(property) && user[property]);
  };
  return (
    <div className={`${styles.container} ${className}`}>
      {true && (
        <Popover
          content={
            showDetails &&
            !!onChangeUser && (
              <ContactContentPopover user={user as Pragmatico} onChangeUser={onChangeUser}/>
            )
          }
        >
          <ContactImageC user={user} size={size} />
        </Popover>
      )}
      <div className={styles.info}>
        {showDetails && user.fullname && (
          <Link to={url}>
            <h3 data-name="fullname">{user.fullname}</h3>
          </Link>
        )}
        {!showDetails && user.fullname && <h3 data-name="fullname">{user.fullname}</h3>}
        {showProperty('email') && <p data-name="email">{user.email}</p>}
        {showProperty('capacity') && <p data-name="capacity">{user.capacity}</p>}
        {showProperty('status') && getUserStatus(user)}
        {show?.includes('group') && (
          <p data-name="group">
            <GroupBadge group={user.group as string} showIcon showLeader />
          </p>
        )}
        {children}
      </div>
    </div>
  );
};
