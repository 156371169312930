import React from 'react';

import styles from './styles.module.scss';

type TProps = {
  msg: string;
  isRow?: boolean;
  look?: boolean;
};

const EmptyState = ({ msg, isRow, look }: TProps) => {
  if (isRow) {
    return (
      <tr>
        <td colSpan={100}>
          {look ? (
            <div className={styles['empty-state-comment']}>{msg}</div>
          ) : (
            <div className={styles['empty-state']}>{msg}</div>
          )}
        </td>
      </tr>
    );
  }

  return look ? (
    <div className={styles['empty-state-comment']}>{msg}</div>
  ) : (
    <div className={styles['empty-state']}>{msg}</div>
  );
};

export default EmptyState;
