/**
 * Solicitudes Pragma SA
 */

// BASE URL
const BASE_URL_FEEDBACK = process.env.REACT_APP_DOMAIN;
const BASE_URL_MULTIMEDIA = process.env.REACT_APP_MULTIMEDIA;

export default {
  GET_TOKEN: `${process.env.REACT_APP_DOMAIN}/token`,
  GET_MULTIMEDIA: BASE_URL_MULTIMEDIA,
  feedback: {
    base: BASE_URL_FEEDBACK,
    groups: `${BASE_URL_FEEDBACK}/groups`,
    historyComments: `${BASE_URL_FEEDBACK}/historyComments`,
    users: `${BASE_URL_FEEDBACK}/users`,
    comments: `${BASE_URL_FEEDBACK}/comments`,
    events: `${BASE_URL_FEEDBACK}/events`,
    states: `${BASE_URL_FEEDBACK}/states`,
    stories: `${BASE_URL_FEEDBACK}/userStories`,
    form: `${BASE_URL_FEEDBACK}/formData`,
    timeRouteDays: `${BASE_URL_FEEDBACK}/parameter`,
    getRoutes: `${BASE_URL_FEEDBACK}/getPragmaticoRoutes`, 
    getAllRoutes: `${BASE_URL_FEEDBACK}/getAllRoutes`,
    getBrands:`${BASE_URL_FEEDBACK}/brands`,
    assignPlatform:`${BASE_URL_FEEDBACK}/user`,
  },
  routes:{
    getRoutes: `${BASE_URL_FEEDBACK}/getPragmaticRoutes`, 
    getAllRoutes: `${BASE_URL_FEEDBACK}/getAllRoutes`, 
    setRoutes: `${BASE_URL_FEEDBACK}/assignRoute`,
    getForms: `${BASE_URL_FEEDBACK}/routeProgress`,
    sendForm: `${BASE_URL_FEEDBACK}/routeTrace`,
  },
  profile: {
    GET_USER: `${BASE_URL_FEEDBACK}/me`,
  },
};
