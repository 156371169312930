import React, { useState } from 'react';
import { format } from 'date-fns';
import * as locale from 'date-fns/locale';

import { CheckToastTicon } from '@/assets/icons/icons';
import Button from '@/components/ui/button';
import ModalDeleteHU from '@/components/userStories/modalDeleteHU';
import ModalSuccess from '@/components/userStories/modalSuccess';
import { capitalize } from '@/helpers';
import { userStory } from '@/services/storie';

import styles from './styles.module.scss';

interface StoryProps {
  story: userStory;
  index: number;
}

const formatDate = (date: string | Date | null | undefined): string => {
  if (!date) return '-';
  const currentDate = new Date(date);
  const month = format(currentDate, 'MMMM', { locale: locale.es });
  const capitalizedMonth = capitalize(month);
  const day = format(currentDate, 'dd');
  return `${day} de ${capitalizedMonth}`;
};

const getNameFromEmail = (email?: string): string => {
  if (!email) return '';

  const leaderNames = email.replace('@pragma.com.co', '');
  const leaderFullName = leaderNames?.replace('.', ' ');
  const leaderNameHolder = leaderFullName?.split(' ');
  leaderNameHolder[0] =
    leaderNameHolder[0]?.charAt(0).toUpperCase() + leaderNameHolder[0]?.slice(1);
  leaderNameHolder[1] =
    leaderNameHolder[1]?.charAt(0).toUpperCase() + leaderNameHolder[1]?.slice(1);

  return leaderNameHolder.join(' ');
};

export const HUcomponent = ({ story, index }: StoryProps) => {
  return (
    <>
      <div className={styles['story-card']}>
        <div className={styles.huComponent}>
          <section className={styles.story}>{story.name}</section>
          <p className={styles.hu}>
            {formatDate(new Date(story.assignedDate))} por: {getNameFromEmail(story.assignedBy)}
          </p>
        </div>
      </div>
    </>
  );
};
