/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect } from 'react';

import { setDefaultLocale } from 'react-datepicker';
import { useNavigate } from 'react-router-dom';

import { sessionContext } from '@/contexts/session';
import { useTranslation } from '@/hooks/useLocalTranslation';
import Pages from '@/pages';

const App = () => {
  const session = useContext(sessionContext);
  const navigate = useNavigate();
  const { i18n } = useTranslation('pages');

  // Verificamos de inicio que estemos logeados
  useEffect(() => {
    session.actions.login().catch(() => navigate('/login'));
    session.actions.getLanguage();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(session.state.language);
    setDefaultLocale(session.state.language);
  }, [session.state.language]);

  // Obtenemos menu lateral e info de usuario cuando estemos logeados
  useEffect(() => {
    if (session.state.logged) {
      session.actions
        .getUser()
        // .then(() =>
        //   session.actions
        //     .getMenu(PRAGMA)
        //     .then(() => {
        //       session.actions.getNotifications().catch((err) => addToast(err.message, 'danger'));
        //       setInterval(() => {
        //         session.actions.getNotifications().catch((err) => addToast(err.message, 'danger'));
        //       }, 30000);
        //     })
        //     .catch(() => navigate('/error', { state: { msg: t('error.getMenu') } })),
        // )
        .catch(() => {
          //console.error('error getUser', e);
          // navigate('/error', { state: { msg: t('error.getUser') } }
        });
    }
  }, [session.state.logged]);
  return <Pages />;
};

export default App;
