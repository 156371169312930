/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { createRoot } from 'react-dom/client';

import Amplify from '@aws-amplify/auth';
import es from 'date-fns/locale/es';
import { use } from 'i18next';
import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import awsconfig from './aws-exports-prod';
import Contexts from './contexts';

import './styles/index.scss';

use(initReactI18next).init({ lng: 'es', interpolation: { escapeValue: false } });
Amplify.configure(awsconfig);
registerLocale('es', es);

const root = createRoot(document.getElementById('root') || document.body);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Contexts>
        <App />
      </Contexts>
    </BrowserRouter>
  </React.StrictMode>,
);
