import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';

import { GetPragmaticsParams, Pragmatico, PragmaticoResponse } from './types';

export * from './types';

export class UserService {
  static async getPragmaticos(params: GetPragmaticsParams): Promise<PragmaticoResponse> {
    const query = await axiosClient({
      url: endpoints.feedback.users,
      method: 'GET',
      params,
    });
    const response: PragmaticoResponse = {
      ...query.data,
      users: (query.data.users as Pragmatico[]).map((user, index) => ({ ...user, _id: index })),
    };
    return response;
  }

  static async getPragmaticoByEmail(email: string): Promise<Pragmatico> {
    const response = await axiosClient({
      url: endpoints.feedback.users + `/${email}`,
      method: 'GET',
    });
    return response.data.user;
  }

  static async saveUserChanges(email: string, newState?: string, newGroup?: string) {
    const response = await axiosClient({
      url: endpoints.feedback.users + `/${email}`,
      method: 'PUT',
      data: {
        newState,
        newGroup,
      },
    });
    return response.data;
  }

  static async assignPlatform(email: string, newGroup: string, oldGroup:string) {
    const response = await axiosClient({
      url: ` ${endpoints.feedback.assignPlatform}/${email}/assignPlatform`,
      method: 'POST',
      data: {
        newGroup,
        oldGroup
      },
    });
    return response;
  }
}

export default UserService;
