import React, { useEffect, useState } from 'react';

import { HomeGroup, QuestionMarkIcon } from '@/assets/icons/icons';
import { Badge } from '@/components/ui/badge';
import { capitalize } from '@/helpers';
import { useGroups } from '@/hooks/useGroups';
import { Group } from '@/services/group';

import styles from './styles.module.scss';

type TProps = {
  group?: string;
  showIcon?: boolean;
  showLeader?: boolean;
};

const getNameFromEmail = (email?: string): string => {
  if (!email) return '';

  const leaderNames = email.replace('@pragma.com.co', '');
  const leaderFullName = leaderNames?.replace('.', ' ');
  const leaderNameHolder = leaderFullName?.split(' ');
  leaderNameHolder[0] =
    leaderNameHolder[0]?.charAt(0).toUpperCase() + leaderNameHolder[0]?.slice(1);
  leaderNameHolder[1] =
    leaderNameHolder[1]?.charAt(0).toUpperCase() + leaderNameHolder[1]?.slice(1);

  return leaderNameHolder.join(' ');
};

export const GroupBadge: React.FC<TProps> = ({ group, showIcon = false, showLeader = false }) => {
  const groups = useGroups();
  const [selectedGroup, setGroup] = useState<Group | null>(null);
  useEffect(() => {
    const currentGroup =
      (!group && groups?.find((g) => g.title.toLocaleLowerCase() == 'sin grupo')) ||
      groups?.find((g) => g.title.toLowerCase() == group?.toLowerCase());
    if (currentGroup) setGroup(currentGroup || { title: group, color: 'gray' });
  }, [groups, group]);

  return (
    <Badge bgColor={selectedGroup?.color[0] || 'gray'}>
      <span className={`${styles['group-badge']}`} style={{ color: selectedGroup?.color[1] }}>
        {showIcon && group && group.toLowerCase() != 'sin grupo' ? (
          <HomeGroup width={15} height={15} fill={selectedGroup?.color[1] || 'black'} />
        ) : (
          <QuestionMarkIcon height={15} width={15} fill={selectedGroup?.color[1] || 'black'} />
        )}
        {selectedGroup?.title ? capitalize(selectedGroup.title) : 'Sin grupo'}
        {showLeader && selectedGroup?.leader && ` - ${getNameFromEmail(selectedGroup.leader)}`}
      </span>
    </Badge>
  );
};
