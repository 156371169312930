import React, { useRef, useState } from 'react';

import { MdOutlineMoreVert } from 'react-icons/md';

import useOutside from '@/hooks/useOutside';

import styles from './styles.module.scss';

interface Props {
  options: { label: string; onClick: React.MouseEventHandler<HTMLButtonElement> }[];
  onChangeOpen?: (
    open: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | { target: HTMLElement | null },
  ) => void;
}

const Menu: React.FC<Props> = ({ options, onChangeOpen }) => {
  const ref = useRef<HTMLDivElement>(null);
  useOutside(ref, () => {
    handleChangeOpen(false, { target: ref.current as HTMLElement });
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeOpen = (
    state: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | { target: HTMLElement | null },
  ) => {
    setIsOpen(state);
    onChangeOpen?.(state, e);
  };

  return (
    <div className={`${styles.container}`} ref={ref}>
      <button
        className={`${styles.button}`}
        name="menu"
        onClick={(e) => handleChangeOpen(!isOpen, e)}
      >
        <MdOutlineMoreVert />
      </button>
      {isOpen && (
        <ul className={`${styles.menu}`}>
          {options.map((option, index) => (
            <li key={index}>
              <button
                onClick={(e) => {
                  try {
                    option.onClick(e);
                  } finally {
                    handleChangeOpen(false, e);
                  }
                }}
              >
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Menu;
