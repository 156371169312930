import React, { useContext, useEffect, useState } from 'react';

import { format } from 'date-fns';

import { ColumnsIcon } from '@/assets/icons/icons';
import { FilterSelect } from '@/components/select-multiple';
import Table from '@/components/table';
import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import { UserService, GetPragmaticsParams, Pragmatico } from '@/services/users';

import styles from './styles.module.scss';

type ColumnsToShow = {
  name: keyof Pragmatico;
  label: string;
  filterable?: boolean;
  immobilizable?: boolean;
};

export const pragmaticosColumns: ColumnsToShow[] = [
  { name: 'business', label: 'Negocio' },
  { name: 'id', label: 'CC' },
  { name: 'email', label: 'Correo' },
  { name: 'fullname', label: 'Nombre' },
  { name: 'PragmaEntryDate', label: 'Fecha ingreso Pragma' },
  { name: 'city', label: 'Ciudad' },
  { name: 'contract', label: 'Contrato' },
  { name: 'level', label: 'Nivel' },
  { name: 'capacity', label: 'Capacidad' },
  { name: 'status', label: 'Estado' },
  { name: 'availability', label: '%' },
  { name: 'availabilityStartDate', label: 'Fecha inicio disponibilidad' },
  { name: 'technologies', label: 'Tecnologías' },
  { name: 'englishSkill', label: 'Nivel de Ingles' },
  { name: 'followUp', label: 'Seguimiento', filterable: false },
  { name: 'candidateToGoOut', label: 'Candidato a salir' },
  { name: 'send', label: 'enviar' },
  { name: 'emailInTP', label: 'El correo no se encuentra en Talent Pool' },
  { name: 'probableBrand', label: 'Marca Probable' },
  { name: 'group', label: 'Grupo/Proyecto' },
  { name: 'TPEntryDate', label: 'Fecha de Ingreso a Talent Pool' },
  { name: 'timeGettingReady', label: 'Tiempo preparándose (Semanas)' },
  { name: 'availabilityTime', label: 'Tiempo disponible (Semanas)' },
  { name: 'totalWeeks', label: 'Total Semanas' },
].map(
  (data) =>
    ({ ...data, filterable: data?.filterable != false, immobilizable: true } as ColumnsToShow),
);

export const DisponiblesApp: React.FC = () => {
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<any>();
  const {
    actions: { addToast },
  } = useContext(utilsContext);

  const [selectedColumns, setSelectedColumns] = useState(pragmaticosColumns);

  useEffect(() => {
    requestList();
  }, []);

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    const pragmaticParams: GetPragmaticsParams = {
      page: page + 1,
      limit: 10000,
      all: true,
    };
    cancellablePromise(
      UserService.getPragmaticos(pragmaticParams)
        .finally(() => {
          setListLoading(false);
        })
        .then((res) => {
          const data = res.users;
          setList(data || [], !!page, res.length, res.length - 1 == page, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };
  const formatDate = (date: string | Date) => {
    if (!date) return '';
    const d = new Date(date);
    return format(d, 'yyyy-MM-dd');
  };
  return (
    <div className="container">
      <header className={styles.header}>
        <div className={styles.title}>
          <h1>Disponibles</h1>
          {list?.list?.length > 0 && <span>{`${list.list.length} Total`}</span>}
        </div>
      </header>

      <div className={styles['disponibles-container']}>
        {/* <div className={styles["thead-background"]}></div> */}
        <main>
          <section className={`${styles['options']}`}>
            <FilterSelect
              label={
                <>
                  <ColumnsIcon height={17} width={17} /> Seleccionar Columnas
                </>
              }
              options={pragmaticosColumns}
              value={selectedColumns}
              onChange={(value: any) => {
                const columnsToShow =
                  value.length > 0 ? (value as ColumnsToShow[]) : pragmaticosColumns;
                setSelectedColumns(columnsToShow);
              }}
              getOptionLabel={(option) => option.label}
              isClearable
              isSearchable={false}
            />
          </section>

          <Table
            className={styles['table']}
            name="disponibles_table"
            header={selectedColumns}
            data={list.list.map((pragmatico) => ({
              _key: pragmatico._id,
              ...(pragmatico as any),
              TPEntryDate: formatDate(pragmatico.TPEntryDate),
              availabilityStartDate: formatDate(pragmatico.availabilityStartDate),
              PragmaEntryDate: formatDate(pragmatico.PragmaEntryDate),
            }))}
            onRowClick={(e) => {
              (e.currentTarget as HTMLElement).querySelector('textarea')?.focus();
            }}
            advanced={{
              ...list,
              handleSearch: (page) => requestList(page),
              isAnyVisible: false,
            }}
            emptyLabel={'No existen datos para mostrar'}
          />
        </main>
      </div>
    </div>
  );
};
