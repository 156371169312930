import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import { Datum } from '@/services/likelyBrand/type';

export class LikelyBrandService {
  static url = endpoints.feedback.users;

  static async getLikelyBrands(email: string, isRefresh = false): Promise<Datum[]> {
    let url = LikelyBrandService.url + '/' + email + '/brandProfiles';
    if (isRefresh) url += '?refresh=true';
    const response = await axiosClient({ url: url, method: 'GET' });
    return response.data;
  }
}
