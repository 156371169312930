import React, { useContext, useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { CancelIcon, ChevronDownIcon, SearchIcon } from '@/assets/icons/icons';
//components
import ASelect from '@/components/atoms/ASelect/ASelect';
import { Contact } from '@/components/contact';
import FormHeatMap from '@/components/organisms/FormHeatMap';
import Table from '@/components/table';
import TextField from '@/components/ui/textField';
import { utilsContext } from '@/contexts/utils';
import { replaceValue } from '@/helpers';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import { useControlledForm } from '@/hooks/useFeedbackForm';
import { useGroups } from '@/hooks/useGroups';
import useList from '@/hooks/useList';
import { FeedbackService } from '@/services/feedback';
import { FeedbackEvent } from '@/services/feedback/types';
import { UserService, GetPragmaticsParams, Pragmatico } from '@/services/users';

import styles from './styles.module.scss';

export const RoutesKnowledge = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { register, reset, getValues, form } = useControlledForm();
  const [confirmModal, setConfirmModal] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);

  //const [showEdit, setShowEdit] = useState<Pragmatico | null>(null);
  const [states, setStates] = useState([]);
  useEffect(() => {
    FeedbackService.getStates().then((data) => {
      setStates(data);
    });
  }, []);

  const groups = useGroups();
  const [selectedGroup, setSelectedGroup] = useState<string | null>();
  const [selectedTitleGroup, setSelectedTitleGroup] = useState<string>('progreso ruta');
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Pragmatico>();
  const [dropdowned, setDropdowned] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');
  const [events, setEvents] = useState<FeedbackEvent[]>([]);
  //const [selectedEvent, setSelectedEvent] = useState<number>(0);

  const [componentMounted, setComponentMounted] = useState(false);

  useEffect(() => {
    setList([]);
    setSelectedGroup(searchParams.get('group'));
    const querySearch = searchParams.get('search');
    setCurrentSearch(querySearch ? querySearch : '');
    setSearchValue(querySearch ? querySearch : '');
    FeedbackService.getEvents().then((data) => {
      setEvents(data);
    });
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (groups.length > 0 && events.length > 0) setLoading(false);
    else setLoading(true);
    const group = groups.find(
      (g) => g.title.toLocaleLowerCase() == searchParams.get('group')?.toLocaleLowerCase(),
    );
    setSelectedTitleGroup(group?.isProject ? 'Progreso Proyecto' : 'Progreso Ruta');
  }, [groups, events]);
  useEffect(() => {
    if (componentMounted) {
      setDisabledSave(true);
      reset();
      changeQueryParams();
      requestList();
    }
  }, [selectedGroup, currentSearch, componentMounted]);

  useEffect(() => {
    disabledButton();
  }, [form]);

  const today = new Date();

  const changeQueryParams = () => {
    if (selectedGroup) searchParams.set('group', selectedGroup);
    else searchParams.delete('group');

    if (currentSearch) searchParams.set('search', currentSearch);
    else searchParams.delete('search');
    navigate('?' + searchParams.toString(), { replace: true });
  };

  const getEntryDateString = (user: Pragmatico) => {
    let fechaIngreso = 0;
    if (user.TPEntryDate) {
      fechaIngreso = Math.floor(
        (today.getTime() - new Date(user.TPEntryDate).getTime()) / (1000 * 60 * 60 * 24),
      );
    }
    let entryDate = '-';
    if (fechaIngreso !== 0) entryDate = fechaIngreso == 1 ? '1' : `${fechaIngreso}`;
    return entryDate;
  };

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    const pragmaticParams: GetPragmaticsParams = {
      page: page + 1,
      limit: 10,
    };
    if (selectedGroup) pragmaticParams.group = selectedGroup;
    if (currentSearch) pragmaticParams.user = currentSearch;
    cancellablePromise(
      UserService.getPragmaticos(pragmaticParams)
        .finally(() => {
          setListLoading(false);
        })
        .then((res) => {
          const data = res.users.map((el) => {
            return {
              ...el,
              TPEntryDate: getEntryDateString(el),
            };
          });
          setList(data || [], !!page, res.length, res.length - 1 == page, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  const toggleDropDowned = (key: React.Key) => {
    const index = dropdowned.indexOf(key);
    if (index < 0) setDropdowned((v) => [...v, key]);
    else {
      const newData = [...dropdowned];
      newData.splice(index, 1);
      setDropdowned(newData);
    }
  };

  const disabledButton = (): boolean => {
    const values = form;
    for (const key in values) {
      if (values[key]) {
        if (disabledSave) setDisabledSave(false);
        return false;
      }
    }
    if (!disabledSave) setDisabledSave(true);
    return true;
  };

  // const handleCloseModalSave = () => {
  //   // reseteando comentarios
  //   reset();
  //   setDropdowned([]);
  //   setConfirmModal(false);
  //   document.querySelectorAll("input[type='radio']").forEach((el) => {
  //     (el as HTMLInputElement).checked = false;
  //   });
  // };

  const handleSearch = (value: string) => {
    setCurrentSearch(
      replaceValue({
        value,
        searchValue: /\s+/gi,
        replaceValue: ' ',
      }),
    );
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setCurrentSearch('');
  };

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    setSearchValue(text);
    if (text.length === 0) setCurrentSearch('');
  };

  const getTrailingSearchIcon = (): React.ReactNode => {
    return searchValue.length > 0 ? (
      <CancelIcon width={16} height={16} onclick={handleClearSearch} />
    ) : (
      <SearchIcon width={16} height={16} onclick={() => handleSearch(searchValue)} />
    );
  };

  return (
    <>
      <div className={styles.options}>
        <section className={styles['options-inputs']}>
          <section className={styles['options-search']}>
            <div className={styles['options-option']}>
              <h2>Pragmático</h2>
              <TextField
                placeholder="Buscar pragmático"
                value={searchValue}
                onEnter={() => handleSearch(searchValue)}
                onChange={handleSearchValue}
                trailing={getTrailingSearchIcon()}
              />
            </div>
            <div className={styles['options-option']}>
              <h2>Casa o Proyecto</h2>
              <ASelect
                placeholder="Seleccionar casa o proyecto"
                options={groups ? groups.filter((group) => group.active) : []}
                name="grupos"
                getOptionLabel={(option) => option.title}
                value={groups.find(
                  (g) => g.title.toLocaleLowerCase() == selectedGroup?.toLocaleLowerCase(),
                )}
                onChange={(value, actionMeta) => {
                  if (actionMeta.action === 'clear' && selectedGroup) setSelectedGroup(undefined);
                  else if (value) setSelectedGroup(value.title);
                  setSelectedTitleGroup(value?.isProject ? 'Progreso Proyecto' : 'Progreso Ruta');
                }}
                isClearable
              />
            </div>
          </section>
        </section>
      </div>

      <div className={styles['users-table']}>
        <Table
          header={[
            { name: 'pragmatico', label: 'Nombre' },
            { name: 'capacity', label: 'Capacidad' },
          ]}
          data={list.list.map((pragmatico) => ({
            _key: pragmatico.email,
            ...(pragmatico as any),
            pragmatico: (
              <div className={styles['contact-field']}>
                <button
                  className={styles.chevron}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropDowned(pragmatico.email);
                  }}
                >
                  <ChevronDownIcon width={17} height={17} />
                </button>
                <Contact
                  user={pragmatico}
                  className={styles.contact}
                  show={['status']}
                  size="sm"
                  showDetails={false}
                />
              </div>
            ),
          }))}
          advanced={{
            ...list,
            handleSearch: (page) => requestList(page),
            dropdownContent: (item: Pragmatico) => {
              return (
                <FormHeatMap
                  email={item.email}
                  type="knowledge"
                  handleShowForm={(v: any) => {
                    console.log({ v });
                    setDropdowned([]);
                  }}
                />
              );
            },
            dropdowned: dropdowned,
            isAnyVisible: false,
          }}
          emptyLabel={'No existen datos para mostrar'}
        />
      </div>
      <div>
        <br></br>
      </div>
    </>
  );
};

export default RoutesKnowledge;
