import React, { useEffect, useState } from 'react';

import { ProgressRoutes } from '@/app/rutas/progressRoutes';
import { RoutesKnowledge } from '@/app/rutas/routesKnowledge';
import TabComponent, { TSubMenu } from '@/components/ui/tab';

import styles from './styles.module.scss';
import usePermission from '@/routes/protect/usePermission';

const getTabs = (): TSubMenu[] => {
  const tabs: TSubMenu[] = [
    {
      title: 'Conocimiento',
      element: <RoutesKnowledge />,
      name: 'conocimiento',
    },
    {
      title: 'Avances',
      element: <ProgressRoutes />,
      name: 'ruta-pragmatico',
    },
  ];
  return tabs;
};

export const RutasApp = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tabs, setTabs] = useState<any>([]);
  const {routes} = usePermission()
  const permissions = routes?.map((route)=> route.nombre)
  useEffect(()=>{
    setTabs(getTabs().filter((route)=>permissions?.includes(route.name)))
  },[])
  
  return (
    <>
      <div className={styles.options}>
        <TabComponent
          isMain={true}
          selectedTab={selectedTab}
          subMenus={tabs.map((tab:any) => ({ ...tab, element: <> {tab.element} </> }))}
          onChange={(e) => {
            setSelectedTab(e);
          }}
        />
      </div>
    </>
  );
};

export default RutasApp;
