import React from 'react';

import { Link } from 'react-router-dom';

import { useGoToUserDetails } from '@/app/feedbackComments';
import { PencilIcon, ShuffleIcon } from '@/assets/icons/icons';
import { ContactInfo } from '@/components/contact/contact-info';
import Button from '@/components/ui/button';
import { Pragmatico } from '@/services/users';

import styles from './styles.module.scss';

type ContactContentPopoOverProps = {
  user: Pragmatico;
  onChangeUser: (user: Pragmatico) => void;
};

export const ContactContentPopover: React.FC<ContactContentPopoOverProps> = ({
  user,
  onChangeUser
}) => {
  const [url] = useGoToUserDetails(user.email);
  const handleUpdateUser = () => {
    onChangeUser?.(user);
  };

  return (
    <div className={`${styles.tooltip}`}>
      <ContactInfo user={user} showDetails={false} show={['email', 'group']} />

      <div className={styles['button-groups']}>
        <Link to={url}>
          <Button name="see-detail_btn">Ver detalles</Button>
        </Link>

        <span data-title="Editar estado - grupo">
          <Button name="edit-status_btn" onClick={handleUpdateUser}>
            <ShuffleIcon width={17} height={17} />
          </Button>
        </span>
      </div>
    </div>
  );
};
