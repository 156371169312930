import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

//components

import { CheckToastTicon, CancelTreasuryIcon, ChevronDownIcon } from '@/assets/icons/icons';
import Button from '@/components/ui/button';
import SpinnerLoader from '@/components/ui/spinnerLoader';
import ModalSuccess from '@/components/userStories/modalSuccess';
// ======
// services
import { HeatMapService } from '@/services/heatMap';

// ======
import styles from './styles.module.scss';

interface UserFeedbackProps {
  email?: string;
  handleShowForm?: any;
  type?: string;
}

interface MakeData {
  questionId: number;
  choiceId?: number;
  answerText?: string;
}

const FormHeatMap = ({ email, handleShowForm, type = 'heatmap' }: UserFeedbackProps) => {
  const [questionsForm, setQuestionsForm] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [modalError, setModalError] = useState<boolean>(false);
  const [disableBtn, setDisabledBtn] = useState<boolean>(false);

  const [formId, setFormId] = useState<number>(0);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    try {
      const res = await HeatMapService.getQuestions({ email, type });
      setIsLoading(false);
      setFormId(res.formId); // se guarda el id del formulario para enviarlo en el post.
      setQuestionsForm(res.sections);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const makeData = (data: MakeData) => {
    const datos = [];
    for (const [key, value] of Object.entries(data)) {
      if (value !== null) {
        if (Number(value)) {
          datos.push({
            questionId: Number(key),
            choiceId: Number(value),
          });
        } else {
          value !== '' &&
            datos.push({
              questionId: Number(key),
              answerText: value,
            });
        }
      }
    }

    return datos;
  };

  const handleCloseModal = () => {
    setShowModalSuccess(false);
    handleShowForm();
  };

  const onSubmit = (data: any) => {
    setDisabledBtn(true);
    HeatMapService.saveQuestionsHeatMap({
      formId: formId,
      createdTo: email,
      responses: makeData(data),
      type,
    })
      .then(() => {
        // console.log('RESPUESTA DEL SERVICIO', res);
        setShowModalSuccess(true);
        setModalError(false);
        setDisabledBtn(false);
      })
      .catch(() => {
        // console.log('ha ocurrido un error');
        setShowModalSuccess(true);
        setModalError(true);
        setDisabledBtn(false);
      });
  };

  return (
    <form className={styles['form-heat-map']} onSubmit={handleSubmit(onSubmit)}>
      {isLoading ? (
        <>
          <SpinnerLoader />
        </>
      ) : (
        <>
          {questionsForm.length > 0 ? (
            questionsForm.map((section: any, index: number) => {
              return (
                <>
                  <h2 key={index}>{section.name}</h2>
                  {section.questions.map((questions: any, index: number) => {
                    return (
                      <div className={styles['container-questions-heat-map']} key={index}>
                        {questions.type === 'TEXT' ? (
                          <textarea
                            {...register(`${questions.id}`, {
                              required: questions.isMandatory,
                            })}
                            name={`${questions.id}`}
                            placeholder="Este campo es opcional"
                            className={styles['text-area-heat-map']}
                            id="text-area"
                          />
                        ) : (
                          <>
                            <p>{questions.description}</p>
                            {questions.choices.map((choices: any, index: number) => {
                              return (
                                <>
                                  <label
                                    key={index}
                                    className={
                                      styles['container-questions-heat-map__itemQuestions']
                                    }
                                  >
                                    <span
                                      className={
                                        styles['container-questions-heat-map__itemQuestions__input']
                                      }
                                    >
                                      <input
                                        {...register(`${questions.id}`, {
                                          required: questions.isMandatory,
                                        })}
                                        type="radio"
                                        value={choices.id}
                                        id="radio-pra"
                                        name={`${questions.id}`}
                                      />
                                    </span>
                                    <span>{choices.description}</span>
                                  </label>
                                </>
                              );
                            })}
                          </>
                        )}
                        {Object.keys(errors).some((item) => item === `${questions.id}`) && (
                          <p className={styles['message-error']}>Campo obligatorio *</p>
                        )}
                      </div>
                    );
                  })}
                </>
              );
            })
          ) : (
            <h1>No hay formularios para mostrar</h1>
          )}
          {questionsForm.length > 0 && (
            <div className={styles['button-row']}>
              <Button
                name="up"
                disabled={disableBtn}
                className={styles['reverse']}
                onClick={() => handleShowForm()}
              >
                <ChevronDownIcon width={17} height={17} />
              </Button>
              <Button type="submit" name="completar-heatMap" disabled={disableBtn}>
                Finalizar
              </Button>
            </div>
          )}

          {/* Modal de confirmacion del formulario */}
          <ModalSuccess
            onClose={() => {
              modalError ? setShowModalSuccess(false) : handleShowForm();
            }}
            show={showModalSuccess}
          >
            <>
              <div>
                {modalError ? (
                  <CancelTreasuryIcon
                    className={styles['error-toast-icon']}
                    width={50}
                    height={50}
                  />
                ) : (
                  <CheckToastTicon className={styles['check-toast-icon']} width={50} height={50} />
                )}
              </div>
              <h4 className={styles['success-title']}>
                {modalError ? 'Ha ocurrido un error' : '¡Formulario Guardado!'}
              </h4>
              <div className={styles['success-footer']}>
                <Button
                  onClick={() => {
                    modalError ? setShowModalSuccess(false) : handleCloseModal();
                  }}
                  name="aceptarSuccess"
                >
                  Aceptar
                </Button>
              </div>
            </>
          </ModalSuccess>
        </>
      )}
    </form>
  );
};

export default FormHeatMap;
