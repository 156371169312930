import React, { useRef, useState, memo } from 'react';

import { CancelIcon, ChevronDownIcon, SearchIcon } from '@/assets/icons/icons';
import Checkbox from '@/components/ui/checkbox';
import TextField from '@/components/ui/textField';
import useOutside from '@/hooks/useOutside';

import styles from './styles.module.scss';

interface TProps<T = any> {
  options: T[];
  value?: T[] | any[];
  isClearable?: boolean;
  isSearchable?: boolean;
  getOptionLabel?: (option: T) => string;

  onChange?: (value: any) => void;
  onChangeOpen?: (
    open: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | { target: HTMLElement | null },
  ) => void;
}

type FilterSelectProps = TProps & {
  menuClassName?: string;
  buttonClassName?: string;
  label?: string | React.ReactNode;
  buttonContent?: string | React.ReactNode;
  onMouseEventCheckbox?:() => void
};
export const FilterSelect: React.FC<FilterSelectProps> = (props) => {
  const {
    onChange,
    label = 'filtrar',
    buttonContent,
    buttonClassName,
    menuClassName,
    onChangeOpen,
    onMouseEventCheckbox
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [innerValue, setInnerValue] = useState<any[]>([]);

  const handleChange = (option: any) => {
    const newValue = [...innerValue];
    const index = innerValue.indexOf(option);
    if (index > -1) newValue.splice(index, 1);
    else newValue.push(option);
    setInnerValue(newValue);
    onChange?.(newValue);
  };
  const clean = () => {
    setInnerValue([]);
    onChange?.([]);
  };

  const handleChangeOpen = (
    state: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | { target: HTMLElement | null },
  ) => {
    setIsOpen(state);
    onChangeOpen?.(state, e);
  };
  return (
    <>
      <button
        className={`${styles.button} ${isOpen ? styles['open'] : ''} ${buttonClassName}`}
        onClick={(e) => {
          handleChangeOpen(!isOpen, e);
        }}
      >
        {buttonContent || (
          <>
            {label}
            <ChevronDownIcon height={17} width={17} className={styles.chevron} />
          </>
        )}
      </button>
      {isOpen && (
        <Menu
          {...{
            ...props,
            value: innerValue,
            clean,
            setIsOpen,
            handleChange,
            handleChangeOpen,
            className: menuClassName,
            onMouseEventCheckbox
          }}
        />
      )}
    </>
  );
};

const Menu: React.FC<
  TProps & {
    value: any[];
    clean: () => void;
    setIsOpen: any;
    handleChange: (option: any) => void;
    handleChangeOpen: (
      open: boolean,
      e: React.MouseEvent<HTMLButtonElement, MouseEvent> | { target: HTMLElement | null },
    ) => void;
    className?: string;
    onMouseEventCheckbox?:() => void
  }
> = (props) => {
  const {
    getOptionLabel = (option) => option as string,
    options,
    isClearable,
    clean,
    setIsOpen,
    handleChange,
    handleChangeOpen,
    value,
    isSearchable = true,
    className,
    onMouseEventCheckbox
  } = props;
  const ref = useRef(null);
  useOutside(ref, () => {
    handleChangeOpen(false, { target: ref.current });
  });

  const [visibleOptions, setVisibleOptions] = useState(options);
  const [searchValue, setSearchValue] = useState<string>('');
  const handleSearch = (searchValue: string) => {
    const search = searchValue.toLowerCase();
    let newVisibleOptions = options;
    if (searchValue)
      newVisibleOptions = options.filter((option) =>
        getOptionLabel(option).toLowerCase().includes(search),
      );
    setVisibleOptions(newVisibleOptions);
  };
  return (
    <div className={`${styles.menu} ${className}`} ref={ref}>
      <div className={`${styles.tools}`}>
        <div className={styles.close}>
          <button
            onClick={(e) => {
              handleChangeOpen(false, e);
            }}
          >
            <CancelIcon height={10} width={10} />
          </button>
        </div>
        {isSearchable && (
          <TextField
            placeholder="Buscar"
            className={`${styles['search-input']}`}
            value={searchValue}
            onEnter={() => handleSearch(searchValue)}
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleSearch(e.target.value);
            }}
            trailing={
              <SearchIcon width={16} height={16} onclick={() => handleSearch(searchValue)} />
            }
          />
        )}
        {isClearable && (
          <>
            <button
              className={styles['clean-button']}
              onClick={() => {
                clean();
              }}
            >
              Limpiar vista
            </button>
            <button
              className={styles['acept-button']}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Aceptar
            </button>
          </>
        )}
      </div>
      <div className={`${styles.options}`}>
        {visibleOptions.map((option) => {
          const label = getOptionLabel(option);
          return (
            <Checkbox
              label={label}
              key={label}
              positionLabel="end"
              className={styles.option}
              onChange={() => {
                handleChange(option);
              }}
              onMouseMove={() => {onMouseEventCheckbox?.()}}
              checked={value.includes(option)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(FilterSelect);
