import React, { useState, useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroll-component';

import Menu from '@/components/menu';
import { FilterSelect } from '@/components/select-multiple';

import EmptyState from '../emptyState';
import Shimmer from '../ui/shimmer';

import styles from './styles.module.scss';

type TProps = {
  name?: string;
  header: { name: string; label: React.ReactNode; filterable?: boolean; immobilizable?: boolean }[];
  data: ({ _key: React.Key } & Record<string, any>)[];
  emptyLabel?: string;
  classEmpty?: boolean;
  className?: string;
  rowClassName?: Record<string, string | undefined>;
  rowStyle?: Record<string, React.CSSProperties | undefined>;
  headerClassName?: Record<string, string | undefined>;
  headerStyle?: Record<string, React.CSSProperties | undefined>;
  onRowClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item?: any) => void;
  onChangeFilter?: (value:any) => void;
  advanced?: {
    count: number;
    page: number;
    lastPage?: boolean;
    isNewSearch?: boolean;
    isLoading?: boolean;
    isAnyVisible?: boolean;
    handleSearch: (page: number) => void;
    dropdownContent?: (
      item: { _key: React.Key } & Record<string, React.ReactNode> & any,
    ) => React.ReactNode;
    dropdowned?: React.Key[];
  };
};

const Table = ({
  name,
  header,
  data,
  emptyLabel,
  classEmpty,
  className,
  rowClassName,
  rowStyle,
  headerClassName,
  headerStyle,
  onRowClick,
  advanced,
  onChangeFilter
}: TProps) => {
  const [items, setItems] = useState(data);
  const [currentFilters, setCurrentFilters] = useState<{ [key: string]: string[] }>({});

  const [blockedColumns, setBlockedColumns] = useState(
    new Map<string, { width: number; position?: number; target?: HTMLElement }>(),
  );

  /**Manejo de filtros */

  useEffect(() => {
    changeVisibleColumns();
  }, [currentFilters, data]);
  const changeVisibleColumns = () => {
    const filters = Object.entries(currentFilters);
    const newData = data.filter((item) => {
      if (filters.length <= 0) return true;
      return filters.every(
        ([key, value]) =>
          value.length == 0 ||
          value
            .map((v) => v.toLowerCase())
            .includes(item[key]?.toString().toLowerCase() || item[key]),
      );
    });
    setItems(newData);
  };

  useEffect(() => {
    const filters = Object.entries(currentFilters);
    filters.forEach(([key]) => {
      if (!header.find((head) => head.name == key))
        setCurrentFilters((filters) => ({ ...filters, [key]: [] }));
    });
    blockedColumns.forEach((value, key) => {
      if (!header.find((head) => head.name == key)) blockedColumns.delete(key);
    });
    changeBlockedColumns();
  }, [header]);

  /**Manejo de columnas inmovibles */
  const changeBlockedColumns = () => {
    let position = 0;
    let index = 0;
    blockedColumns.forEach((value, key) => {
      value.position = position;
      if (value.target) value.width = value.target?.clientWidth || 0;
      const headerIndex = header.findIndex((head) => head.name == key);
      if (headerIndex) {
        const head = header.splice(headerIndex, 1);
        header.splice(index, 0, head[0]);
      }

      position += value.width;
      index++;
    });
    setBlockedColumns((prev) => new Map(prev));
  };

  const onChangeOpenOptions = (isOpen: boolean, e: any) => {
    const parentTh = (e.target as HTMLElement).closest('th');
    if (isOpen) parentTh?.classList.add(styles['active-th']);
    else parentTh?.classList.remove(styles['active-th']);
  };

  const getTbodyAdvanced = () => {
    if (advanced?.isNewSearch && advanced?.isLoading) return <Shimmer isRow repetitions={3} />;
    if (!advanced?.count && !advanced?.isAnyVisible && emptyLabel)
      return <EmptyState isRow msg={emptyLabel} look={classEmpty} />;

    return (
      <>
        {advanced &&
          items.map((d) => (
            <AnimatePresence key={d._key}>
              {d._visible && (
                <>
                  <motion.tr
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={(e) => {
                      onRowClick?.(e, d);
                    }}
                    data-dropdowned={advanced.dropdowned?.includes(d._key) ? true : undefined}
                  >
                    {header.map((th) => (
                      <td
                        key={th.name}
                        style={{
                          ...((blockedColumns.has(th.name) && {
                            left: blockedColumns.get(th.name)?.position,
                          }) ||
                            {}),
                          ...((rowStyle && rowStyle[th.name]) || {}),
                        }}
                        className={`${(blockedColumns.has(th.name) && styles.sticky) || ''} ${
                          rowClassName && rowClassName[th.name]
                        }`}
                      >
                        {d[th.name]}
                      </td>
                    ))}
                  </motion.tr>

                  {advanced.dropdownContent && advanced.dropdowned?.includes(d._key) && (
                    <tr>
                      <td colSpan={header.length}>
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ height: { duration: 0.5 } }}
                          style={{ display: 'grid', overflow: 'hidden' }}
                        >
                          {advanced.dropdownContent(d)}
                        </motion.div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </AnimatePresence>
          ))}
        {advanced?.isLoading && <Shimmer isRow />}
      </>
    );
  };

  if (advanced) {
    return (
      <div id={name} className={`${styles.table} ${className ? className : ''}`}>
        <InfiniteScroll
          scrollableTarget={name || 'template'}
          dataLength={advanced.count}
          next={() => !advanced.isLoading && advanced.handleSearch(advanced.page + 1)}
          hasMore={!advanced.lastPage}
          loader={null}
          scrollThreshold={0.9}
          style={{ overflow: 'visible', paddingRight: '1rem' }}
        >
          <table>
            <thead style={{ opacity: 1 }}>
              <tr>
                {header.map((th) => (
                  <th
                    key={th.name}
                    style={{
                      ...((blockedColumns.has(th.name) && {
                        left: blockedColumns.get(th.name)?.position,
                      }) ||
                        {}),
                      ...((headerStyle && headerStyle[th.name]) || {}),
                    }}
                    className={`${(blockedColumns.has(th.name) && styles.sticky) || ''} ${
                      (headerClassName && headerClassName[th.name]) || ''
                    }`}
                  >
                    <div className={`${styles['column-name']}`}>
                      {(th.filterable && (
                        <div className={`${styles.label}`}>
                          <FilterSelect
                            label={
                              <>
                                <span>{th.label}</span>
                                {currentFilters[th.name]?.length > 0 && (
                                  <span className={styles['filter-notification']}>
                                    {currentFilters[th.name]?.length}
                                  </span>
                                )}
                              </>
                            }
                            onChangeOpen={onChangeOpenOptions}
                            buttonClassName={styles['filter-button']}
                            menuClassName={styles['filter-menu']}
                            isClearable
                            options={findUniqueValues(data.map((d) => d[th.name]?.toString()))}
                            onChange={(values) => {
                              setCurrentFilters((filters) => ({ ...filters, [th.name]: values }));
                            }}
                            onMouseEventCheckbox={ () => {onChangeFilter?.(true)}}
                          />
                        </div>
                      )) ||
                        th.label}

                      <div className={`${styles.options}`}>
                        {th.immobilizable && (
                          <Menu
                            options={[
                              {
                                label: blockedColumns.has(th.name)
                                  ? 'Desinmovilizar Columna'
                                  : 'Inmovilizar Columna',
                                onClick: (e) => {
                                  if (blockedColumns.has(th.name)) {
                                    blockedColumns.delete(th.name);
                                  } else {
                                    const thParent = (e.target as HTMLElement).closest('th');
                                    blockedColumns.set(th.name, {
                                      width: thParent?.clientWidth || 0,
                                      target: thParent || undefined,
                                    });
                                  }
                                  changeBlockedColumns();
                                },
                              },
                            ]}
                            onChangeOpen={onChangeOpenOptions}
                          />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{getTbodyAdvanced()}</tbody>
          </table>
        </InfiniteScroll>
      </div>
    );
  }

  return (
    <div id={name} className={styles.table}>
      <table>
        <thead>
          <tr>
            {header.map((th) => (
              <th
                key={th.name}
                style={headerStyle && headerStyle[th.name]}
                className={headerClassName && headerClassName[th.name]}
              >
                {th.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length
            ? data.map((d) => (
                <tr
                  key={d._key}
                  onClick={(e) => {
                    onRowClick?.(e, d);
                  }}
                >
                  {header.map((th) => (
                    <td
                      key={th.name}
                      style={rowStyle && rowStyle[th.name]}
                      className={rowClassName && rowClassName[th.name]}
                    >
                      {d[th.name]}
                    </td>
                  ))}
                </tr>
              ))
            : emptyLabel && <EmptyState isRow msg={emptyLabel} look={classEmpty} />}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

const findUniqueValues = (values: string[]) => {
  const result = new Map<string, string>();
  values.forEach((value) => {
    const lowerValue = value?.toLowerCase();
    !result.has(lowerValue) && result.set(lowerValue, value);
  });
  return Array.from(result.values()).sort((a, b) => a.localeCompare(b));
};
