import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';

import {
  Feedback,
  GetCommentsProps,
  CommentListResponse,
  FeedbackEvent,
  GetParameterRoutesParams,
  ParameterResponse,
  FieldsToUpdate,
  Brand,
} from './types';

export class FeedbackService {
  static url = endpoints.feedback.base;

  static async getEvents(): Promise<FeedbackEvent[]> {
    const response = await axiosClient({ url: endpoints.feedback.events, method: 'GET' });
    return response.data;
  }

  static async saveComments(comments: Feedback[]): Promise<{ [key: string]: string }> {
    return axiosClient({ url: endpoints.feedback.comments, method: 'POST', data: comments });
  }

  static async getComments({
    email,
    limit = 20,
    commentDatePagination,
    onlyComments = false,
  }: GetCommentsProps): Promise<CommentListResponse> {
    const datePagination = commentDatePagination
      ? '&commentDatePagination=' + commentDatePagination
      : '';
    const response = await axiosClient({
      url:
        endpoints.feedback.comments +
        `?userEmail=${email}&limit=${limit}${datePagination}${
          (onlyComments && '&onlyComments=true') || ''
        }`,
      method: 'GET',
    });
    return response.data;
  }

  static async getStates() {
    const response = await axiosClient({
      url: endpoints.feedback.states,
      method: 'GET',
    });
    return response.data;
  }

  static async geTimeRouteDays(params: GetParameterRoutesParams): Promise<ParameterResponse> {
    const response = await axiosClient({
      url: endpoints.feedback.timeRouteDays,
      method: 'GET',
      params,
    });
    return response.data;
  }

  static async getBrands(): Promise<{data: Brand[]}> {
    const response = await axiosClient({
      url: endpoints.feedback.getBrands,
      method: 'GET',
    });
    return response;
  }

  static async updateSomeFields(data:FieldsToUpdate, email:string): Promise<{statusCode: number, message: string}> {
    const response = await axiosClient({
      url: endpoints.feedback.users + `/${email}`,
      method: 'PATCH',
      data
    });
    return response;
  }
}
