import React from 'react';

import { ThumbsUp } from '@/assets/icons/icons';

import styles from './styles.module.scss';

type TProps = {
  value: string | undefined;
  onChange?: (e: any) => void;
};

export const Reaction: React.FC<TProps> = ({ value, onChange }) => {
  const handleChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, newValue: string) => {
    let nextValue = '';
    if (value != newValue) nextValue = newValue;
    onChange?.({ target: { value: nextValue } });
  };
  return (
    <div className={styles['puntualidad']}>
      {/* <select value={value} {...rest} style={{ display: 'none' }}>
        <option value="true" />
        <option value="false" />
      </select> */}
      <button
        className={`${styles.option} ${styles.up} ${value == 'true' ? styles.checked : ''}`}
        onClick={(e) => {
          handleChange(e, 'true');
        }}
      >
        <ThumbsUp height={30} width={30} />
      </button>
      <button
        className={`${styles.option} ${styles.down} ${value == 'false' ? styles.checked : ''}`}
        onClick={(e) => {
          handleChange(e, 'false');
        }}
      >
        <ThumbsUp height={30} width={30} />
      </button>
    </div>
  );
};
