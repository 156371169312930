import { useEffect, useState } from 'react';

import { Group, groupsService } from '@/services/group';

export const useGroups = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  useEffect(() => {
    groupsService.get().then((data) => setGroups(data));
  }, []);
  return groups;
};
export default useGroups;
