import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import { Data, saveHeatProps, resumeHeatMap, detailQuestions, QuesionProps } from '@/services/heatMap/type';

export class HeatMapService {
  static async getQuestions({type="heatmap", email}:QuesionProps={}): Promise<Data> {
    const response = await axiosClient({
      url: endpoints.feedback.form,
      method: 'GET',
      params:{
        type,
        email,
      }
    });
    return response.data;
  }

  static async saveQuestionsHeatMap(props: saveHeatProps): Promise<saveHeatProps> {
    const object = {...props}
    delete object.type
    const response = await axiosClient({
      url: endpoints.feedback.form,
      method: 'POST',
      data: object,
      params:{
        type:props.type || "heatmap"
      }
    });
    return response;
  }

  static async getResumeHeatMap(
    email: string,
    page: number,
    limit: number,
  ): Promise<resumeHeatMap[]> {
    const response = await axiosClient({
      url: endpoints.feedback.users + `/${email}/heatMap?limit=${limit}&page=${page}`,
      method: 'GET',
    });
    return response.data;
  }

  static async getDetailQuestions(heatMapId: number): Promise<detailQuestions[]> {
    const response = await axiosClient({
      url: endpoints.feedback.form + `/${heatMapId}`,
      method: 'GET',
    });
    return response.data;
  }
}
export default HeatMapService;
