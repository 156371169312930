import React from 'react';

type TProps = {
  width: number;
  height: number;
  className?: string;
  fill?: string;
  fillICon?: string;
  onclick?: () => void;
};

export const LogoHeaderIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="80 0 62 62"
    >
      <path
        fill="#FFF"
        d="M117.607 37.658c6.498-2.237 11.185-8.416 11.185-15.607a16.32 16.32 0 00-6.818-13.316c.32.054.693.107 1.012.107 2.451 0 4.475-1.97 4.475-4.421 0-2.45-2.024-4.421-4.475-4.421-2.45 0-4.474 1.97-4.474 4.421 0 1.065.373 2.077 1.066 2.876a16.77 16.77 0 00-7.511-1.757c-9.215 0-16.725 7.403-16.725 16.565 0 9.002 7.297 16.352 16.299 16.565-4.368 1.864-7.457 6.179-7.457 11.186 0 6.711 5.486 12.144 12.251 12.144s12.251-5.433 12.251-12.144c.106-6.445-4.794-11.665-11.079-12.198zm-17.045-15.66c0-6.338 5.167-11.452 11.559-11.452 6.391 0 11.558 5.167 11.558 11.452s-5.167 11.505-11.558 11.505c-6.392 0-11.559-5.166-11.559-11.505zm15.979 34.835c-3.941 0-7.137-3.195-7.137-7.084 0-3.888 3.196-7.084 7.137-7.084 3.942 0 7.138 3.196 7.138 7.084 0 3.942-3.196 7.084-7.138 7.084z"
      ></path>
    </svg>
  );
};

export const ChevronDownIcon = ({ width, height, className, fill }: TProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={`${className ?? ''}`}
    fill="none"
    viewBox="0 0 21 12"
  >
    <path
      fill={fill ?? '#fff'}
      fillRule="evenodd"
      d="M.634.901a.8.8 0 011.132 0l8.767 8.768L19.301.9a.8.8 0 011.131 1.131L11.1 11.366a.8.8 0 01-1.131 0L.634 2.032a.8.8 0 010-1.13z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const HomeGroup = ({ width, height, className, fill }: TProps) => (
  <svg
    width={width}
    height={height}
    className={`${className ?? ''}`}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.56592 0.52487C6.40084 0.396475 6.16968 0.396475 6.0046 0.52487L0.2446 5.00487C0.133246 5.09148 0.0681152 5.22465 0.0681152 5.36572V12.4057C0.0681152 12.8664 0.251135 13.3083 0.576913 13.6341C0.90269 13.9598 1.34454 14.1429 1.80526 14.1429H10.7653C11.226 14.1429 11.6678 13.9598 11.9936 13.6341C12.3194 13.3083 12.5024 12.8664 12.5024 12.4057V5.36572C12.5024 5.22465 12.4373 5.09148 12.3259 5.00487L6.56592 0.52487ZM8.6624 13.2286H10.7653C10.9835 13.2286 11.1928 13.1419 11.3471 12.9876C11.5014 12.8332 11.5881 12.624 11.5881 12.4057V5.5893L6.28526 1.46485L0.982401 5.5893V12.4057C0.982401 12.624 1.06909 12.8332 1.22341 12.9876C1.37773 13.1419 1.58702 13.2286 1.80526 13.2286H3.90811V7.28572C3.90811 7.03324 4.11278 6.82857 4.36525 6.82857H8.20526C8.45773 6.82857 8.6624 7.03324 8.6624 7.28572V13.2286ZM4.8224 13.2286V7.74286H7.74811V13.2286H4.8224Z"
      fill={fill ?? '#fff'}
    />
  </svg>
);

export const CancelIcon = ({ width, height, className, onclick }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-testid="svg-cancel-icon"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 17 17"
      onClick={() => onclick && onclick()}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1l14.29 14.29M15.29 1L1 15.29"
      ></path>
    </svg>
  );
};

export const CheckToastTicon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21 7.2C13.379 7.2 7.2 13.379 7.2 21c0 7.622 6.179 13.8 13.8 13.8 7.622 0 13.8-6.178 13.8-13.8 0-7.621-6.178-13.8-13.8-13.8zM6 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M18.45 25.992a1.54 1.54 0 01-1.088-.45l-3.296-3.293a.907.907 0 111.286-1.283l3.099 3.098 8.197-8.197a.906.906 0 011.286 0 .907.907 0 010 1.284l-8.394 8.39c-.29.289-.681.451-1.09.451z"
      ></path>
    </svg>
  );
};

export const CheckTimelineIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 29 23"
    >
      <g filter="url(#filter0_d_331_5440)">
        <path
          fill="#6429CD"
          d="M10.893 18.286a2.115 2.115 0 01-1.497-.62l-4.53-4.526a1.248 1.248 0 111.767-1.764l4.26 4.26L22.16 4.367a1.246 1.246 0 011.768 0 1.247 1.247 0 010 1.764L12.39 17.666a2.125 2.125 0 01-1.497.62z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_331_5440"
          width="27.794"
          height="22.286"
          x="0.5"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_331_5440"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_331_5440" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export const ErrorWarningLineIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21 7.2C13.379 7.2 7.2 13.379 7.2 21c0 7.622 6.179 13.8 13.8 13.8 7.622 0 13.8-6.178 13.8-13.8 0-7.621-6.178-13.8-13.8-13.8zM6 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M20.86 19.909c.584 0 1.059.488 1.059 1.09v6.11c0 .603-.475 1.091-1.06 1.091-.585 0-1.059-.488-1.059-1.09V21c0-.603.474-1.091 1.06-1.091zM19.8 14.89c0-.602.474-1.09 1.06-1.09h.014c.585 0 1.06.488 1.06 1.09 0 .603-.475 1.092-1.06 1.092h-.015c-.585 0-1.059-.489-1.059-1.091z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const OutlineDownCircleDisableIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M25 2C12.297 2 2 12.297 2 25s10.297 23 23 23 23-10.297 23-23S37.703 2 25 2zM0 25C0 11.193 11.193 0 25 0s25 11.193 25 25-11.193 25-25 25S0 38.807 0 25z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.234 19.634a.8.8 0 011.132 0L25 30.27l10.634-10.635a.8.8 0 011.132 1.132l-11.2 11.2a.8.8 0 01-1.132 0l-11.2-11.2a.8.8 0 010-1.132z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const OutlineUpCircleDisableIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      className={`${className ?? ''}`}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12 23.04c6.097 0 11.04-4.943 11.04-11.04C23.04 5.903 18.097.96 12 .96 5.903.96.96 5.903.96 12c0 6.097 4.943 11.04 11.04 11.04zM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M17.648 14.575a.384.384 0 01-.543 0L12 9.471l-5.104 5.104a.384.384 0 11-.544-.542l5.377-5.377c.15-.15.393-.15.543 0l5.376 5.377c.15.15.15.393 0 .543z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const OutlineWarningCircleIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21 7.2C13.379 7.2 7.2 13.379 7.2 21c0 7.622 6.179 13.8 13.8 13.8 7.622 0 13.8-6.178 13.8-13.8 0-7.621-6.178-13.8-13.8-13.8zM6 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15z"
        clipRule="evenodd"
      ></path>
      <g fill="#fff" fillRule="evenodd" clipPath="url(#clip0_63_15024)" clipRule="evenodd">
        <path d="M20.097 13.774a1.925 1.925 0 011.806 0c.275.146.506.358.67.613l.002.002 5.674 8.947.004.007c.161.263.246.56.247.864 0 .303-.082.602-.241.866a1.79 1.79 0 01-.666.637 1.923 1.923 0 01-.913.24H15.32a1.921 1.921 0 01-.913-.24 1.79 1.79 0 01-.666-.637 1.664 1.664 0 01-.241-.866c0-.303.086-.601.247-.864l.004-.007 5.674-8.947.43.243-.428-.245c.164-.255.395-.467.67-.613zm.185 1.102l-5.671 8.942a.76.76 0 000 .787c.072.12.177.22.303.29.126.07.268.108.414.11h11.344a.877.877 0 00.414-.11.818.818 0 00.304-.29.76.76 0 000-.787l-5.672-8.941v-.001a.82.82 0 00-.306-.28.879.879 0 00-.824 0 .82.82 0 00-.306.28z"></path>
        <path d="M21 17.412c.276 0 .5.211.5.472v2.531c0 .261-.224.472-.5.472a.487.487 0 01-.5-.472v-2.53c0-.262.224-.473.5-.473zM20.5 22.946c0-.26.224-.472.5-.472h.007c.276 0 .5.211.5.472s-.224.472-.5.472H21a.487.487 0 01-.5-.472z"></path>
      </g>
      <defs>
        <clipPath id="clip0_63_15024">
          <path fill="#fff" d="M0 0H15V14.167H0z" transform="translate(13.5 12.667)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export const BackButtonIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M8.377.91a.533.533 0 00-.754-.754L.156 7.623a.533.533 0 000 .754l7.467 7.467a.533.533 0 10.754-.755L1.821 8.534h13.663A.525.525 0 0016 8a.525.525 0 00-.516-.533H1.821L8.377.91z"
      ></path>
    </svg>
  );
};

export const AlertTooltipFillIcon = ({ width, height, className, onclick }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      onClick={onclick}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g filter="url(#filter0_i_736_6020)">
        <circle cx="12" cy="12" r="12" fill="#ED0039"></circle>
      </g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.278 6.938a1.472 1.472 0 011.98.52l.002.001 4.54 7.579.003.005a1.471 1.471 0 01-1.259 2.208H7.456a1.473 1.473 0 01-1.259-2.208l.004-.005 4.54-7.579.342.206-.341-.208c.13-.216.315-.395.536-.52zm.148.933v.001l-4.537 7.574a.672.672 0 00.574 1.005h9.074a.673.673 0 00.574-1.005l-4.537-7.574a.672.672 0 00-1.148 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12 10.02c.221 0 .4.178.4.4v2.143a.4.4 0 01-.8 0V10.42c0-.22.18-.4.4-.4zM11.6 14.707c0-.22.18-.4.4-.4h.006a.4.4 0 010 .8H12a.4.4 0 01-.4-.4z"
        clipRule="evenodd"
      ></path>
      <defs>
        <filter
          id="filter0_i_736_6020"
          width="24"
          height="24"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.321569 0 0 0 0 0.482353 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_736_6020"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export const AlertTooltipIcon = ({ width, height, className, fill }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill={fill ?? '#828285'}></circle>
      <path
        fill="#4D4D4F"
        fillRule="evenodd"
        d="M11.278 6.938a1.472 1.472 0 011.98.52l.002.001 4.54 7.579.003.005a1.471 1.471 0 01-1.259 2.208H7.456a1.473 1.473 0 01-1.259-2.208l.004-.005 4.54-7.579.342.206-.341-.208c.13-.216.315-.395.536-.52zm.148.933v.001l-4.537 7.574a.672.672 0 00.574 1.005h9.074a.673.673 0 00.574-1.005l-4.537-7.574a.672.672 0 00-1.148 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#4D4D4F"
        fillRule="evenodd"
        d="M12 10.02c.221 0 .4.178.4.4v2.143a.4.4 0 01-.8 0V10.42c0-.22.18-.4.4-.4zM11.6 14.707c0-.22.18-.4.4-.4h.006a.4.4 0 010 .8H12a.4.4 0 01-.4-.4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const EyeIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        fill="#330072"
        d="M15.791 5.254C14.923 4.177 11.83.667 8.008.667 4.184.667 1.09 4.177.222 5.254a.994.994 0 000 1.251c.868 1.078 3.962 4.588 7.785 4.588 3.822 0 6.916-3.51 7.784-4.588a1.043 1.043 0 000-1.25zM8.008 9.703c-3.007 0-5.63-2.815-6.5-3.823C2.36 4.872 5 2.057 8.007 2.057c3.006 0 5.63 2.815 6.498 3.823-.868 1.008-3.492 3.823-6.498 3.823z"
      ></path>
      <path
        fill="#330072"
        d="M8.007 2.613A3.263 3.263 0 004.74 5.88a3.263 3.263 0 003.267 3.267 3.263 3.263 0 003.266-3.267 3.263 3.263 0 00-3.266-3.267zm0 5.143A1.88 1.88 0 016.13 5.88a1.88 1.88 0 011.877-1.877c1.042 0 1.876.834 1.876 1.877a1.869 1.869 0 01-1.876 1.877z"
      ></path>
    </svg>
  );
};

export const DownloadIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M8.533.533a.533.533 0 10-1.066 0v8.668L4.229 5.963a.533.533 0 00-.754.755l4.141 4.141a.532.532 0 00.768 0l4.141-4.141a.533.533 0 10-.754-.755L8.533 9.201V.533z"
      ></path>
      <path
        fill="#fff"
        d="M.533 9.956c.295 0 .534.239.534.533v3.319a1.126 1.126 0 001.126 1.126h11.614a1.126 1.126 0 001.126-1.126v-3.319a.533.533 0 111.067 0v3.319A2.193 2.193 0 0113.807 16H2.193A2.193 2.193 0 010 13.808v-3.319c0-.295.239-.533.533-.533z"
      ></path>
    </svg>
  );
};

export const PlusIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M12.8.8a.8.8 0 00-1.6 0v10.4H.8a.8.8 0 000 1.6h10.4v10.4a.8.8 0 001.6 0V12.8h10.4a.8.8 0 000-1.6H12.8V.8z"
      ></path>
    </svg>
  );
};

export const CancelTreasuryIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M23.766 1.366A.8.8 0 0022.634.234L12 10.87 1.366.234A.8.8 0 00.234 1.366L10.87 12 .234 22.634a.8.8 0 001.132 1.132L12 13.13l10.634 10.635a.8.8 0 001.132-1.132L13.13 12 23.766 1.366z"
      ></path>
    </svg>
  );
};

export const MoneyIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12 .96C5.903.96.96 5.903.96 12c0 6.097 4.943 11.04 11.04 11.04 6.097 0 11.04-4.943 11.04-11.04C23.04 5.903 18.097.96 12 .96zM0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M14.738 12.152a2.152 2.152 0 00-1.53-.625h-.721v-2.4h1.921a.481.481 0 100-.966h-1.921v-1.44a.483.483 0 00-.821-.34.483.483 0 00-.142.34v1.432h-.72a2.145 2.145 0 00-1.996 1.338 2.15 2.15 0 00-.16.827 2.15 2.15 0 00.626 1.53 2.15 2.15 0 001.53.625h.72v2.4H9.121a.484.484 0 00-.481.482.481.481 0 00.481.481h2.403v1.443a.483.483 0 00.482.481.483.483 0 00.48-.481v-1.443h.722a2.15 2.15 0 001.53-.625 2.15 2.15 0 00.625-1.53 2.18 2.18 0 00-.625-1.529zm-.332 1.53a1.184 1.184 0 01-.35.848 1.193 1.193 0 01-.848.352h-.721v-2.4h.721a1.21 1.21 0 011.108.74c.06.146.091.302.09.46zm-2.882-4.564v2.4h-.72a1.195 1.195 0 01-1.2-1.2 1.184 1.184 0 01.351-.848 1.195 1.195 0 01.848-.352h.721z"
      ></path>
    </svg>
  );
};

export const MinusIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 25 2"
    >
      <path stroke="#fff" strokeLinecap="round" strokeWidth="2" d="M1 1h22.5"></path>
    </svg>
  );
};

export const VerySmileFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.598 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.74 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M25.344 36.323a9.384 9.384 0 003.274 7.134 9.37 9.37 0 006.146 2.282 9.338 9.338 0 006.142-2.282 9.397 9.397 0 003.274-7.134c-6.365-2.757-12.639-2.717-18.836 0zM25.345 29.802a1.477 1.477 0 01-1.478-1.477 2.773 2.773 0 00-2.768-2.769 2.773 2.773 0 00-2.768 2.769 1.477 1.477 0 11-2.956 0 5.73 5.73 0 015.724-5.724 5.732 5.732 0 015.724 5.724c0 .816-.662 1.477-1.478 1.477zM52.677 29.802a1.477 1.477 0 01-1.478-1.477 2.773 2.773 0 00-2.768-2.769 2.773 2.773 0 00-2.768 2.769 1.477 1.477 0 11-2.956 0 5.73 5.73 0 015.724-5.724 5.732 5.732 0 015.724 5.724c0 .816-.662 1.477-1.478 1.477z"
      ></path>
    </svg>
  );
};

export const VerySmileFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#00BF11"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M24.766 36.398a9.95 9.95 0 003.471 7.564 9.935 9.935 0 006.517 2.42 9.902 9.902 0 006.513-2.42 9.964 9.964 0 003.471-7.564c-6.749-2.923-13.4-2.88-19.972 0zM24.765 29.488a1.566 1.566 0 01-1.567-1.567 2.94 2.94 0 00-2.936-2.935 2.94 2.94 0 00-2.935 2.935 1.566 1.566 0 11-3.134 0c0-3.349 2.725-6.069 6.07-6.069a6.078 6.078 0 016.069 6.07c0 .865-.701 1.566-1.567 1.566zM53.745 29.488a1.566 1.566 0 01-1.567-1.567 2.94 2.94 0 00-2.935-2.935 2.94 2.94 0 00-2.935 2.935 1.566 1.566 0 11-3.134 0c0-3.349 2.724-6.069 6.069-6.069a6.078 6.078 0 016.07 6.07c0 .865-.702 1.566-1.568 1.566z"
      ></path>
    </svg>
  );
};

export const SmileFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.595 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.74 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M34.772 43.862c-2.7 0-5.314-.971-7.353-2.732a11.263 11.263 0 01-3.414-5.21 1.848 1.848 0 013.53-1.091 7.596 7.596 0 002.294 3.505 7.565 7.565 0 009.874.004 7.528 7.528 0 002.302-3.505 1.849 1.849 0 012.31-1.223 1.843 1.843 0 011.22 2.31 11.23 11.23 0 01-3.418 5.214 11.218 11.218 0 01-7.345 2.728zM25.35 29.802a1.477 1.477 0 01-1.477-1.478 2.773 2.773 0 00-2.768-2.768 2.773 2.773 0 00-2.769 2.768 1.477 1.477 0 11-2.955 0 5.73 5.73 0 015.724-5.724 5.732 5.732 0 015.723 5.724c0 .817-.66 1.478-1.477 1.478zM52.683 29.802a1.477 1.477 0 01-1.478-1.478 2.773 2.773 0 00-2.768-2.768 2.773 2.773 0 00-2.769 2.768 1.477 1.477 0 11-2.955 0 5.73 5.73 0 015.724-5.724 5.732 5.732 0 015.724 5.724c0 .817-.662 1.478-1.478 1.478z"
      ></path>
    </svg>
  );
};

export const SmileFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#8DD100"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M34.753 44.397c-2.864 0-5.634-1.03-7.797-2.897a11.942 11.942 0 01-3.62-5.524 1.96 1.96 0 013.742-1.157 8.053 8.053 0 002.433 3.716 8.022 8.022 0 0010.47.004 7.983 7.983 0 002.442-3.716 1.96 1.96 0 012.45-1.297 1.954 1.954 0 011.292 2.45 11.908 11.908 0 01-3.624 5.528 11.895 11.895 0 01-7.788 2.893zM24.765 29.488a1.566 1.566 0 01-1.567-1.567 2.94 2.94 0 00-2.936-2.935 2.94 2.94 0 00-2.935 2.935 1.566 1.566 0 11-3.134 0c0-3.349 2.725-6.069 6.07-6.069a6.078 6.078 0 016.069 6.07c0 .865-.701 1.566-1.567 1.566zM53.745 29.488a1.566 1.566 0 01-1.567-1.567 2.94 2.94 0 00-2.935-2.935 2.94 2.94 0 00-2.935 2.935 1.566 1.566 0 11-3.134 0c0-3.349 2.724-6.069 6.069-6.069a6.078 6.078 0 016.07 6.07c0 .865-.702 1.566-1.568 1.566z"
      ></path>
    </svg>
  );
};

export const StraightFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.595 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.74 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M41.61 39.437H27.932a1.85 1.85 0 01-1.848-1.848 1.85 1.85 0 011.848-1.848H41.61a1.85 1.85 0 011.849 1.848 1.85 1.85 0 01-1.849 1.848zM51.435 29.849a4.246 4.246 0 10-6.005-6.005 4.246 4.246 0 006.005 6.005zM25.313 27.461a4.246 4.246 0 10-8.403-1.226 4.246 4.246 0 008.403 1.226z"
      ></path>
    </svg>
  );
};

export const StraightFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#FFCE00"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M42.008 39.705H27.505a1.961 1.961 0 01-1.96-1.96c0-1.081.878-1.96 1.96-1.96h14.503c1.081 0 1.96.879 1.96 1.96s-.879 1.96-1.96 1.96zM52.424 29.538a4.502 4.502 0 10-6.367-6.367 4.502 4.502 0 006.367 6.367zM24.726 27.004a4.502 4.502 0 10-8.91-1.3 4.502 4.502 0 008.91 1.3z"
      ></path>
    </svg>
  );
};

export const SadFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.595 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.737 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M40.91 40.15c-.427 0-.857-.147-1.207-.45a7.554 7.554 0 00-4.94-1.832c-1.816 0-3.568.65-4.935 1.832a1.851 1.851 0 01-2.605-.187 1.844 1.844 0 01.188-2.605 11.259 11.259 0 0114.702 0 1.848 1.848 0 01-1.207 3.246l.004-.004zM25.226 27.817a4.246 4.246 0 10-8.267-1.942 4.246 4.246 0 008.267 1.942zM52.556 27.816a4.246 4.246 0 10-8.267-1.942 4.246 4.246 0 008.267 1.942z"
      ></path>
    </svg>
  );
};

export const SadFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#FE9900"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M41.266 40.461c-.451 0-.908-.156-1.28-.477a8.01 8.01 0 00-5.236-1.943 7.99 7.99 0 00-5.233 1.943 1.963 1.963 0 01-2.762-.199c-.71-.819-.621-2.052.198-2.762a11.938 11.938 0 0115.589 0 1.959 1.959 0 01-1.28 3.442l.004-.004zM24.64 27.381a4.502 4.502 0 10-8.767-2.06 4.502 4.502 0 008.766 2.06zM53.618 27.384a4.502 4.502 0 10-8.766-2.059 4.502 4.502 0 008.766 2.06z"
      ></path>
    </svg>
  );
};

export const VerySadFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.595 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.737 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M25.766 44.511a1.843 1.843 0 01-1.765-2.394 11.262 11.262 0 013.418-5.213 11.219 11.219 0 017.345-2.729c2.7 0 5.313.968 7.353 2.733a11.263 11.263 0 013.414 5.21A1.848 1.848 0 0142 43.209a7.595 7.595 0 00-2.294-3.505 7.568 7.568 0 00-9.878-.004 7.578 7.578 0 00-2.302 3.505 1.847 1.847 0 01-1.765 1.303l.004.003zM25.228 27.817a4.246 4.246 0 10-8.267-1.942 4.246 4.246 0 008.267 1.942zM52.558 27.816a4.246 4.246 0 10-8.267-1.942 4.246 4.246 0 008.267 1.942z"
      ></path>
    </svg>
  );
};

export const VerySadFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#FF3200"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M25.21 45.086c-.19 0-.385-.03-.579-.089a1.954 1.954 0 01-1.292-2.45 11.94 11.94 0 013.623-5.528 11.895 11.895 0 017.789-2.893c2.863 0 5.634 1.026 7.796 2.897a11.942 11.942 0 013.62 5.524 1.96 1.96 0 01-3.742 1.158 8.054 8.054 0 00-2.433-3.717 8.025 8.025 0 00-10.474-.004 8.035 8.035 0 00-2.442 3.716 1.959 1.959 0 01-1.87 1.381l.004.005zM24.64 27.381a4.502 4.502 0 10-8.767-2.06 4.502 4.502 0 008.766 2.06zM53.618 27.384a4.502 4.502 0 10-8.766-2.059 4.502 4.502 0 008.766 2.06z"
      ></path>
    </svg>
  );
};

export const SearchIcon = ({ width, height, className, onclick }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      data-testid="svg-search-icon"
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 20 20"
      onClick={() => onclick && onclick()}
    >
      <g clipPath="url(#clip0_706_760)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M14.812 15.754a8.963 8.963 0 11.943-.943l4.05 4.05a.667.667 0 01-.943.943l-4.05-4.05zM1.333 8.963a7.63 7.63 0 1113.105 5.314.667.667 0 00-.16.16A7.63 7.63 0 011.333 8.963z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_706_760">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export const ScrollIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.529 4.195c.26-.26.682-.26.942 0l9.334 9.334a.667.667 0 11-.943.942L10 5.61l-8.862 8.862a.667.667 0 11-.943-.942L9.53 4.195z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const ThumbsUp = ({ width, height, className }: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${className ?? ''}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_130_1498)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1633 6.23754C11.2275 6.09309 11.3708 6 11.5288 6C12.0805 6 12.6095 6.21914 12.9996 6.60921C13.3897 6.99929 13.6088 7.52834 13.6088 8.07999V9.91998H16.3763C16.5958 9.91784 16.8132 9.96327 17.0135 10.0532C17.2145 10.1434 17.3935 10.2762 17.5381 10.4425C17.6827 10.6088 17.7894 10.8045 17.8508 11.0161C17.9122 11.2277 17.9269 11.4501 17.8939 11.668L17.8938 11.6686L17.1211 16.7079C17.1211 16.708 17.1211 16.7078 17.1211 16.7079C17.0661 17.0702 16.8819 17.4008 16.6027 17.6381C16.324 17.8751 15.9692 18.0035 15.6035 17.9999H7.60886C7.20573 17.9999 6.81912 17.8398 6.53406 17.5547C6.24901 17.2697 6.08887 16.8831 6.08887 16.4799V12.56C6.08887 12.1568 6.24901 11.7702 6.53406 11.4852C6.81912 11.2001 7.20573 11.04 7.60886 11.04H9.0289L11.1633 6.23754ZM9.68885 11.5249L11.7779 6.82446C12.0246 6.87341 12.2535 6.99443 12.4339 7.1749C12.674 7.41494 12.8088 7.74051 12.8088 8.07999V10.32C12.8088 10.5409 12.9879 10.72 13.2088 10.72H16.3784L16.3829 10.7199C16.4873 10.7188 16.5907 10.7403 16.6859 10.783C16.7811 10.8258 16.8659 10.8887 16.9344 10.9675C17.0029 11.0462 17.0534 11.1389 17.0825 11.2392C17.1116 11.3393 17.1186 11.4446 17.103 11.5477C17.103 11.5477 17.1029 11.5478 17.103 11.5477L16.3301 16.588C16.3041 16.7596 16.2169 16.9161 16.0846 17.0286C15.9523 17.1411 15.7838 17.2019 15.6101 17.2L9.68885 17.1999V11.5249ZM8.88885 17.1999V11.84H7.60886C7.41791 11.84 7.23477 11.9158 7.09975 12.0508C6.96472 12.1859 6.88887 12.369 6.88887 12.56V16.4799C6.88887 16.6709 6.96472 16.854 7.09975 16.989C7.23477 17.1241 7.41791 17.1999 7.60886 17.1999H8.88885Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_130_1498">
          <rect width="12" height="12" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ColumnsIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      className={`${className ?? ''}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.64063 1.91016H10.5143C10.8079 1.91016 11.0894 2.02676 11.2969 2.23432C11.5045 2.44188 11.6211 2.72339 11.6211 3.01693V10.7643C11.6211 11.0579 11.5045 11.3394 11.2969 11.5469C11.0894 11.7545 10.8079 11.8711 10.5143 11.8711H6.64063M6.64063 1.91016H2.76693C2.47339 1.91016 2.19188 2.02676 1.98432 2.23432C1.77676 2.44188 1.66016 2.72339 1.66016 3.01693V10.7643C1.66016 11.0579 1.77676 11.3394 1.98432 11.5469C2.19188 11.7545 2.47339 11.8711 2.76693 11.8711H6.64063M6.64063 1.91016V11.8711"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ShuffleIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${className ?? ''}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3703 0.533333C10.3703 0.238781 10.609 0 10.9036 0H15.0517C15.3463 0 15.5851 0.238781 15.5851 0.533333V4.68148C15.5851 4.97603 15.3463 5.21482 15.0517 5.21482C14.7572 5.21482 14.5184 4.97603 14.5184 4.68148V1.821L1.32525 15.0142C1.11697 15.2224 0.779284 15.2224 0.571005 15.0142C0.362725 14.8059 0.362725 14.4682 0.571005 14.2599L13.7643 1.06667H10.9036C10.609 1.06667 10.3703 0.827885 10.3703 0.533333Z"
        fill="#6429CD"
      ></path>
      <path
        d="M15.5851 11.3188C15.5851 11.0242 15.3463 10.7854 15.0517 10.7854C14.7572 10.7854 14.5184 11.0242 14.5184 11.3188V14.1796L10.4513 10.1125C10.243 9.90418 9.90533 9.90418 9.69705 10.1125C9.48877 10.3207 9.48877 10.6584 9.69705 10.8667L13.7639 14.9336H10.9036C10.609 14.9336 10.3703 15.1723 10.3703 15.4669C10.3703 15.7615 10.609 16.0002 10.9036 16.0002H15.0297C15.1735 16.0062 15.3193 15.9543 15.4291 15.8445C15.537 15.7366 15.589 15.594 15.5851 15.4527V11.3188Z"
        fill="#6429CD"
      ></path>
      <path
        d="M1.32525 0.985376C1.11697 0.777097 0.779284 0.777097 0.571005 0.985376C0.362725 1.19366 0.362725 1.53134 0.571005 1.73962L4.71915 5.88777C4.92743 6.09605 5.26512 6.09605 5.4734 5.88777C5.68168 5.67949 5.68168 5.3418 5.4734 5.13352L1.32525 0.985376Z"
        fill="#6429CD"
      ></path>
    </svg>
  );
};

export const QuestionMarkIcon = ({ width, height, className, fill }: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${className ?? ''}`}
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50125 2.04532C4.75669 1.91761 3.99095 2.05753 3.33967 2.44029C2.68838 2.82306 2.19358 3.42397 1.9429 4.1366C1.75969 4.65739 1.18898 4.93107 0.668185 4.74786C0.147387 4.56466 -0.126288 3.99395 0.0569171 3.47315C0.464281 2.31513 1.26834 1.33865 2.32667 0.71666C3.38501 0.094666 4.62933 -0.1327 5.83924 0.0748313C7.04915 0.282363 8.14657 0.9114 8.93713 1.85053C9.72757 2.78951 10.1602 3.97789 10.1585 5.20526C10.1581 7.13493 8.724 8.44507 7.61418 9.18495C7.02877 9.57523 6.45046 9.86367 6.02246 10.0539C5.80703 10.1496 5.62612 10.2221 5.49672 10.2714C5.43194 10.296 5.37983 10.315 5.34249 10.3283L5.29774 10.344L5.28412 10.3486L5.27957 10.3502L4.96046 9.40282L5.27657 10.3512C4.75282 10.5257 4.1867 10.2427 4.01212 9.71894C3.8377 9.19567 4.12006 8.63013 4.64287 8.45498L4.64725 8.45348L4.67272 8.44456C4.69688 8.43596 4.73497 8.42213 4.78499 8.40308C4.8852 8.3649 5.0323 8.30614 5.21048 8.22694C5.56968 8.0673 6.04099 7.83093 6.50519 7.52146C7.49443 6.86197 8.15928 6.07318 8.15928 5.20436C8.16041 4.44893 7.89414 3.71598 7.40764 3.13806C6.92114 2.56013 6.24581 2.17303 5.50125 2.04532Z"
        fill={fill ?? '#fff'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.073 15.0004C4.073 14.4483 4.52055 14.0007 5.07263 14.0007H5.08663C5.63871 14.0007 6.08626 14.4483 6.08626 15.0004C6.08626 15.5524 5.63871 16 5.08663 16H5.07263C4.52055 16 4.073 15.5524 4.073 15.0004Z"
        fill={fill ?? '#fff'}
      />
    </svg>
  );
};

export const SubjectIcon = ({ width, height, className, fill }: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${className ?? ''}`}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.47983 1.49323V1.28C3.47983 0.573076 4.05291 0 4.75983 0H9.23983C9.94676 0 10.5198 0.573076 10.5198 1.28V1.49323H11.4798C12.0173 1.49323 12.5328 1.70675 12.9129 2.08683C13.293 2.4669 13.5065 2.98239 13.5065 3.5199V13.9732C13.5065 14.5107 13.293 15.0262 12.9129 15.4063C12.5328 15.7864 12.0173 15.9999 11.4798 15.9999H2.51983C1.98233 15.9999 1.46683 15.7864 1.08676 15.4063C0.706687 15.0262 0.493164 14.5107 0.493164 13.9732V3.5199C0.493164 2.98239 0.706687 2.4669 1.08676 2.08683C1.46683 1.70675 1.98233 1.49323 2.51983 1.49323H3.47983ZM4.5465 1.28C4.5465 1.16218 4.64201 1.06667 4.75983 1.06667H9.23983C9.35766 1.06667 9.45317 1.16218 9.45317 1.28V2.02446L9.45316 2.02656L9.45317 2.02867V2.77333C9.45317 2.89115 9.35766 2.98667 9.23983 2.98667H4.75983C4.64201 2.98667 4.5465 2.89115 4.5465 2.77333V1.28ZM10.5198 2.5599V2.77333C10.5198 3.48026 9.94676 4.05333 9.23983 4.05333H4.75983C4.05291 4.05333 3.47983 3.48026 3.47983 2.77333V2.5599H2.51983C2.26522 2.5599 2.02104 2.66104 1.84101 2.84107C1.66097 3.02111 1.55983 3.26529 1.55983 3.5199V13.9732C1.55983 14.2278 1.66097 14.472 1.84101 14.6521C2.02104 14.8321 2.26522 14.9332 2.51983 14.9332H11.4798C11.7344 14.9332 11.9786 14.8321 12.1587 14.6521C12.3387 14.472 12.4398 14.2278 12.4398 13.9732V3.5199C12.4398 3.26529 12.3387 3.02111 12.1587 2.84107C11.9786 2.66104 11.7344 2.5599 11.4798 2.5599H10.5198Z"
        fill={fill ?? '#fff'}
      />
    </svg>
  );
};

export const AssignIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${className ?? ''}`}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.52667 0C1.98916 0 1.47367 0.213523 1.0936 0.593597C0.713523 0.973671 0.5 1.48916 0.5 2.02667V13.9733C0.5 14.5108 0.713523 15.0263 1.0936 15.4064C1.47367 15.7865 1.98916 16 2.52667 16H11.4867C12.0242 16 12.5397 15.7865 12.9197 15.4064C13.2998 15.0263 13.5133 14.5108 13.5133 13.9733V5.76C13.5133 5.61855 13.4571 5.4829 13.3571 5.38288L8.13046 0.15621C8.03044 0.0561903 7.89478 0 7.75333 0H2.52667ZM1.84784 1.34784C2.02788 1.16781 2.27206 1.06667 2.52667 1.06667H7.22V5.76C7.22 6.05455 7.45878 6.29333 7.75334 6.29333H12.4467L12.4467 13.9733C12.4467 14.2279 12.3455 14.4721 12.1655 14.6522C11.9855 14.8322 11.7413 14.9333 11.4867 14.9333H2.52667C2.27206 14.9333 2.02788 14.8322 1.84784 14.6522C1.66781 14.4721 1.56667 14.2279 1.56667 13.9733V2.02667C1.56667 1.77206 1.66781 1.52788 1.84784 1.34784ZM11.6924 5.22667L8.28667 1.82092V5.22667H11.6924Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.90671 9C2.90671 8.66863 3.17534 8.4 3.50671 8.4H10.5067C10.8381 8.4 11.1067 8.66863 11.1067 9C11.1067 9.33137 10.8381 9.6 10.5067 9.6H3.50671C3.17534 9.6 2.90671 9.33137 2.90671 9Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.90674 12C2.90674 11.6686 3.17537 11.4 3.50674 11.4H10.5067C10.8381 11.4 11.1067 11.6686 11.1067 12C11.1067 12.3314 10.8381 12.6 10.5067 12.6H3.50674C3.17537 12.6 2.90674 12.3314 2.90674 12Z"
        fill="white"
      />
    </svg>
  );
};

export const CheckToastTiconFilled = () => {
  return (
    <svg width="48" height="37" viewBox="0 0 48 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1636_1437)">
        <path
          d="M16.7857 32.5717C16.2297 32.5719 15.6791 32.4626 15.1653 32.25C14.6515 32.0374 14.1846 31.7257 13.7913 31.3326L4.73034 22.2803C4.26268 21.8124 4 21.1778 4 20.5163C4 19.8547 4.26268 19.2202 4.73034 18.7522V18.7522C5.20085 18.2861 5.8364 18.0247 6.49868 18.0247C7.16097 18.0247 7.79641 18.2861 8.26692 18.7522L16.7857 27.2709L39.3219 4.73489C39.5538 4.50204 39.8293 4.31728 40.1327 4.19121C40.4362 4.06513 40.7616 4.00024 41.0902 4.00024C41.4188 4.00024 41.7441 4.06513 42.0476 4.19121C42.351 4.31728 42.6266 4.50204 42.8585 4.73489V4.73489C43.3262 5.20283 43.5888 5.83736 43.5888 6.49893C43.5888 7.16049 43.3262 7.79503 42.8585 8.26297L19.7802 31.3326C18.9848 32.1249 17.9084 32.5703 16.7857 32.5717Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1636_1437"
          x="0"
          y="0.000244141"
          width="47.5889"
          height="36.5714"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1636_1437" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1636_1437"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const TrashIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7a1 1 0 0 1 1 1v11a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8a1 1 0 1 1 2 0v11a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V8a1 1 0 0 1 1-1z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1zM14 8a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1zM4 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1zM8 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1z"
        fill="#fff"
      />
    </svg>
  );
};

export const PencilIcon = ({ width, height, className, fillICon }: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3365 1.06667C13.1268 1.06667 12.9192 1.10797 12.7254 1.18822C12.5317 1.26847 12.3557 1.38609 12.2074 1.53437L2.14031 11.6014L1.29346 14.7065L4.39857 13.8597L14.4656 3.79263C14.6139 3.64435 14.7315 3.46832 14.8118 3.27458C14.892 3.08085 14.9333 2.8732 14.9333 2.6635C14.9333 2.4538 14.892 2.24615 14.8118 2.05242C14.7315 1.85868 14.6139 1.68265 14.4656 1.53437C14.3174 1.38609 14.1413 1.26847 13.9476 1.18822C13.7539 1.10797 13.5462 1.06667 13.3365 1.06667ZM12.3172 0.202747C12.6404 0.0688933 12.9867 0 13.3365 0C13.6863 0 14.0326 0.0688933 14.3558 0.202747C14.6789 0.3366 14.9726 0.532792 15.2199 0.780121C15.4672 1.02745 15.6634 1.32107 15.7973 1.64422C15.9311 1.96737 16 2.31373 16 2.6635C16 3.01327 15.9311 3.35962 15.7973 3.68278C15.6634 4.00593 15.4672 4.29955 15.2199 4.54688L5.05267 14.7141C4.98704 14.7797 4.90542 14.8271 4.81588 14.8515L0.673675 15.9812C0.489029 16.0316 0.291555 15.9791 0.156222 15.8438C0.0208887 15.7085 -0.0315531 15.511 0.018805 15.3263L1.1485 11.1841C1.17292 11.0946 1.22028 11.013 1.28591 10.9473L11.4531 0.780121C11.7005 0.532792 11.9941 0.3366 12.3172 0.202747Z"
        fill={fillICon? fillICon:'white'}
      />
    </svg>
  );
};

export const CheckRouteIcon = ({ width, height, className, fill, fillICon}: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className ?? ''}`}
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M21 7.2C13.379 7.2 7.2 13.379 7.2 21c0 7.622 6.179 13.8 13.8 13.8 7.622 0 13.8-6.178 13.8-13.8 0-7.621-6.178-13.8-13.8-13.8zM6 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fillICon}
        stroke={fillICon}
        d="M18.45 25.992a1.54 1.54 0 01-1.088-.45l-3.296-3.293a.907.907 0 111.286-1.283l3.099 3.098 8.197-8.197a.906.906 0 011.286 0 .907.907 0 010 1.284l-8.394 8.39c-.29.289-.681.451-1.09.451z"
      ></path>
    </svg>
  );
};
