/**
 * Solicitudes Pragma SA
 */

import React, { createContext, useMemo, useReducer, useState } from 'react';

import SpinnerLoader from '@/components/ui/spinnerLoader';
import Toast from '@/components/ui/toast';

import type * as Types from './types';

export { Types };

/* Actions Types */
enum Actions {
  SET_LOADING = 'UTILS.SET_LOADING',
  SET_SCROLL = 'UTILS.SET_SCROLL',
  SET_CURRENT_TAB = 'UTILS.SET_CURRENT_TAB',
}

/* Initial State */
const initialState: Types.TState = {
  loading: false,
  scroll: 0,
  currentTab: 0,
};

/* Reducer */
const reducer = (state: Types.TState, action: Types.TActions): Types.TState => {
  switch (action.type) {
    case Actions.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case Actions.SET_SCROLL: {
      return {
        ...state,
        scroll: action.payload,
      };
    }

    case Actions.SET_CURRENT_TAB: {
      return {
        ...state,
        currentTab: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const utilsContext = createContext({} as Types.TContext);

const UtilsProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [toast, setToast] = useState<{
    color: 'danger' | 'success' | 'warning' | 'information';
    message: string;
  } | null>(null);

  const actions: Types.IActions = {
    addToast: (message: string, color: 'danger' | 'success' | 'warning' | 'information') => {
      setToast({ message, color });
      setTimeout(() => {
        setToast(null);
      }, 8000);
    },

    setLoading: (value: boolean) => {
      dispatch({ type: Actions.SET_LOADING, payload: value });
    },

    setScroll: (value: number) => {
      dispatch({ type: Actions.SET_SCROLL, payload: value });
    },

    setCurrentTab: (value: number) => {
      dispatch({ type: Actions.SET_CURRENT_TAB, payload: value });
    },
  };

  return (
    <utilsContext.Provider value={useMemo(() => ({ state, actions }), [state, actions])}>
      {children}
      {state.loading && <SpinnerLoader />}
      {toast && <Toast color={toast.color} message={toast.message} />}
    </utilsContext.Provider>
  );
};

export default UtilsProvider;
