import React, { useState } from 'react';

import { FormProgressRoutes } from '@/components/molecules/FormProgressRoutes';


export const ProgressRoutes = () => {
  return (
    <>
      <FormProgressRoutes />
    </>
  );
};
