/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { Route, RouteObject, Routes } from 'react-router-dom';

import { DisponiblesPage } from '@/pages/disponibles/index';
import { FeedbackPage } from '@/pages/feedback';
import LoginPage from '@/pages/login';
import { Rutas } from '@/pages/rutas';
import { Protected } from '@/routes';
import Template, { TemplateType } from '@/template';

import Page404 from './404';
import ErrorPage from './error';
import HistoryComment from './feedback/historyComment';
import RoutesGrowth from '@/pages/rutasCrecimiento';

export type RouteObjectExtended = {
  hidden?: boolean;
  name?: string;
} & RouteObject;

export type TRoutes = {
  name: string;
  id: string;
  index: string;
  routes: (RouteObjectExtended & {
    full?: boolean;
    template?: TemplateType;
    showTitle?: boolean;
    backButton?: boolean;
  })[];
};

export const PRAGMA: TRoutes[] = [
  {
    name: 'Feedback',
    id: '77b691f7-7a53-5f63-9cf1-75ff51c21c80',
    index: '/',
    routes: [
      { path: '/', element: <FeedbackPage /> },
      {
        path: '/comentarios',
        name: 'Comentarios',
        element: <HistoryComment />,
        template: TemplateType.template2,
        backButton: true,
        showTitle: false,
      },
    ],
  },
  {
    name: 'Disponibles',
    id: '77b691f7-7a53-5f63-9cf1-75ff51c2dfdd',
    index: '/disponibles',
    routes: [
      {
        path: '/disponibles',
        element: <DisponiblesPage />,
        template: TemplateType.template2,
        showTitle: false,
      },
    ],
  },
  {
    name: 'Rutas',
    id: '77b691f7-7a53-5f63-9cf1-75ff51c2dfde',
    index: '/rutas',
    routes: [
      {
        path: '/rutas',
        element: <Rutas />,
        template: TemplateType.template1,
        showTitle: true,
      },
    ],
  },
  {
    name: 'Rutas de crecimiento',
    id: '77b691f7-7a53-4f86-9cf1-75ff51c21c70',
    index: '/asignar-ruta',
    routes: [
      {
        path: '/asignar-ruta',
        element: <RoutesGrowth />,
        template: TemplateType.template1,
        showTitle: true,
      },
    ],
  },
];

let routes: JSX.Element[] = [];
PRAGMA.forEach((app) =>
  app.routes.forEach((route) => {
    routes = [
      ...routes,
      ...[
        <Route
          key={route.path}
          path={route.path}
          element={<Protected path={route.path}>{route.element}</Protected>}
        />,
      ],
    ];
  }),
);

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Template />}>
        {routes}
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Pages;
