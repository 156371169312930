import React, { useState, useContext, useEffect } from 'react';

//components
import { format } from 'date-fns';
import * as locale from 'date-fns/locale';

import { ChevronDownIcon } from '@/assets/icons/icons';
import { AProcessBar } from '@/components/atoms/AProcessBar/AProcessBar';
import { ToolTipContact } from '@/components/contact';
import { ListAnswersHeatMap } from '@/components/molecules/ListAnswersHeatMap';
import Table from '@/components/table';
//====
import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import { HeatMapService } from '@/services/heatMap';
import { resumeHeatMap } from '@/services/heatMap/type';

import styles from './styles.module.scss';

interface UserFeedbackProps {
  email?: string;
}

const ListHeatMap = ({ email }: UserFeedbackProps) => {
  const [dropdowned, setDropdowned] = useState<React.Key[]>([]);

  const [list, setList, setListLoading] = useList<resumeHeatMap>();
  const cancellablePromise = useCancellablePromise();
  const {
    actions: { addToast },
  } = useContext(utilsContext);

  useEffect(() => {
    requestListHeatMap();
  }, [email]);

  const requestListHeatMap = (page = 0) => {
    if (!email) return null;
    setListLoading(true, !page);
    cancellablePromise(
      HeatMapService.getResumeHeatMap(email, page + 1, 10)
        .finally(() => setListLoading(false))
        .then((res) => {
          console.log('====res>>', res);
          setList(res || [], !!page, res.length, res.length - 1 == page, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  const toggleDropDowned = (key: any) => {
    const index = dropdowned.indexOf(key);
    if (index < 0) setDropdowned((v) => [...v, key]);
    else {
      const newData = [...dropdowned];
      newData.splice(index, 1);
      setDropdowned(newData);
    }
  };

  const formatDate = (date: Date): string => {
    const str = format(date, 'MMM-dd-yyyy', { locale: locale.es });
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className={styles['heat-map-table']}>
      <Table
        header={[
          { name: 'totalScore', label: 'Cobertura de operación' },
          { name: 'createDate', label: 'Fecha de creación' },
          { name: 'observations', label: 'Observación' },
          { name: 'createdBy', label: 'Creada por' },
        ]}
        data={list.list.map((item) => ({
          _key: `${item.id}`,
          ...(item as any),
          totalScore: (
            <div className={styles['coverage-container']}>
              <button
                className={styles.chevron}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropDowned(`${item.id}`);
                }}
              >
                <ChevronDownIcon width={17} height={17} fill="#440099" />
              </button>
              <div className={styles['coverage-container__percentage']}>
                <AProcessBar percentage={item.totalScore} />
              </div>
            </div>
          ),
          createDate: formatDate(new Date(item.createDate)),
          observations: (
            <p className={styles['text-observations']}>
              {item.observations ? item.observations : '-'}
            </p>
          ),
          createdBy: (
            <div className={styles['image-name']}>
              <ToolTipContact
                className={''}
                user2={{ photo: item.createdBy?.photo, label: item.createdBy?.name }}
              />
              <p>{item.createdBy?.name}</p>
            </div>
          ),
        }))}
        advanced={{
          ...list,
          handleSearch: (page) => requestListHeatMap(page),
          dropdownContent: (item) => {
            return <ListAnswersHeatMap heatMapId={item.id} />;
          },
          dropdowned: dropdowned,
          isAnyVisible: false,
        }}
        classEmpty={true}
        emptyLabel={'Aún no se han registrado Mapas de calor'}
      />
    </div>
  );
};

export default ListHeatMap;
