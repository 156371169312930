/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import styles from './styles.module.scss';

type TProps = {
  children: React.ReactNode;
  label?: string;
  className: string;
};

type TStyle = {
  main: string;
  mainChildren: string;
  label: string;
};

const Tooltip = ({ children, label, className }: TProps) => {
  let styleProps: TStyle = {
    main: 'tooltip',
    mainChildren: 'top',
    label: 'tooltip-label',
  };

  if (className === '-icon') {
    styleProps = {
      main: `tooltip${className}`,
      mainChildren: 'down-icon',
      label: 'tooltip-icon-label-icon',
    };
  }
  if (className === '-detail-pragmatico') {
    styleProps = {
      main: `tooltip${className}`,
      mainChildren: `front${className}`,
      label: `tooltip-${className}-label`,
    };
  }

  return (
    <div className={`${styles[styleProps.main]} ${styles[styleProps.mainChildren]}`}>
      {children}
      {label && <span className={styles[styleProps.label]}>{label}</span>}
    </div>
  );
};

export default Tooltip;
