import React from 'react';

import styles from './styles.module.scss';

type TProps = {
  children: React.ReactNode;
  bgColor: string;
};
export const Badge: React.FC<TProps> = ({ children, bgColor = 'gray' }) => {
  return (
    <span className={styles.badge} style={{ backgroundColor: bgColor }}>
      {children}
    </span>
  );
};

export default Badge;
