/**
 * Travel Journal
 *
 */

import React, { memo } from 'react';

import styles from './styles.module.scss';

type TProps = {
  children: React.ReactNode;
  content: React.ReactNode;
};

const Popover = ({ children, content }: TProps) => {
  if (content)
    return (
      <div className={`${styles['popover']} ${styles['top']}`}>
        {children}
        <div id="popover-content" className={styles['popover-content']}>
          {content}
        </div>
      </div>
    );
  else return <>{children}</>;
};

export default memo(Popover);
