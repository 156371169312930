import CryptoJS from 'crypto-js';
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

interface Props {
  value: string;
  searchValue: string | RegExp;
  replaceValue: string;
}
interface IDataLocalStorage {
  name: string;
  value: string;
}
/**
 *
 * @param value base string
 * @param searchValue string or regex you want to search for
 * @param replaceValue Value you want to replace
 * @returns string value in lowercase and trimmed
 */
export function replaceValue({ value, searchValue, replaceValue }: Props) {
  if (value.length === 0) return '';
  return value.trim().replace(searchValue, replaceValue).toLowerCase();
}

function decodingURI(uri: string): string {
  return decodeURIComponent(uri);
}

function decryptData(value: string) {
  if (!SECRET_KEY || !value) return '';
  const bytes = CryptoJS.AES.decrypt(value, SECRET_KEY);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}

/**
 *
 * @param value encoded string value
 * @returns decode value format
 */
export function decryptDataValue(value: string) {
  const data = decodingURI(value);
  return decryptData(data);
}

/**
 *
 * @param value base string
 * @returns encoded string
 */
export function encryptDataValue(value: string) {
  if (!SECRET_KEY || !value) return '';
  const data = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
  return encodeURIComponent(data);
}
/**
 *
 * @returns integer number between 0 and 255
 */
export function randomHexadecimalNumber(): number {
  return Math.trunc(Math.random() * 255);
}

/**
 *
 * @returns RGB color value
 */
export function getRandomRGBColor(): string {
  let rgb = 'rgb(';
  for (let index = 0; index < 3; index++) {
    rgb += `${randomHexadecimalNumber()}`;
    if (index < 2) rgb += ',';
  }
  rgb += ')';
  return rgb;
}

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getDataInitial = (email: string, initialData?: string): string => {
  if (initialData == undefined || initialData == '') return '';
  const valueForm: IDataLocalStorage[] = JSON.parse(initialData);
  const valueByEmail = valueForm.find((data) => data.name == email);
  if (valueByEmail) return valueByEmail.value;
  return '';
};

export const deleteDataLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const saveDataLocalStorage = (name: string, value: string) => {
  let dataParse: IDataLocalStorage[] = []; // variable del parseo de la data;  String -> []
  let dataById; // variable almacenar temporalmente el que estamos editando.
  let filterById; // almacenar temporalmente la limpieza de la data != a la que estamos editando
  const dataOld = localStorage.getItem('dataOffline') || '';
  if (dataOld != '') {
    // hay algo guardado
    dataParse = JSON.parse(dataOld);
    dataById = dataParse.find((data) => data.name === name);
    if (dataById) {
      filterById = dataParse.filter((data) => data.name != name);
      dataById.value = value;
      filterById.push(dataById);
      localStorage.setItem('dataOffline', JSON.stringify(filterById));
      return;
    }
    dataParse.push({ name: name, value: value });
    localStorage.setItem('dataOffline', JSON.stringify(dataParse));
    return;
  }
  localStorage.setItem('dataOffline', JSON.stringify([{ name: name, value: value }]));
};
