import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { utilsContext } from '@/contexts/utils';
import { encryptDataValue } from '@/helpers';

export const useGoToUserDetails = (email?: string): [string, () => void] => {
  const {
    actions: { addToast },
  } = useContext(utilsContext);
  const navigate = useNavigate();

  if (!email) {
    addToast('El usuario no tiene un correo registrado.', 'warning');
    return [
      '',
      () => {
        return;
      },
    ];
  }
  const encryptedEmail = encryptDataValue(email);
  const url = `/comentarios/?data=${encryptedEmail}`;

  return [
    url,
    () => {
      if (!encryptedEmail) return;
      navigate(url, { replace: true });
    },
  ];
};
