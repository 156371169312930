import React from 'react';

import { IconType } from 'react-icons/lib';

import avatar from '@/assets/images/user.png';
import { ContactInfo } from '@/components/contact/contact-info';
import Tooltip from '@/components/ui/tooltip';
import endpoints from '@/services/endpoints';
import { ContactImage, Pragmatico } from '@/services/users';

import style from './styles.module.scss';

type Icon = {
  label: string;
  icon: IconType;
};

type TUser2 = {
  photo: string | null;
  label: string;
};

type GeneralTooltip = {
  className: string;
  user?: Partial<Pragmatico>;
  icon?: Icon;
  user2?: TUser2;
};

const getImage = (images?: ContactImage[] | null) => {
  let url = images?.find((url) => url.tipoFotografia == 'REDONDA');
  if (!url) url = images?.[0];
  if (url) return endpoints.GET_MULTIMEDIA + '/' + url?.contenido;
  else return avatar;
};

//user2 recibe photo: string y label: string
const getImageUser2 = (photo?: string | null) => {
  if (photo) return endpoints.GET_MULTIMEDIA + '/' + photo;
  else return avatar;
};

export const Contact = ContactInfo;

export const ToolTipContact: React.FC<GeneralTooltip> = ({ className, user, icon, user2 }) => {
  return user ? (
    <Tooltip label={user.fullname} className={className}>
      <div className={style.image}>
        <img src={getImage(user.images)} alt={user.fullname} />
      </div>
    </Tooltip>
  ) : icon ? (
    <Tooltip label={icon.label} className={className}>
      <div>{<icon.icon />}</div>
    </Tooltip>
  ) : user2 ? (
    <Tooltip label={user2.label} className={className}>
      <div className={style.image}>
        <img src={getImageUser2(user2.photo)} alt={user2.label} />
      </div>
    </Tooltip>
  ) : (
    <div></div>
  );
};
