import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import { saveStorieProps, State, Stories } from '@/services/storie/type';

export * from './type';

export class UserStoryService {
  static async getUserStories(email: string, groupId: number): Promise<Stories> {
    const response = await axiosClient({
      url: endpoints.feedback.users + `/${email}/userStories?group=${groupId}`,
      method: 'GET',
    });
    return response.data;
  }

  static async getStates(): Promise<State[]> {
    const response = await axiosClient({
      url: endpoints.feedback.stories + `/states`,
      method: 'GET',
    });
    return response.data;
  }

  static async saveStorie(props: saveStorieProps): Promise<any> {
    const response = await axiosClient({
      url: endpoints.feedback.stories,
      method: 'POST',
      data: props,
    });
    return response.data;
  }

  static async updateStorie(storyId: number, newState?: number): Promise<any> {
    const response = await axiosClient({
      url: endpoints.feedback.stories + `/${storyId}`,
      method: 'PUT',
      data: {
        stateId: newState,
      },
    });
    return response.data;
  }

  static async deleteUserStorie(storyId: number): Promise<any> {
    await axiosClient({
      url: endpoints.feedback.stories + `/${storyId}`,
      method: 'DELETE',
    });
  }
}

export default UserStoryService;

// export class ProjectTracing {
//   static async getUserStories(email: string): Promise<IUserStorie[]> {
//     const response = await axiosClient({
//       url: endpoints.feedback.users + `/${email}/userStories`,
//       method: 'GET',
//     });
//     return response.data;
//   }
// }

// export default ProjectTracing;
