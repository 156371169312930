/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import user from '@/assets/images/user.png';
import Button from '@/components/ui/button';
import { sessionContext } from '@/contexts/session';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import useOutside from '@/hooks/useOutside';
import endpoints from '@/services/endpoints';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const UserMenu = () => {
  const t = useLocalTranslation(i18n as Resource);
  const { state, actions } = useContext(sessionContext);
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();
  const ref = useRef(null);
  useOutside(ref, () => {
    setDisplay(false);
  });

  return (
    <div className={styles.user} ref={ref}>
      <Button
        name="user-menu_button-user-photo"
        ghost
        className={styles['user-menu']}
        onClick={() => setDisplay(true)}
      >
        <img
          className={styles.logo}
          src={
            state?.data?.fotografias?.[0]?.contenido
              ? `${endpoints.GET_MULTIMEDIA}/${state?.data?.fotografias?.[0]?.contenido}`
              : user
          }
          alt="."
        />
      </Button>
      {display && (
        <div className={styles.menu}>
          <Button
            theme="primary"
            name="user-menu_button-logout"
            onClick={() => {
              actions.logout().finally(() => {
                navigate('/login', { replace: true });
              });
            }}
          >
            {t('actions.logout')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
