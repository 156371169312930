import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import { Route, RouteChapterName, EmailsByRoute } from '@/services/route/type';

export * from './type';

export class RouteTracing {
  static async getUserRoute(email: string): Promise<Route[]> {
    const response = await axiosClient({
      url: endpoints.feedback.users + `/${email}/routeTracing`,
      method: 'GET',
    });
    return response.data;
  }

  static async getRouteByName(email: string, routeName: string): Promise<Route> {
    console.log('ROUTE NAME IS: ', routeName);
    const response = await axiosClient({
      url: endpoints.feedback.users + `/${email}/routeTracing?routeName=${routeName}`,
      method: 'GET',
    });
    return response.data;
  }

  static async getUserRoutesAll(email: string): Promise<Route[]> {
    const response = await axiosClient({
      url: endpoints.feedback.users + `/${email}/routes`,
      method: 'GET',
    });
    return response.data;
  }
  static async getPragmaticoRoutes(userEmail:any): Promise<RouteChapterName[]> {
    const response = await axiosClient({
      url: endpoints.routes.getRoutes,
      method: 'GET',
      params:{userEmail}
    });
    return response.data.routes;
  }
  static async getAllRoutes(): Promise<any> {
    const response = await axiosClient({
      url: endpoints.routes.getAllRoutes,
      method: 'GET',
    });
    return response.data;
  }
  static async setRoutes(data:EmailsByRoute): Promise<any> {
    const response = await axiosClient({
      url: endpoints.routes.setRoutes,
      method: 'POST',
      data
    });
    return response;
  }
  static async getForms(): Promise<any> {
    const response = await axiosClient({
      url: endpoints.routes.getForms,
      method: 'GET',
    });
    return response.data;
  }
  static async sendForm(data:any): Promise<any> {
    const response = await axiosClient({
      url: endpoints.routes.sendForm,
      method: 'POST',
      data,
    });
    return response.data;
  }
}

export default RouteTracing;
