import React, { useState, useEffect } from 'react';

import style from './styles.module.scss';

type TProps = {
  unitTime: string;
};

export const ATimelineMessage = ({ unitTime }: TProps) => {
  const [nameClass, setNameClass] = useState('');
  useEffect(() => {
    setNameClass(cssClass());
  }, [unitTime]);

  const cssClass = (): string => {
    let classTimelineMessage = 'timeline__subtitle';
    if (unitTime === 'days') {
      classTimelineMessage = 'timeline__subtitle--resumed';
    }
    return classTimelineMessage;
  };
  return (
    <div className={style['timeline']}>
      <div className={style[nameClass]}>
        Aún no tenemos suficiente información para mostrarte como va el
        <strong className={style['timeline__title--pink']}> talent poolero. </strong>
        {unitTime == 'weeks' && (
          <>
            Comunícate con el{' '}
            <strong className={style['timeline__title--pink']}>líder de la casa </strong> para tener
            mas información.
          </>
        )}
      </div>
    </div>
  );
};

export default ATimelineMessage;
