/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useRef, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { CancelIcon, ChevronDownIcon, SearchIcon } from '@/assets/icons/icons';

import { ACircleDays } from '@/components/atoms/ACircleDays';
import { AProgressRoute } from '@/components/atoms/AProgressRoute';
import ASelect from '@/components/atoms/ASelect/ASelect';
import { Contact } from '@/components/contact';
import ModalComponentSaveComment from '@/components/modalSaveComment';
import { Reaction } from '@/components/reaction';
import StateInfoEditComponent from '@/components/organisms/StateInfoEdit';
import Table from '@/components/table';
import Button from '@/components/ui/button';
import Radio from '@/components/ui/radio';
import TabComponent from '@/components/ui/tab';
import TextArea from '@/components/ui/textArea';
import TextField from '@/components/ui/textField';
import { UserSummary } from '@/components/userSummary';
import { utilsContext } from '@/contexts/utils';
import { replaceValue, deleteDataLocalStorage } from '@/helpers';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import { useControlledForm } from '@/hooks/useFeedbackForm';
import { useGroups } from '@/hooks/useGroups';
import useList from '@/hooks/useList';
import { FeedbackService } from '@/services/feedback';
import { Brand, Feedback, FeedbackEvent, GetParameterRoutesParams } from '@/services/feedback/types';
import { UserService, GetPragmaticsParams, Pragmatico } from '@/services/users';

import styles from './styles.module.scss';
import ModalInformationComponent from '@/components/molecules/ModalInformation';

export const FeedbackContent = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { register, reset, getValues, form } = useControlledForm();
  const [confirmModal, setConfirmModal] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);

  const [showEdit, setShowEdit] = useState<Pragmatico | null>(null);
  const [states, setStates] = useState([]);
  useEffect(() => {
    FeedbackService.getStates().then((data) => {
      setStates(data);
    });
  }, []);

  const [paramaterRouteDays, setParameterRouteDays] = useState(0);
  const parameterRouteParams: GetParameterRoutesParams = {
    name: 'maxDaysInTP',
  };

  useEffect(() => {
    FeedbackService.geTimeRouteDays(parameterRouteParams).then((res) => {
      setParameterRouteDays(res.value);
    });
  }, []);

  const groups = useGroups();
  const [selectedGroup, setSelectedGroup] = useState<string | null>();
  const [selectedTitleGroup, setSelectedTitleGroup] = useState<string>("progreso ruta");
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Pragmatico>();
  const [dropdowned, setDropdowned] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');
  const [events, setEvents] = useState<FeedbackEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<number>(0);

  const [componentMounted, setComponentMounted] = useState(false);
  const brandsRef = useRef<Brand[]>([]);
  const [openInformationModal, setOpenInformationModal]= useState(false);
  const [isLeaderInNewGroup, setIsLeaderInNewGroup] = useState<string | null>();

  useEffect(() => {
    setList([]);
    setSelectedGroup(searchParams.get('group'));
    const querySearch = searchParams.get('search');
    setCurrentSearch(querySearch ? querySearch : '');
    setSearchValue(querySearch ? querySearch : '');
    FeedbackService.getEvents().then((data) => {
      setEvents(data);
    });
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (groups.length > 0 && events.length > 0) setLoading(false);
    else setLoading(true);
    const group = groups.find((g) => g.title.toLocaleLowerCase() == searchParams.get('group')?.toLocaleLowerCase())
    setSelectedTitleGroup(group?.isProject ? "Progreso Proyecto" : "Progreso Ruta");
  }, [groups, events]);
  useEffect(() => {
    if (componentMounted) {
      setDisabledSave(true);
      reset();
      changeQueryParams();
      requestList();
    }
  }, [selectedGroup, currentSearch, componentMounted]);

  useEffect(() => {
    disabledButton();
  }, [form]);

  useEffect(() => {
    getAllBrands();
  }, [])

  const today = new Date();

  const getAllBrands = async() => {
    try {
      const response = await FeedbackService.getBrands();
      brandsRef.current = response.data;
    } catch(e){
      addToast('marcas probables cargadas automaticamente', 'danger')
    }
  }

  const changeQueryParams = () => {
    if (selectedGroup) searchParams.set('group', selectedGroup);
    else searchParams.delete('group');

    if (currentSearch) searchParams.set('search', currentSearch);
    else searchParams.delete('search');
    navigate('?' + searchParams.toString(), { replace: true });
  };

  const getEntryDateString = (user: Pragmatico) => {
    let fechaIngreso = 0;
    if (user.TPEntryDate) {
      fechaIngreso = Math.floor(
        (today.getTime() - new Date(user.TPEntryDate).getTime()) / (1000 * 60 * 60 * 24),
      );
    }
    let entryDate = '-';
    if (fechaIngreso !== 0) entryDate = fechaIngreso == 1 ? '1' : `${fechaIngreso}`;
    return entryDate;
  };

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    const pragmaticParams: GetPragmaticsParams = {
      page: page + 1,
      limit: 10,
    };
    if (selectedGroup) pragmaticParams.group = selectedGroup;
    if (currentSearch) pragmaticParams.user = currentSearch;
    cancellablePromise(
      UserService.getPragmaticos(pragmaticParams)
        .finally(() => {
          setListLoading(false);
        })
        .then((res) => {
          const data = res.users.map((el) => {
            return {
              ...el,
              TPEntryDate: getEntryDateString(el),
            };
          });
          setList(data || [], !!page, res.length, res.length - 1 == page, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  const toggleDropDowned = (key: React.Key) => {
    const index = dropdowned.indexOf(key);
    if (index < 0) setDropdowned((v) => [...v, key]);
    else {
      const newData = [...dropdowned];
      newData.splice(index, 1);
      setDropdowned(newData);
    }
  };

  const disabledButton = (): boolean => {
    const values = form;
    for (const key in values) {
      if (values[key]) {
        if (disabledSave) setDisabledSave(false);
        return false;
      }
    }
    if (!disabledSave) setDisabledSave(true);
    return true;
  };

  const submit = () => {
    const data = getValues();
    const comments: Feedback[] = [];
    for (const key in data) {
      if (data[key]) {
        const email = key.replaceAll(' ', '.');
        const group = list.list.find(user => user.email === email)?.group
        const parseBoolean = (d: string) => {
          if (d == 'true') return true;
          if (d == 'false') return false;
          return undefined;
        };

        comments.push({
          comment: data[key].comment,
          userEmail: email,
          assistance: parseBoolean(data[key].assistance),
          punctuality:
            data[key].assistance == 'true' ? parseBoolean(data[key].punctuality) : undefined,
          event: events[selectedEvent].title,
          group: group,
        });
      }
    }
    if (comments.length > 0) {
      setLoading(true);
      FeedbackService.saveComments(comments)
        .then(() => {
          setConfirmModal(true);
        })
        .catch((e) => addToast(e.message, 'danger'))
        .finally(() => {
          setLoading(false);
        });
    }
    deleteDataLocalStorage('dataOffline');
  };

  const getDataLocalStorage = () => {
    const data = localStorage.getItem('dataOffline') ?? '';
    return data;
  };

  const handleCloseModalSave = () => {
    // reseteando comentarios
    reset();
    setDropdowned([]);
    setConfirmModal(false);
    document.querySelectorAll("input[type='radio']").forEach((el) => {
      (el as HTMLInputElement).checked = false;
    });
  };

  const handleSearch = (value: string) => {
    setCurrentSearch(
      replaceValue({
        value,
        searchValue: /\s+/gi,
        replaceValue: ' ',
      }),
    );
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setCurrentSearch('');
  };

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    setSearchValue(text);
    if (text.length === 0) setCurrentSearch('');
  };

  const getTrailingSearchIcon = (): React.ReactNode => {
    return searchValue.length > 0 ? (
      <CancelIcon width={16} height={16} onclick={handleClearSearch} />
    ) : (
      <SearchIcon width={16} height={16} onclick={() => handleSearch(searchValue)} />
    );
  };

  const assignPlatforms = async (email:string, newGroup:string, oldGroup:string) => {

    try {
      setOpenInformationModal(true);
      setTimeout(() => { setOpenInformationModal(false)
      }, 10000)
     await UserService.assignPlatform(email, newGroup, oldGroup);
 
   } catch(e:any) {
      addToast(`No se ha podido gestionar los accesos de Jira y Gitlab al pragmático. 
      ${e.response? e.response.data.message:e}`, 'danger');
   } 
 }

  return (
    <>
      <div className={styles.options}>
        {events && (
          <TabComponent
            isMain={true}
            subMenus={events.map((event) => {
              return {
                name: event.title,
                title: event.title,
                element: <></>,
              };
            })}
            selectedTab={selectedEvent}
            onChange={setSelectedEvent}
          />
        )}
        <section className={styles['options-inputs']}>
          <section className={styles['options-search']}>
            <div className={styles['options-option']}>
              <h2>Pragmático</h2>
              <TextField
                placeholder="Buscar pragmático"
                value={searchValue}
                onEnter={() => handleSearch(searchValue)}
                onChange={handleSearchValue}
                trailing={getTrailingSearchIcon()}
              />
            </div>
            <div className={styles['options-option']}>
              <h2>Casa o Proyecto</h2>
              <ASelect
                placeholder="Seleccionar casa o proyecto"
                options={groups ? groups.filter((group) => group.active) : []}
                name="grupos"
                getOptionLabel={(option) => option.title}
                value={groups.find(
                  (g) => g.title.toLocaleLowerCase() == selectedGroup?.toLocaleLowerCase(),
                )}
                onChange={(value, actionMeta) => {
                  if (actionMeta.action === 'clear' && selectedGroup) setSelectedGroup(undefined);
                  else if (value) setSelectedGroup(value.title); setSelectedTitleGroup(value?.isProject ? "Progreso Proyecto" : "Progreso Ruta");
                }}
                isClearable
              />
            </div>
          </section>
          <section className={styles['options-buttons']}>
            <Button name="save-comments_btn" onClick={submit} disabled={disabledSave}>
              Guardar
            </Button>
          </section>
        </section>
      </div>

      <div className={styles['users-table']}>
        <Table
          header={[
            { name: 'pragmatico', label: 'Nombre' },
            { name: 'capacity', label: 'Capacidad' },
            { name: 'progressRoute', label: selectedTitleGroup },
            { name: 'TPEntryDate', label: 'Días en TP' },
            { name: 'comment', label: 'Comentar' },
          ]}
          data={list.list.map((pragmatico) => ({
            _key: pragmatico.email,
            ...(pragmatico as any),
            pragmatico: (
              <div className={styles['contact-field']}>
                <button
                  className={styles.chevron}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropDowned(pragmatico.email);
                  }}
                >
                  <ChevronDownIcon width={17} height={17} />
                </button>
                <Contact
                  user={pragmatico}
                  className={styles.contact}
                  onChangeUser={() => {
                    setShowEdit(pragmatico);
                  }}
                  show={['status']}
                  size="sm"
                />
              </div>
            ),
            capacity: pragmatico.capacity,
            progressRoute: (
              <div className={styles['coverage-container__progress']}>
                <AProgressRoute
                  completedRouteStories={pragmatico.completedRouteStories}
                  estimatedRouteStories={pragmatico.estimatedRouteStories}
                />
              </div>
            ),
            TPEntryDate: (
              <div className={styles['coverage-container__progress']}>
                <ACircleDays days={pragmatico.TPEntryDate} timeframe={paramaterRouteDays} />
              </div>
            ),
            comment: (
              <div>
                <div className={styles['puntualidad']}>
                  <div>
                    ¿Asistió?:
                    <Radio
                      label="Si"
                      {...register(`${pragmatico.email.replaceAll('.', ' ')}.assistance`)}
                      value="true"
                    />
                    <Radio
                      label="No"
                      {...register(`${pragmatico.email.replaceAll('.', ' ')}.assistance`)}
                      value="false"
                    />
                  </div>
                  {form[`${pragmatico.email.replaceAll('.', ' ')}.assistance`] == 'true' && (
                    <div>
                      Puntualidad:
                      <Reaction
                        {...register(`${pragmatico.email.replaceAll('.', ' ')}.punctuality`)}
                      />
                    </div>
                  )}
                </div>
                <TextArea
                  placeholder="Escribe tu comentario"
                  className={styles['comment-field']}
                  onKeyDown={(e) => {
                    const el = e.target as HTMLTextAreaElement;
                    el.style.cssText = 'height:auto;';
                    el.style.cssText = 'height:' + el.scrollHeight + 'px';
                  }}
                  {...register(
                    `${pragmatico.email.replaceAll('.', ' ')}.comment`,
                    {},
                    getDataLocalStorage(),
                    true,
                  )}
                ></TextArea>
              </div>
            ),
          }))}
          onRowClick={(e) => {
            const popoverContent = (e.currentTarget as HTMLElement).querySelector(
              '#popover-content',
            ) as HTMLDivElement;
            if (window.getComputedStyle(popoverContent).visibility == 'hidden') {
              (e.currentTarget as HTMLElement).querySelector('textarea')?.focus();
            }
          }}
          advanced={{
            ...list,
            handleSearch: (page) => requestList(page),
            dropdownContent: (item: Pragmatico) => {
              return <UserSummary user={item} />;
            },
            dropdowned: dropdowned,
            isAnyVisible: false,
          }}
          emptyLabel={'No existen datos para mostrar'}
        />
      </div>
      <div>
        <br></br>
      </div>

      <div></div>
      <ModalComponentSaveComment
        title={''}
        successLabel={'Aceptar'}
        onClose={handleCloseModalSave}
        show={!!confirmModal}
        onSuccess={handleCloseModalSave}
      >
        ¡Comentarios <br />
        guardados!
      </ModalComponentSaveComment>
      
      <ModalInformationComponent
           onClose={() => { setOpenInformationModal(false) }}
           show={openInformationModal}
          >
             <div className={styles['text-confirmation-modal']}>
            {
              isLeaderInNewGroup ? (
              <>
               <p>Se estan procesando los accesos de Jira y Gitlab al pragmático.</p>
               <p>Por favor, verifique los accesos en 10 minutos.</p>
               <p>En caso de no tener los accesos verifique su correo y comuniquese con su lider </p>
               {isLeaderInNewGroup}
             </>
              ):(
              <>
                <p>Se estan retirando los accesos de Jira y Gitlab al pragmático </p>
                <p>del anterior grupo. Verifique en 5 minutos </p>
                <p>En caso de no realizarse comuniquese con su anterior lider </p>
              </>
              )
            }
              </div>
      </ModalInformationComponent>

      {states && showEdit && (
        <StateInfoEditComponent
          show={!!showEdit}
          states={states}
          brands= {brandsRef.current}
          successLabel="Aceptar"
          user={showEdit}
          onClose={(newGroup, leader, oldGroup) => {
            setShowEdit(null);
            requestList();
            setIsLeaderInNewGroup(leader);
            if(newGroup && oldGroup){
              assignPlatforms(showEdit.email, newGroup, oldGroup);
            }
            
          }}
        />
      )}
    </>
  );
};

export default FeedbackContent;
