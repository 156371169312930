/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import { MdMenu } from 'react-icons/md';
import { NavLink, useLocation } from 'react-router-dom';

import { LogoHeaderIcon } from '@/assets/icons/icons';
import Button from '@/components/ui/button';
import { PRAGMA } from '@/pages';

import styles from './styles.module.scss';
import UserMenu from './userMenu';
import usePermission from '@/routes/protect/usePermission';

type TProps = {
  setShowMenu: (show: boolean) => void;
};

const Header = ({ setShowMenu }: TProps) => {
  // const { state } = useContext(sessionContext);
  const location = useLocation();
  // const { t } = useTranslation('pages');
  const [hover, setHover] = useState<boolean>(false);

const {routesMenu} = usePermission()

  return (
    <header className={styles.header}>
      <div className={styles['extend-line']}></div>
      <div className={styles['container']}>
        <div className={styles['header-line']}>
          <div className={`${styles['line']} ${styles['line-left']}`}></div>
          <div className={styles['container-logo']}>
            <Button
              name="user-menu_button-menu"
              text
              className={styles['menu']}
              onClick={() => setShowMenu(true)}
            >
              <MdMenu />
            </Button>
            <div className={styles['box-logo']}>
              <LogoHeaderIcon className={styles['logo']} height={36} width={36} />
            </div>
            <div className={styles['separate']}></div>
            <span className={styles['header-logo']}>Talent pool</span>
          </div>
        </div>
        <div className={styles.links}>
          {routesMenu?.map((app) => {

            // Verificamos que el usario tenga acceso al menu y haya submenus disponibles
            return (
              <NavLink
                onClick={() => setHover(false)} 
                key={app.name}
                end
                to={app.index}
                className={`${styles.link}
                  ${
                  styles['active-custom']
                }`}
              >
                <div className={styles['container-title']}>
                  <h2 className={`${styles.title} ${styles['title-custom']}`}>{app.name}</h2>
                  {/* {true && <span className={styles.notifications}>5</span>} */}
                </div>
                <span
                  className={
                   
                      styles['lighting-off']
                  }
                ></span>
              </NavLink>
            );
          })}
        </div>
        <div className={styles['header-line']}>
          <div className={`${styles['line']} ${styles['line-right']}`}></div>
          <div className={styles['container-user']}>
            {/* <Translation className={styles.translation} /> */}
            {/*<div className={styles['separate']}></div>*/}
            <UserMenu />
          </div>
        </div>
      </div>
      <div className={styles['extend-line']}></div>
    </header>
  );
};

export default Header;
