/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { createPortal } from 'react-dom';

import {
  CheckToastTicon,
  ErrorWarningLineIcon,
  OutlineWarningCircleIcon,
} from '@/assets/icons/icons';

import styles from './styles.module.scss';

type TProps = {
  message?: string;
  color?: 'danger' | 'success' | 'warning' | 'information';
};

const Toast = ({ message, color }: TProps) =>
  createPortal(
    <div className={styles.screen}>
      <div className={styles.toast} data-color={color}>
        {color === 'danger' ? (
          <OutlineWarningCircleIcon width={30} height={30} className={styles['icon']} />
        ) : color === 'success' ? (
          <CheckToastTicon width={30} height={30} className={styles['icon']} />
        ) : color === 'warning' ? (
          <OutlineWarningCircleIcon width={30} height={30} className={styles['icon']} />
        ) : (
          <ErrorWarningLineIcon width={30} height={30} className={styles['icon']} />
        )}
        {message}
      </div>
    </div>,
    document.body,
  );

export default Toast;
