import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import { TimelineResumed } from '@/services/timeline/types';

export * from './types';

export class TimelineStoryService {
  static async getTimelineUser(email: string): Promise<TimelineResumed> {
    const response = await axiosClient({
      url: endpoints.feedback.users + `/${email}/routeTimeline`,
      method: 'GET',
    });
    return response.data;
  }
}
