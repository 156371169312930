import React, { useState, useEffect, useContext } from 'react';

import { utilsContext } from '@/contexts/utils';
import { HeatMapService } from '@/services/heatMap';
import { detailQuestions } from '@/services/heatMap/type';

import styles from './styles.module.scss';

interface Tprops {
  heatMapId: number;
}

export const ListAnswersHeatMap = ({ heatMapId }: Tprops) => {
  const [questionsList, setQuestionsList] = useState<detailQuestions[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const {
    actions: { addToast },
  } = useContext(utilsContext);

  useEffect(() => {
    getQuestionsContent(heatMapId);
  }, []);

  const getQuestionsContent = (heatMapId: number) => {
    HeatMapService.getDetailQuestions(heatMapId)
      .then((res) => {
        setQuestionsList(res);
        setLoading(false);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  return (
    <ul className={`${styles.ul}`}>
      {loading ? (
        <h3>Cargando...</h3>
      ) : (
        questionsList?.map((questionsListItem, index) => {
          return (
            <li key={index}>
              <p>{questionsListItem.question}</p>
              <span>{questionsListItem.answer}</span>
            </li>
          );
        })
      )}
    </ul>
  );
};
