import { sessionContext } from '@/contexts/session';
import { PRAGMA } from '@/pages';
import { useContext } from 'react';
const usePermission = ()=>{
    const { state:{data} } = useContext(sessionContext);
    const routesAllowed = data?.permisos && data?.permisos.map((route)=>route.path)
    return {
        routes:data?.permisos,
        routesAllowed,
        routesMenu:[...PRAGMA.filter((p)=> routesAllowed?.includes(p.index))]
    } 
}
export default usePermission;
