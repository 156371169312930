import React, { useContext, useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import imgEmail from '@/assets/icons/email.svg';
import { Contact } from '@/components/contact';
//import { RouteStatus } from '@/components/routeStatus';
import { LikelyBrand } from '@/components/molecules/LikelyBrand';
import { UserHeatMap } from '@/components/molecules/UserHeatMap';
import TabComponent, { TSubMenu } from '@/components/ui/tab';
import { UserFeedback } from '@/components/userDetails/userFeedback';
//import { UserSubjects } from '@/components/userDetails/userSubjects';
import { UserTimeline } from '@/components/userDetails/UserTimeline';
import { StoriesComponent } from '@/components/userStories';
import { utilsContext } from '@/contexts/utils';
import { capitalize, decryptDataValue } from '@/helpers/helpers';
import { useGroups } from '@/hooks/useGroups';
import { Group } from '@/services/group';
import { RouteTracing, Route } from '@/services/route';
//import UserStoryService, { Stories } from '@/services/storie';
import { StorieRoute } from '@/services/timeline';
import { UserService, Pragmatico } from '@/services/users';

import tabStyles from './styles-tab.module.scss';
import style from './styles.module.scss';

export * from './useGoToUserDetails';

const FeedbackComments = () => {
  const unitTime = 'weeks';
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>();

  const [pragmatico, setPragmatico] = useState<Pragmatico>();
  const groups = useGroups();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [currentGroup, setCurrentGroup] = useState<Group | undefined>(undefined);

  const [storieRoute, setStorieRoute] = useState<StorieRoute | undefined>(undefined);

  const {
    actions: { setLoading },
    state: { loading },
  } = useContext(utilsContext);

  useEffect(() => {
    const data = searchParams.get('data');
    if (!data) {
      navigate('/', { replace: true });
      return;
    }
    setEmail(decryptDataValue(data));
  }, [email]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (email) {
  //     RouteTracing.getUserRoutesAll(email)
  //       .then((d) => {
  //         setRouteNames(d);
  //       })
  //       .finally(() => setLoading(false));
  //   }
  // }, [email]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (routes) {
  //     setLoading(false);
  //   }
  //   setLoading(false);
  // }, [routes]);

  // useEffect(() => {
  //   setLoading(true);

  //   if (routeNames && email) {
  //     RouteTracing.getRouteByName(email, routeNames[1]?.routeName)
  //       .then((d) => setRoutes(d))
  //       .finally(() => setLoading(false));
  //   }
  // }, [routeNames]);

  useEffect(() => {
    setLoading(true);
    if (email) {
      UserService.getPragmaticoByEmail(email)
        .then((d) => {
          setPragmatico(d);
        })
        .finally(() => setLoading(false));

      //
    }
  }, [email]);

  useEffect(() => {
    if (pragmatico) {
      const group = groups?.find(
        (element) => element.title.toLowerCase() === pragmatico.group.toLowerCase(),
      );
      setCurrentGroup(group);
    }
  }, [pragmatico]);

  useEffect(() => {
    setLoading(true);
    if (pragmatico?.routeProgress) {
      setStorieRoute(pragmatico.routeProgress);
      setLoading(false);
    }
    setLoading(false);
  }, [pragmatico?.routeProgress]);

  const getUserStatus = (status: string) => {
    if (!status) return;
    const capitalizeStatus = capitalize(status);
    return (
      <div>
        <p>Estado</p>
        <p className={style.status} data-name={capitalizeStatus}>
          {capitalizeStatus}
        </p>
      </div>
    );
  };

  const getTabs = (): TSubMenu[] => {
    const tabs: TSubMenu[] = [
      {
        title: 'Timeline',
        element: (
          <UserTimeline
            startDate={storieRoute?.startDate}
            timeline={storieRoute?.timeline}
            userEmail={storieRoute?.userEmail}
            unitTime={unitTime}
          />
        ),
        name: 'Timeline',
      },
      {
        title: 'Historial Feedback',
        element: <UserFeedback email={email} />,
        name: 'Historial feedback',
      },
      {
        title: 'Mapa de calor',
        element: <UserHeatMap email={email} />,
        name: 'Mapa de calor',
      },
      {
        title: 'Marca probable',
        element: <LikelyBrand email={email} />,
        name: 'Marca probable',
      },
    ];

    if (currentGroup?.isProject && pragmatico) {
      tabs.push({
        title: currentGroup.title,
        element: <StoriesComponent user={pragmatico} group={currentGroup} />,
        name: currentGroup.title,
      });
    }

    // if (routes) {
    //   tabs.push({
    //     title: 'Ruta ' + routes?.routeName,
    //     element: <UserSubjects route={routes} />,
    //     name: 'Ruta ' + routes?.routeName,
    //   });
    // }

    // routes?.forEach((route) => {
    //   tabs.push({
    //     title: 'Ruta ' + route.routeName,
    //     element: <UserSubjects route={route} />,
    //     name: 'Ruta ' + route.routeName,
    //   });
    // });
    return tabs;
  };
  //
  const today = new Date();

  const fechaIngreso = Math.floor(
    (today.getTime() - new Date(pragmatico?.TPEntryDate ?? '').getTime()) / (1000 * 60 * 60 * 24),
  );

  return (
    <div className={`${style.main}`}>
      <header className={style.header}>
        {pragmatico ? (
          <Contact size="m" user={pragmatico} show={[]} className={`${style.contact}`}>
            <div className={style.email}>
              <img src={imgEmail} alt="Email" />
              {pragmatico.email && <p>{pragmatico.email}</p>}
            </div>

            <div className={style.perfil}>
              {getUserStatus(pragmatico.status || '')}
              <div>
                <p>Casa/Proyecto</p>
                <span>{pragmatico.group}</span>
              </div>
              <div>
                <p>Capacidad</p>
                <span>{pragmatico.capacity}</span>
              </div>
              <div>
                <p>Tiempo en TP</p>
                <span>{fechaIngreso ? `${fechaIngreso} días` : `-`}</span>
              </div>
              <div>
                <p>Correo Lider</p>
                <span>{currentGroup?.leader ?? 'Sin lider'}</span>
              </div>
            </div>
          </Contact>
        ) : (
          <div></div>
        )}

        {/* <RouteStatus
          route={routes ? routes : null}
          className={style.route}
          routeNames={routeNames}
          setRoutes={setRoutes}
          selectedRoute={routes}
          setSelectedRoute={setRoutes}
          email={email?.toString()}
        /> */}
        <TabComponent
          selectedTab={selectedTab}
          subMenus={getTabs().map((tab) => ({ ...tab, element: <></> }))}
          customStyles={tabStyles}
          onChange={(e) => {
            setSelectedTab(e);
          }}
        />
      </header>
      <div className={`${style.content}`}>
        {getTabs()[selectedTab] ? getTabs()[selectedTab].element : getTabs()[0].element}
      </div>
    </div>
  );
};

export default FeedbackComments;
