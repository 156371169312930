/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect, useContext } from 'react';
import { format } from 'date-fns';
import * as locale from 'date-fns/locale';

import { CheckToastTicon } from '@/assets/icons/icons';
import Button from '@/components/ui/button';
import CardComponent from '@/components/ui/cardComponent';
import ModalSuccess from '@/components/userStories/modalSuccess';
import { utilsContext } from '@/contexts/utils';
import { capitalize } from '@/helpers';
import { Group } from '@/services/group';
import { UserStoryService, State, Stories } from '@/services/storie';
import { Pragmatico } from '@/services/users';

import { HUcomponent } from './huComponent';
import ModalAsignarHU from './modalAsignarHU';
import styles from './styles.module.scss';

interface UserStoriesProps {
  user: Partial<Pragmatico>;
  group: Group;
}
const formatDate = (date: string | Date | null | undefined): string => {
  if (!date) return '-';
  const currentDate = new Date(date);
  return capitalize(format(currentDate, 'MMM-dd-yyyy', { locale: locale.es }));
};

export const StoriesComponent = ({ user, group }: UserStoriesProps) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [hu, setHu] = useState('');
  const [stories, setStories] = useState<Stories | null>(null);
  const [states, setStates] = useState<State[] | null>(null);
  const [mapStates, setMapStates] = useState<any | null>(null);

  const {
    actions: { setLoading, addToast },
    state: { loading },
  } = useContext(utilsContext);

  useEffect(() => {
    UserStoryService.getStates().then((data) => {
      setStates(data);
      const map = mapStatesToIndex(data);
      setMapStates(map);
    });

    if (user.email && group) {
      UserStoryService.getUserStories(user.email, group.id).then((d) => {
        setStories(d);
        setLoading(false);
      });
    }
  }, [loading]);

  const hideModalSuccess = () => {
    setShowModalSuccess(false);
  };

  const handleChange = (event: any) => {
    setHu(event.target.value);
  };

  const mapStatesToIndex = (states: State[]) => {
    const map: any = {};
    states.forEach((state: any, index: number) => {
      map[state.state] = state.id;
    });
    // console.log('map', map)
    return { ...map };
  };

  {
    const addHU = () => {
      if (!user.email) return;

      setShowModal(false);
      setLoading(true);

      UserStoryService.saveStorie({ assignedTo: user.email, groupId: group.id, name: hu })
        .then((data) => {
          if (stories) {
            setStories({ ...stories, userStories: [data, ...stories?.userStories] });
          }

          setShowModalSuccess(true);
        })
        .finally(() => setLoading(false));
    };

    const handleDisable = () => {
      if (hu === '') {
        return true;
      } else {
        return false;
      }
    };

    if (loading) return <div>Loading...</div>;
    else
      return (
        <div>
          <div className={styles['upper-box']}>
            {stories?.projectStartDate && (
              <h4 className={styles.date}>
                Fecha de ingreso al proyecto: <>{formatDate(stories.projectStartDate)}</>
              </h4>
            )}
          </div>

          <>
            <div className={styles['cards-container']}>
              {states?.map((state, index) => {
                return (
                  <CardComponent key={index} title={state.state}>
                    {stories?.userStories
                      .filter((userStory) => userStory.stateId == state.id)
                      .map((userStory, index) => (
                        <HUcomponent key={userStory.id} story={userStory} index={index} />
                      ))}
                    <div></div>
                  </CardComponent>
                );
              })}
            </div>
          </>
          <ModalAsignarHU
            title={'Asignar HU'}
            onClose={() => {
              setHu('');
              setShowModal(false);
            }}
            show={showModal}
          >
            <>
              <div className={styles.body}>
                <p className={styles.tag}>Nombre de la historia de usuario</p>
                <input
                  id="hu"
                  type={'text'}
                  className={styles['hu-field']}
                  onChange={handleChange}
                ></input>
              </div>
              <div className={styles.footer}>
                <Button
                  disabled={handleDisable()}
                  name="asignar"
                  onClick={() => {
                    addHU();
                  }}
                >
                  Asignar
                </Button>
              </div>
            </>
          </ModalAsignarHU>
          <ModalSuccess
            onClose={() => {
              setShowModalSuccess(false);
            }}
            show={showModalSuccess}
          >
            <>
              <div>
                <CheckToastTicon className={styles['check-toast-icon']} width={50} height={50} />
              </div>
              <h4 className={styles['success-title']}>¡HU Asignada!</h4>
              <div className={styles['success-footer']}>
                <Button onClick={hideModalSuccess} name="aceptarSuccess">
                  Aceptar
                </Button>
              </div>
            </>
          </ModalSuccess>
        </div>
      );
  }
};
