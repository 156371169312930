/**
 * Solicitudes Pragma SA
 */

import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import styles from './styles.module.scss';

export interface IPropsTabTitle {
  title: string;
  index: number;
  total: number;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
  notifications?: number;
  hover?: boolean;
  setHover?: Dispatch<SetStateAction<boolean>>;
  customStyles: {
    readonly [key: string]: string;
  };
}

const TabTitleComponent = ({
  title,
  setSelectedTab,
  index,
  selectedTab,
  notifications,
  hover,
  setHover,
  customStyles,
}: IPropsTabTitle) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
    setHover && setHover(false);
  }, [setSelectedTab, index]);

  if (title === 'empty') {
    return null;
  }
  const styles = customStyles;

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.container}>
          <button
            onFocus={() => selectedTab !== index && setHover && setHover(true)}
            onMouseOver={() => selectedTab !== index && setHover && setHover(true)}
            onMouseLeave={() => selectedTab !== index && setHover && setHover(false)}
            type="button"
            onClick={onClick}
            className={`${styles.title} ${selectedTab === index && styles.active} ${
              hover && selectedTab === index && styles['active-custom']
            }`}
          >
            {title}
          </button>
          {!!notifications && <span className={styles.notifications}>{notifications}</span>}
        </div>
        <span className={`${selectedTab === index && styles.lighting}`}></span>
      </div>
    </>
  );
};

export type TSubMenu = {
  name: string;
  title: string;
  element: React.ReactNode;
  notifications?: number;
};

const TabComponent = ({
  subMenus,
  isMain,
  selectedTab = 0,
  onChange,
  customStyles = styles,
}: {
  subMenus: TSubMenu[];
  isMain?: boolean;
  selectedTab: number;
  onChange?: (index: number) => void;
  customStyles?: {
    readonly [key: string]: string;
  };
}) => {
  const [hover, setHover] = useState<boolean>(false);
  const [selected, setSelectedTab] = useState(selectedTab);
  const handleChange = (index: number) => {
    try {
      onChange?.(index);
    } finally {
      setSelectedTab(index);
    }
  };
  const styles = customStyles;
  return (
    <section className={styles.tab}>
      <div className={styles.header}>
        {subMenus.map((menu, index) => (
          <TabTitleComponent
            hover={hover}
            setHover={setHover}
            key={menu.name}
            title={menu.title}
            index={index}
            setSelectedTab={handleChange}
            total={subMenus.length}
            selectedTab={selected}
            notifications={menu.notifications}
            customStyles={styles}
          />
        ))}
      </div>
      {isMain ? subMenus[selected]?.element : subMenus[selected]?.element}
    </section>
  );
};

export default TabComponent;
