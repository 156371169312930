/**
 * Solicitudes Pragma SA
 */

import React, { useEffect } from 'react';

import { createPortal } from 'react-dom';

import { motion, AnimatePresence } from 'framer-motion';
import { MdClose } from 'react-icons/md';

import styles from './styles.module.scss';

interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  successDisabled?: boolean;
}

const ModalSuccess = ({ children, onClose, show }: ModalProps) => {
  const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
    if ((e.charCode || e.keyCode) === 27) onClose();
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  return createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.modal}
          onClick={onClose}
        >
          <div className={styles.container} onClick={(e) => e.stopPropagation()} aria-hidden="true">
            <MdClose
              name="modal-component_button-close"
              className={styles.close}
              onClick={onClose}
            />
            <div>{children}</div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.querySelector('root') || document.body,
  );
};

export default ModalSuccess;
