/**
 * Solicitudes Pragma SA
 */

import React  from 'react';

import { createPortal } from 'react-dom';

import { motion, AnimatePresence } from 'framer-motion';
import { MdClose,  MdError,  MdHighlightOff } from 'react-icons/md';

import styles from './styles.module.scss';

interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  successDisabled?: boolean;
}

const ModalInformationComponent = ({
  children,
  onClose,
  show,
}: ModalProps) => {

  return createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.modal}
          onClick={onClose}
        >
          <div className={styles.container} onClick={(e) => e.stopPropagation()} aria-hidden="true">
            <div className={styles.header}>
              <MdClose
                name="modal-component_button-close"
                className={styles.close}
                onClick={onClose}
              />
            </div>
            <div className={styles.body}>
              <MdError name="modal-component_icon-done" className={styles.done} />
              {children}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.querySelector('root') || document.body,
  );
};

export default ModalInformationComponent;
